import React, {createContext, useContext, useEffect, useState} from 'react';
import {getPlatformCustomMenuList} from '../api/platformCustomMenu';
import {PlatformCustomMenuDto} from '../api/dto/platformCustomMenu';

interface PlatformCustomMenuContextType {
  platformCustomMenus?: PlatformCustomMenuDto[];
  setPlatformCustomMenus: (
    platformCustomMenus?: PlatformCustomMenuDto[],
  ) => void;
}

const PlatformCustomMenuContext = createContext<
  PlatformCustomMenuContextType | undefined
>(undefined);

export const PlatformCustomMenuProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const platformId = localStorage.getItem('platformId');
  const [platformCustomMenus, setPlatformCustomMenus] = useState<
    PlatformCustomMenuDto[] | undefined
  >(() => {
    const cachedPlatformCustomMenu = localStorage.getItem(
      'platformCustomMenus',
    );
    return cachedPlatformCustomMenu
      ? JSON.parse(cachedPlatformCustomMenu)
      : undefined;
  });

  useEffect(() => {
    if (!platformCustomMenus && platformId) {
      const fetchPlatformCustomMenus = async () => {
        try {
          const response = await getPlatformCustomMenuList({limit: 100});
          if (response?.data?.items) {
            setPlatformCustomMenus(response.data.items);
            localStorage.setItem(
              'platformCustomMenus',
              JSON.stringify(response.data.items),
            );
          }
        } catch (error) {
          console.error('Error fetching platformCustomMenus:', error);
        }
      };
      fetchPlatformCustomMenus();
    }
  }, [platformId, platformCustomMenus]);

  return (
    <PlatformCustomMenuContext.Provider
      value={{platformCustomMenus, setPlatformCustomMenus}}
    >
      {children}
    </PlatformCustomMenuContext.Provider>
  );
};

export const usePlatformCustomMenu = () => {
  const context = useContext(PlatformCustomMenuContext);
  if (!context) {
    throw new Error(
      'usePlatformCustomMenu must be used within a PlatformCustomMenuProvider',
    );
  }
  return context;
};
