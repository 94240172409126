import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../../components/Dashboard';
import {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../../components/MaterialTable';
import {
  createPlatformFace,
  deletePlatformFace,
  getPlatformFaceList,
} from '../../../api/platformFace';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {CreatedAtColumn} from '../../../components/Date/CreatedAtColumn';
import {usePlatformUsage} from '../../../context/platformUsage';
import {StatusColumn} from '../../../components/EditTable/ViewTableStatusColumn';
import {ViewPLatformFaceColumn} from '../../../components/EditTable/ViewPlatformFace';
import {MRT_Cell} from 'material-react-table';

dayjs.extend(utc);

function validate(data: Record<string, any>) {
  return {
    img: !validateRequired(data.img) ? 'img is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
  autoHideDuration: number;
}

function AIPlatformFaceContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    autoHideDuration: ALERT_HIDE_DURATION,
    vertical: 'bottom',
    horizontal: 'right',
  });

  const {open} = state;
  const [refetch, setRefetch] = useState<Function>();
  const handleClose = () => {
    setState(prev => ({...prev, open: false}));
  };

  const refreshFunc = (refetch: Function) => {
    setRefetch(() => refetch);
  };

  const handleClick = (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => {
    setState({
      ...state,
      type,
      message,
      open: true,
      autoHideDuration: autoHideDuration || ALERT_HIDE_DURATION,
    });
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        show: false,
        enableEditing: false,
        size: 60,
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                  {idValue}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'img',
        header: t('imgHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.img,
          helperText: validationErrors?.img,
          onFocus: () =>
            setValidationErrors(prev => ({
              ...prev,
              img: undefined,
            })),
        },
        Cell: ({cell}) => {
          const imageUrl = cell.getValue() as string;
          const [open, setOpen] = useState(false);

          const handleOpen = () => setOpen(true);
          const handleClose = () => setOpen(false);

          return imageUrl ? (
            <>
              <Tooltip title={t('dashboardMenuAIPlatformFaceShowBigImg')} arrow>
                <img
                  src={imageUrl}
                  alt='Preview'
                  onClick={handleOpen}
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>

              <Dialog open={open} onClose={handleClose} maxWidth='md'>
                <DialogContent
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 20,
                  }}
                >
                  <img
                    src={imageUrl}
                    alt='Full Preview'
                    style={{
                      maxWidth: '100%',
                      maxHeight: '80vh',
                      borderRadius: '8px',
                    }}
                  />
                </DialogContent>
              </Dialog>
            </>
          ) : (
            <span>{t('dashboardMenuAIPlatformFaceNoImageData')}</span>
          );
        },
      },
      {
        accessorKey: 'results',
        header: t('resultsHeader'),
        enableEditing: false,
        Cell: ({cell, row}) => (
          <ViewPLatformFaceColumn t={t} cell={cell} row={row} />
        ),
      },
      {
        accessorKey: 'status',
        header: t('statusHeader'),
        editVariant: 'select' as any,
        editSelectOptions: [
          {
            label: t('statusFinish'),
            value: 'Finish',
          },
          {label: t('statusFail'), value: 'Fail'},
          {
            label: t('statusPending'),
            value: 'Pending',
          },
        ],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          sx: {display: 'none'},
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              status: undefined,
            }),
        },
        Cell: ({cell}: any) => <StatusColumn t={t} cell={cell} />,
        accessorFn: (row: any) => {
          return row.status;
        },
      },

      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}: any) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='platformFace'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuAIPlatformFace')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardMenuAIPlatformFaceDescription')}
        </Typography>
      </Box>

      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography
                  variant='body2'
                  color={
                    platformUsage &&
                    platformUsage?.platformFaceCount >
                      platformUsage?.ruleObj.maxLimitPlatformFaceCount
                      ? 'error.main'
                      : platformUsage?.platformFaceCount ===
                        platformUsage?.ruleObj.maxLimitPlatformFaceCount
                      ? 'warning.main'
                      : 'primary'
                  }
                >
                  {platformUsage?.platformFaceCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj.maxLimitPlatformFaceCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper',
              border: '1px solid #ccc',
            }}
          />
        </Box>
      </Tooltip>
      <TWithProviders
        props={{
          createApi: createPlatformFace,
          getListApi: getPlatformFaceList,
          deleteApi: deletePlatformFace,
        }}
        refreshFunc={refetch => refreshFunc(refetch)}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        displayMode='row'
        handleClick={handleClick}
      />
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardAIPlatformFace() {
  return <Dashboard element={<AIPlatformFaceContent />} />;
}
