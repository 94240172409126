import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import LanguageIcon from '@mui/icons-material/Language';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {i18n} from 'i18next';
import {Box, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'; // 用於標記當前語言

export const LanguageObject: {[k: string]: string} = {
  'en-US': 'English',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文',
  'ja-JP': '日本語',
  'ms-MY': 'Melayu',
};

export default function LanguageSelector({
  i18n,
  theme,
  iconOnly = false,
  isChangeLang = true,
}: {
  i18n: i18n;
  theme: 'light' | 'blue' | 'dark' | 'darkBlue';
  iconOnly?: boolean;
  isChangeLang?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const localeI18n = localStorage.getItem('i18n');
  const localstorageObject: {[k: string]: string} = {
    English: 'en-US',
    简体中文: 'zh-CN',
    繁體中文: 'zh-TW',
    日本語: 'ja-JP',
    Melayu: 'ms-MY',
  };

  const indexI18n = Object.keys(LanguageObject).findIndex(
    d => d === localeI18n,
  );
  const [selectedIndex, setSelectedIndex] = React.useState(
    indexI18n === -1 ? 0 : indexI18n,
  ); // 預設選中第一項
  const languageOptions = Object.values(LanguageObject);

  React.useEffect(() => {
    if (isChangeLang && localeI18n) {
      i18n.changeLanguage(localeI18n);
    }
  }, [i18n, isChangeLang, localeI18n]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    const selectedLang = localstorageObject[languageOptions[index]];
    localStorage.setItem('i18n', selectedLang);
    i18n.changeLanguage(selectedLang);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  // 主題樣式，與 modernTheme 配色一致
  const themeStyles = {
    light: {
      buttonBg: 'rgba(255, 255, 255, 0.9)',
      buttonHoverBg: 'linear-gradient(90deg, #e3f2fd, #ffffff)',
      buttonTextColor: '#34495e', // modernTheme text.primary
      buttonHoverShadow: '0px 4px 16px rgba(52, 73, 94, 0.2)', // 基於 text.primary
      menuBg: '#ffffff',
      menuBorder: '1px solid rgba(0, 0, 0, 0.1)',
      menuTextColor: '#34495e',
      menuHoverBg: '#e3f2fd', // modernTheme primary.light
      menuSelectedBg: '#1976d2', // modernTheme primary.main
      menuSelectedColor: '#ffffff',
    },
    blue: {
      buttonBg: 'rgba(25, 118, 210, 0.2)', // modernTheme primary.main 透明
      buttonHoverBg: 'linear-gradient(90deg, #1976d2, #42a5f5)', // 藍色漸層
      buttonTextColor: '#ffffff',
      buttonHoverShadow: '0px 4px 16px rgba(25, 118, 210, 0.4)',
      menuBg: '#115293', // modernTheme primary.dark
      menuBorder: '1px solid rgba(255, 255, 255, 0.2)',
      menuTextColor: '#ffffff',
      menuHoverBg: '#1976d2', // modernTheme primary.main
      menuSelectedBg: '#e3f2fd', // modernTheme primary.light
      menuSelectedColor: '#34495e',
    },
    darkBlue: {
      buttonBg: 'rgba(17, 82, 147, 0.8)', // 基於 primary.dark
      buttonHoverBg: 'linear-gradient(90deg, #115293, #1976d2)',
      buttonTextColor: '#ffffff',
      buttonHoverShadow: '0px 4px 16px rgba(25, 118, 210, 0.5)',
      menuBg: '#0d3c6b', // 更深的藍色
      menuBorder: '1px solid rgba(255, 255, 255, 0.3)',
      menuTextColor: '#e3f2fd', // modernTheme primary.light
      menuHoverBg: '#1976d2',
      menuSelectedBg: '#f06292', // modernTheme secondary.main
      menuSelectedColor: '#ffffff',
    },
    dark: {
      buttonBg: 'rgba(52, 73, 94, 0.8)', // 基於 text.primary
      buttonHoverBg: 'linear-gradient(90deg, #34495e, #4a627a)',
      buttonTextColor: '#ffffff',
      buttonHoverShadow: '0px 4px 16px rgba(52, 73, 94, 0.4)',
      menuBg: '#263238', // 深灰藍
      menuBorder: '1px solid rgba(255, 255, 255, 0.15)',
      menuTextColor: '#ffffff',
      menuHoverBg: '#37474f',
      menuSelectedBg: '#f06292', // modernTheme secondary.main
      menuSelectedColor: '#ffffff',
    },
  };

  const styles = themeStyles[theme];

  return (
    <React.Fragment>
      <Box ref={anchorRef}>
        {iconOnly ? (
          <IconButton
            onClick={handleToggle}
            sx={{
              backgroundColor: styles.buttonBg,
              borderRadius: '8px',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: styles.buttonHoverBg,
                boxShadow: styles.buttonHoverShadow,
                transform: 'scale(1.05)', // 輕微放大
              },
            }}
          >
            <LanguageIcon sx={{color: styles.buttonTextColor, fontSize: 24}} />
          </IconButton>
        ) : (
          <ButtonGroup
            variant='text'
            aria-label='Language selector button group'
            sx={{backgroundColor: styles.buttonBg}}
          >
            <Button
              size='small'
              sx={{
                minWidth: '40px',
                p: '6px 10px',
                borderRadius: '12px', // 與 modernTheme 一致
                backdropFilter: 'blur(10px)',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: styles.buttonHoverBg,
                  boxShadow: styles.buttonHoverShadow,
                  transform: 'scale(1.05)',
                },
              }}
              aria-controls={open ? 'language-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label='select language'
              aria-haspopup='menu'
              onClick={handleToggle}
            >
              <LanguageIcon
                sx={{color: styles.buttonTextColor, fontSize: 24}}
              />
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <Popper
        sx={{zIndex: 1300}} // 提高 zIndex 避免被其他元素遮蓋
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
            timeout={300} // 動畫時長
          >
            <Paper
              sx={{
                backgroundColor: styles.menuBg,
                border: styles.menuBorder,
                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.25)', // 更柔和的陰影
                borderRadius: '12px', // 與 modernTheme 一致
                minWidth: '140px',
                overflow: 'hidden',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem sx={{py: 0.5}}>
                  {languageOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                      sx={{
                        color: styles.menuTextColor,
                        py: 1,
                        px: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        transition: 'all 0.2s ease',
                        backgroundColor:
                          index === selectedIndex
                            ? styles.menuSelectedBg
                            : 'transparent',
                        '&:hover': {
                          backgroundColor: styles.menuHoverBg,
                        },
                        '&.Mui-selected': {
                          backgroundColor: styles.menuSelectedBg,
                          color: styles.menuSelectedColor,
                          '&:hover': {
                            backgroundColor: styles.menuSelectedBg,
                          },
                        },
                      }}
                    >
                      <Typography variant='body2' sx={{fontWeight: 500}}>
                        {option}
                      </Typography>
                      {index === selectedIndex && (
                        <CheckIcon sx={{fontSize: 16, ml: 1}} />
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
