import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PlatformUserGrow from '../../components/PlatformUserGrow';
import PlatformPlan from '../../components/PlatformPlan';
import EventList from '../../components/EventList';
import Copyright from '../../components/Copyright';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import {Alert, AlertColor, Snackbar, SnackbarOrigin} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {useState} from 'react';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
  autoHideDuration: number;
}
function DashboardContent() {
  const {t} = useTranslation();

  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
    autoHideDuration: ALERT_HIDE_DURATION,
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleClick = (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => {
    setState({
      ...state,
      type,
      message,
      open: true,
      autoHideDuration: autoHideDuration || ALERT_HIDE_DURATION,
    });
  };

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth='lg' sx={{mt: 4, mb: 4}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <PlatformUserGrow t={t} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <PlatformPlan t={t} handleClick={handleClick} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
              <EventList t={t} />
            </Paper>
          </Grid>
        </Grid>
        <Copyright sx={{pt: 4}} />
        <Snackbar
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
          open={open}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant='filled'
            sx={{width: '100%'}}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
}

export default function DashboardIndex() {
  return <Dashboard element={DashboardContent()}></Dashboard>;
}
