import {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {PlatformEmailDto} from '../../api/dto/platformEmail';

export const EmailHtmlCell = ({
  platformEmail,
  t,
  showType = 'link',
}: {
  platformEmail: PlatformEmailDto | null;
  t: any;
  showType?: 'icon' | 'link';
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
      {showType === 'icon' ? (
        <IconButton onClick={handleOpen}>
          <AccountCircleIcon />
        </IconButton>
      ) : (
        <span
          onClick={handleOpen}
          style={{
            cursor: 'pointer',
            color: '#007bff',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          {platformEmail?.subject}
        </span>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle sx={{bgcolor: '#f5f5f5', fontWeight: 'bold'}}>
          {t('emailHtmlDetails')}
        </DialogTitle>
        <DialogContent dividers>
          {platformEmail ? (
            <Box
              sx={{
                p: 2,
                typography: 'body1',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography>
                <strong>ID:</strong> {platformEmail.id}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('emailHtmlSubject')}:</strong>{' '}
                {platformEmail.subject}
              </Typography>
              <Divider />
              {platformEmail.to && platformEmail.to.length > 0 && (
                <>
                  <Typography>
                    <strong>{t('emailHtmlTo')}:</strong>{' '}
                    {platformEmail.to
                      .map(recipient => recipient.mailAddress)
                      .join(', ')}
                  </Typography>
                  <Divider />
                </>
              )}
              {platformEmail.cc && platformEmail.cc.length > 0 && (
                <>
                  <Typography>
                    <strong>{t('emailHtmlCc')}:</strong>{' '}
                    {platformEmail.cc
                      .map(recipient => recipient.mailAddress)
                      .join(', ')}
                  </Typography>
                  <Divider />
                </>
              )}
              <Typography>
                <strong>{t('emailHtmlStatus')}:</strong>{' '}
                {platformEmail.status ?? t('unknown')}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('emailHtmlScheduleDate')}:</strong>{' '}
                {new Date(
                  platformEmail.scheduleDate || platformEmail.createdAt,
                ).toLocaleString()}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('emailHtmlBody')}:</strong>
              </Typography>
              <Box sx={{p: 1, bgcolor: '#f9f9f9', borderRadius: 1}}>
                <Typography variant='body2'>
                  {platformEmail.bodyHtml ?? platformEmail.bodyText}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography color='error' align='center'>
              {t('emailHtmlNotEmailData')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center', bgcolor: '#f5f5f5'}}>
          <Button onClick={handleClose} variant='contained' color='primary'>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
