import {AxiosResponse} from 'axios';
import {
  GetShortenUrlListDto,
  PatchShortenUrlDto,
  PostShortenUrlDto,
  ShortenUrlDto,
} from './dto/shortenUrl';
import callApi from './index';

export const createShortenUrl = (
  data: PostShortenUrlDto,
): Promise<AxiosResponse<{data: ShortenUrlDto}, any>> | undefined => {
  data.maxViews = parseInt(data.maxViews as any);

  return callApi('post', `/shorten-url`, data);
};

export const getShortenUrlList = (
  data: GetShortenUrlListDto,
): Promise<AxiosResponse<{items: ShortenUrlDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/shorten-url/list`, options);
};

export const getShortenUrlHash = (hash: string, data: {password?: string}) => {
  return callApi('get', `/shorten-url/hash/${hash}`, data) as Promise<
    AxiosResponse<ShortenUrlDto, any>
  >;
};

export const updateShortenUrl = (id: string, data: PatchShortenUrlDto) => {
  return callApi('patch', `/shorten-url/${id}`, data);
};

export const deleteShortenUrl = (id: string) => {
  return callApi('delete', `/shorten-url/${id}`);
};

export const deleteManyShortenUrl = (ids: string[]) => {
  return callApi('delete', `/shorten-url/bulk`, {ids});
};
