import React, {useEffect, useState} from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  IconButton,
  Link,
  Snackbar,
  Alert,
  SnackbarOrigin,
  AlertColor,
  Paper,
} from '@mui/material';
import {useParams, useLocation} from 'react-router-dom';
import {EditorState, convertToRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {PlatformDto} from '../../api/dto/platform';
import {createPlatformContact} from '../../api/platformContact';
import {getPlatformPublic} from '../../api/platform';
import {ALERT_HIDE_DURATION} from '../../constant';
import {useTranslation} from 'react-i18next';
import LanguageSelector from '../../components/LanguageSelector';
import PolicyIcon from '@mui/icons-material/Policy';
import {Fade, Zoom} from 'react-awesome-reveal';
import {HttpStatusCode} from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import SubjectIcon from '@mui/icons-material/Subject';
import {ExtensionRounded} from '@mui/icons-material';
import draftToHtml from 'draftjs-to-html';
import CircularProgress from '@mui/material/CircularProgress';
import {CloudUpload, CheckCircle, Error} from '@mui/icons-material';
import {motion} from 'framer-motion';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
  autoHideDuration: number;
}

const ContactForm: React.FC = () => {
  const {platformId} = useParams<{platformId: string}>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const langParam = searchParams.get('lang');
  const isLangSelectorParam = searchParams.get('isLangSelector');
  const showLangSelector = isLangSelectorParam === 'false' ? false : true;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isPlatformNameParam = searchParams.get('isPlatformName');
  const isPlatformIconParam = searchParams.get('isPlatformIcon');
  const showPlatformName = isPlatformNameParam === 'false' ? false : true;
  const showPlatformIcon = isPlatformIconParam === 'false' ? false : true;

  const isTitleParam = searchParams.get('isTitle');
  const showTitle = isTitleParam === 'false' ? false : true;

  const {t, i18n} = useTranslation();

  const [platform, setPlatform] = useState<PlatformDto>();
  const [platformError, setPlatformError] = useState<boolean>(false);
  const [platformTextError, setPlatformTextError] = useState<string>('');

  const additionalFieldsParam = searchParams.get('fields');
  const allowedAdditionalFields = [
    'email',
    'nickname',
    'type',
    'phone',
    'externalType',
    'location',
  ];
  const additionalFieldsToShow: string[] = additionalFieldsParam
    ? additionalFieldsParam
        .split(',')
        .map(f => f.trim())
        .filter(f => allowedAdditionalFields.includes(f))
    : ['email', 'nickname'];

  const [additionalData, setAdditionalData] = useState<Record<string, string>>(
    () => {
      const initState: Record<string, string> = {};
      additionalFieldsToShow.forEach(field => {
        initState[field] = '';
      });
      return initState;
    },
  );

  const [subject, setSubject] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [file, setFile] = useState<File | null>(null);
  const [editorLang, setEditorLang] = useState('en');
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    autoHideDuration: ALERT_HIDE_DURATION,
    vertical: 'bottom',
    horizontal: 'right',
  });

  const handleClose = () => {
    setState(prev => ({...prev, open: false}));
  };
  const handleClick = (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => {
    setState({
      ...state,
      type,
      message,
      open: true,
      autoHideDuration: autoHideDuration || ALERT_HIDE_DURATION,
    });
  };

  const [submissionResult, setSubmissionResult] = useState<
    'success' | 'error' | null
  >(null);

  const handleAdditionalChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {name, value} = event.target;
    setAdditionalData(prev => ({...prev, [name]: value}));
  };

  const handleSubjectChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSubject(event.target.value);
  };

  const handleEditorChange = (state: EditorState) => {
    const currentText = state.getCurrentContent().getPlainText();
    if (currentText.length <= 1000) {
      setEditorState(state);
    } else {
      handleClick('error', t('platformContactEditorLimitError'));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const contentHtml = draftToHtml(rawContentState);

    if (subject.length > 100) {
      setIsSubmitting(false);
      handleClick('error', t('platformContactSubjectLimitError'));
      return;
    }
    if (contentHtml.length > 5000) {
      setIsSubmitting(false);
      handleClick('error', t('platformContactContentLimitError'));
      return;
    }

    const payload = {
      ...additionalData,
      subject,
      content: contentHtml,
    };

    createPlatformContact(payload)
      ?.then(d => {
        if (d.status !== HttpStatusCode.Ok) {
          setSubmissionResult('error');
          setPlatformTextError(JSON.stringify(d.data));
          setTimeout(() => setSubmissionResult(null), 5000);
          setIsSubmitting(false);
          return;
        }
        setSubmissionResult('success');
        setAdditionalData({});
        setSubject('');
        setEditorState(EditorState.createEmpty());
        setTimeout(() => setSubmissionResult(null), 5000);
        setIsSubmitting(false);
      })
      .catch(e => {
        setSubmissionResult('error');
        setPlatformTextError(JSON.stringify(e?.message) || t('apiUnknownFail'));
        setTimeout(() => setSubmissionResult(null), 5000);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    switch (i18n.language) {
      case 'en-US':
      case 'ms-MY':
        setEditorLang('en');
        break;
      case 'zh-TW':
        setEditorLang('zh_tw');
        break;
      case 'zh-CN':
        setEditorLang('zh');
        break;
      case 'ja-JP':
        setEditorLang('ja');
        break;
      default:
        setEditorLang('en');
    }
  }, [t]);

  useEffect(() => {
    i18n.changeLanguage(langParam || 'en-US');
  }, [langParam]);

  useEffect(() => {
    localStorage.setItem('platformId', platformId || '');
    getPlatformPublic()
      ?.then(d => {
        if (d.status !== HttpStatusCode.Ok) {
          handleClick('error', JSON.stringify(d.data));
          return;
        }
        setPlatform(d.data.data);
      })
      .catch(e => {
        handleClick('error', JSON.stringify(e?.message));
      });
  }, [platformId]);

  const additionalFieldConfig: Record<
    string,
    {label: string; type: string; maxLength?: number}
  > = {
    email: {label: t('emailHeader'), type: 'email'},
    nickname: {label: t('nicknameHeader'), type: 'text', maxLength: 50},
    type: {label: t('typeHeader'), type: 'text'},
    phone: {label: t('phoneHeader'), type: 'tel'},
    externalType: {label: t('externalTypeHeader'), type: 'text'},
    location: {label: t('locationHeader'), type: 'text'},
  };

  const getFieldIcon = (field: string) => {
    switch (field) {
      case 'nickname':
        return <PersonIcon />;
      case 'email':
        return <EmailIcon />;
      case 'phone':
        return <PhoneIcon />;
      case 'subject':
        return <SubjectIcon />;
      case 'type':
        return <ExtensionRounded />;
      default:
        return <InfoIcon />;
    }
  };

  return (
    <Container maxWidth='sm' sx={{py: 6}}>
      <motion.div
        initial={{opacity: 0, y: 10}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.3}}
      >
        <Paper
          elevation={8}
          sx={{
            p: 5,
            borderRadius: 4,
            boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.15)',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {showLangSelector && submissionResult === null && !platformError && (
            <Box sx={{position: 'absolute', top: 16, right: 16}}>
              <LanguageSelector
                i18n={i18n}
                theme='darkBlue'
                iconOnly
                isChangeLang={false}
              />
            </Box>
          )}
          {platformError ? (
            <Box sx={{textAlign: 'center', py: 6}}>
              <Error sx={{fontSize: 80, color: 'error.main'}} />
              <Typography variant='h6' sx={{mt: 2}}>
                {t('platformFetchFailed')}
              </Typography>
            </Box>
          ) : submissionResult !== null ? (
            <Box sx={{textAlign: 'center', py: 6}}>
              {submissionResult === 'success' ? (
                <>
                  <CheckCircle sx={{fontSize: 80, color: 'success.main'}} />
                  <Typography variant='h6' sx={{mt: 2}}>
                    {t('submitSuccess')}
                  </Typography>
                </>
              ) : (
                <>
                  <Error sx={{fontSize: 80, color: 'error.main'}} />
                  <Typography variant='h6' sx={{mt: 2}}>
                    {platformTextError}
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <>
              {(showPlatformName || showPlatformIcon) && platform && (
                <Fade triggerOnce>
                  <Box
                    sx={{
                      mb: 3,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    {showPlatformIcon && platform.icon && (
                      <Zoom triggerOnce>
                        <Box
                          component='img'
                          src={platform.icon}
                          alt={`${platform.name} icon`}
                          sx={{
                            width: 50,
                            height: 50,
                          }}
                        />
                      </Zoom>
                    )}
                    {showPlatformName && (
                      <Box>
                        <Typography
                          variant='h6'
                          sx={{fontWeight: 'bold', color: 'text.primary'}}
                        >
                          {platform.name}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Fade>
              )}

              {showTitle && (
                <Typography
                  variant='h5'
                  gutterBottom
                  textAlign='center'
                  sx={{fontWeight: 'bold'}}
                >
                  {t('contactUs')}
                </Typography>
              )}

              <Box component='form' onSubmit={handleSubmit} sx={{mt: 3}}>
                {additionalFieldsToShow.map(field => (
                  <TextField
                    key={field}
                    fullWidth
                    variant='outlined'
                    label={
                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {getFieldIcon(field)}
                        <Typography
                          variant='body1'
                          sx={{ml: 1, display: 'flex', alignItems: 'center'}}
                        >
                          {additionalFieldConfig[field]?.label || field}
                          <Box
                            component='span'
                            sx={{
                              color: 'error.main',
                              ml: 0.5,
                              fontSize: 'inherit',
                              lineHeight: 1,
                            }}
                          >
                            *
                          </Box>
                        </Typography>
                      </Box>
                    }
                    name={field}
                    type={additionalFieldConfig[field]?.type || 'text'}
                    value={additionalData[field] || ''}
                    onChange={handleAdditionalChange}
                    margin='normal'
                    required
                    sx={{borderRadius: 2}}
                    InputLabelProps={{
                      required: false,
                    }}
                    inputProps={{
                      maxLength: additionalFieldConfig[field]?.maxLength,
                    }}
                  />
                ))}

                <TextField
                  fullWidth
                  variant='outlined'
                  label={
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      {getFieldIcon('subject')}
                      <Typography
                        variant='body1'
                        sx={{ml: 1, display: 'flex', alignItems: 'center'}}
                      >
                        {t('subjectContactHeader')}
                        <Box
                          component='span'
                          sx={{
                            color: 'error.main',
                            ml: 0.5,
                            fontSize: 'inherit',
                            lineHeight: 1,
                          }}
                        >
                          *
                        </Box>
                      </Typography>
                    </Box>
                  }
                  name='subject'
                  value={subject}
                  onChange={handleSubjectChange}
                  margin='normal'
                  required
                  sx={{borderRadius: 2}}
                  inputProps={{maxLength: 100}}
                  InputLabelProps={{
                    required: false,
                  }}
                />

                <Typography
                  variant='subtitle1'
                  sx={{mt: 3, fontWeight: 'bold'}}
                >
                  {t('contentHeader')}
                </Typography>
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    p: 2,
                    minHeight: 250,
                    bgcolor: 'background.paper',
                    boxShadow: 'inset 0px 2px 6px rgba(0,0,0,0.1)',
                  }}
                >
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName='custom-editor-wrapper'
                    editorClassName='custom-editor-content'
                    localization={{locale: editorLang}}
                    toolbar={{
                      options: ['inline', 'link', 'history'],
                    }}
                  />
                </Box>

                <Box sx={{mt: 3, display: 'flex', alignItems: 'center'}}>
                  {/* 可根據需要開啟上傳檔案功能 */}
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    component="label"
                    startIcon={<CloudUpload />}
                    sx={{borderRadius: 2}}
                  >
                    {t('uploadFile')}
                    <input type="file" hidden onChange={handleFileChange} />
                  </Button> */}
                  {file && (
                    <Typography variant='body2' sx={{ml: 2}}>
                      {file.name}
                    </Typography>
                  )}
                </Box>

                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  sx={{
                    mt: 4,
                    borderRadius: 3,
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    py: 1.5,
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    t('submitBtn')
                  )}
                </Button>

                {platform?.policyUrl && (
                  <Box sx={{mt: 3, textAlign: 'center'}}>
                    <Link
                      href={platform.policyUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                      underline='hover'
                      sx={{display: 'inline-flex', alignItems: 'center'}}
                    >
                      <PolicyIcon color='primary' sx={{mr: 1}} />
                      <Typography variant='body2' color='primary'>
                        {t('dashboardPlatformInfoPlatformPolicyUrl')}
                      </Typography>
                    </Link>
                  </Box>
                )}
              </Box>
            </>
          )}

          <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            open={state.open}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={state.type} variant='filled'>
              {state.message}
            </Alert>
          </Snackbar>
        </Paper>
      </motion.div>
    </Container>
  );
};

export default ContactForm;
