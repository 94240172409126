import React, {useState, useEffect} from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Snackbar,
  Alert,
  AlertColor,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';
import {TFunction} from 'i18next';
import {
  GetUserNotificationListDto,
  UserNotificationDto,
} from '../../api/dto/userNotification';
import {
  getUserMeNotificationList,
  patchUserNotification,
  deleteUserNotification,
} from '../../api/userNotification';
import {ALERT_HIDE_DURATION} from '../../constant';

const NotificationButton = ({t}: {t: TFunction<'translation', undefined>}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<
    Array<UserNotificationDto>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] =
    useState<UserNotificationDto | null>(null);
  const [selectedDeleteNotification, setSelectedDeleteNotification] =
    useState<UserNotificationDto | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarType, setShowSnackbarType] =
    useState<AlertColor>('success');
  const [showSnackbarMessage, setShowSnackbarMessage] = useState<string>();

  const unreadCount = notifications.filter(n => !n.isRead).length;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleNotificationClick = async (notification: UserNotificationDto) => {
    setSelectedNotification(notification);

    if (!notification.isRead) {
      try {
        await patchUserNotification(notification.id, {isRead: true});
        setNotifications(prev =>
          prev.map(n => (n.id === notification.id ? {...n, isRead: true} : n)),
        );
      } catch (error) {
        setShowSnackbarType('error');
        setShowSnackbarMessage(JSON.stringify(error));
        setShowSnackbar(true);
      }
    }
  };

  const handleDeleteClick = (notification: UserNotificationDto) => {
    setSelectedDeleteNotification(notification);
    setSelectedNotification(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedDeleteNotification) {
      try {
        await deleteUserNotification(selectedDeleteNotification.id);
        setNotifications(prev =>
          prev.filter(n => n.id !== selectedDeleteNotification.id),
        );
      } catch (error) {
        setShowSnackbarType('error');
        setShowSnackbarMessage(JSON.stringify(error));
        setShowSnackbar(true);
      } finally {
        setSelectedDeleteNotification(null);
      }
    }
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const data: GetUserNotificationListDto = {
        orderBy: 'createdAt',
        sort: 'DESC',
      };
      const response = await getUserMeNotificationList(data);
      setNotifications(response?.data?.items ?? []);
    } catch (error) {
      setShowSnackbarType('error');
      setShowSnackbarMessage(JSON.stringify(error));
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <IconButton color='inherit' onClick={handleClick}>
        <Badge badgeContent={unreadCount} color='warning'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '12px',
            backgroundColor: '#F5F5F5',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            backdropFilter: 'blur(10px)',
            border: '1px solid #E0E0E0',
            color: '#333',
          },
        }}
      >
        {loading ? (
          <MenuItem
            disabled
            sx={{display: 'flex', justifyContent: 'center', width: '320px'}}
          >
            <CircularProgress size={24} sx={{color: '#007ACC'}} />
          </MenuItem>
        ) : notifications.length > 0 ? (
          <>
            <MenuItem
              disabled
              sx={{
                width: '320px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontFamily: `'Roboto', sans-serif`,
                color: '#333',
                borderBottom: '1px solid #000000',
              }}
            >
              {t('notificationPageTitle')} ({notifications.length})
            </MenuItem>
            {notifications.map((notification, index) => (
              <React.Fragment key={notification.id}>
                <MenuItem
                  onClick={() => handleNotificationClick(notification)}
                  sx={{
                    width: '320px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#E3F2FD',
                    },
                  }}
                >
                  <ListItemText
                    primary={notification.title}
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: notification.isRead ? 'normal' : 'bold',
                        color: notification.isRead ? '#757575' : '#007ACC',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                    sx={{
                      flex: 1,
                      overflow: 'hidden',
                    }}
                  />
                  <IconButton
                    size='small'
                    sx={{flexShrink: 0}}
                    onClick={e => {
                      e.stopPropagation();
                      handleDeleteClick(notification);
                    }}
                  >
                    <DeleteIcon sx={{color: '#D32F2F'}} />
                  </IconButton>
                </MenuItem>

                {index < notifications.length - 1 && (
                  <Divider sx={{backgroundColor: '#E0E0E0'}} />
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <MenuItem
            disabled
            sx={{width: '320px', textAlign: 'center', color: '#757575'}}
          >
            <Typography>{t('notificationPageNoNotify')}</Typography>
          </MenuItem>
        )}
      </Menu>

      <Dialog
        open={Boolean(selectedNotification)}
        onClose={() => setSelectedNotification(null)}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
            p: 3,
            maxWidth: '80rem',
            minWidth: '20rem',
            backdropFilter: 'blur(10px)',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E0E0E0',
            color: '#333',
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 1,
          }}
        >
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <NotificationImportantIcon sx={{mr: 1, color: 'primary.main'}} />
            <Typography variant='h6' component='div'>
              {selectedNotification?.title}
            </Typography>
          </Box>
          <IconButton onClick={() => setSelectedNotification(null)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant='body1'>
            {selectedNotification?.message}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        open={Boolean(selectedDeleteNotification)}
        onClose={() => setSelectedDeleteNotification(null)}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            boxShadow: '0 10px 40px rgba(0, 0, 0, 0.1)',
            padding: '24px',
            maxWidth: '80rem',
            minWidth: '20rem',
            backdropFilter: 'blur(10px)',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E0E0E0',
            color: '#333',
          },
        }}
      >
        <DialogTitle>{t('notificationPageConfirmDelete')}</DialogTitle>
        <DialogContent>
          <Typography>{selectedDeleteNotification?.title}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedDeleteNotification(null)}>
            {t('cancelBtn')}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color='error'
            variant='contained'
          >
            {t('deleteBtn')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleCloseSnackbar}
      >
        <Alert
          severity={showSnackbarType}
          variant='filled'
          sx={{width: '100%'}}
        >
          {showSnackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotificationButton;
