import React, {useState, useRef} from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import {
  Button,
  CircularProgress,
  Typography,
  Paper,
  Alert,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import {AxiosResponse, HttpStatusCode} from 'axios';
import {Fade} from 'react-awesome-reveal';

interface CsvUploadProps {
  onSubmitApi: (data: any) => Promise<AxiosResponse<any, any> | undefined>;
  t: any;
}

const CsvUpload: React.FC<CsvUploadProps> = ({onSubmitApi, t}) => {
  const [csvData, setCsvData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<
    'idle' | 'success' | 'error'
  >('idle');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const MAX_FILE_SIZE = 20 * 1024 * 1024;
  const validateFile = (file: File): string | null => {
    if (file.size > MAX_FILE_SIZE) {
      return t('dashboardPlatformDeviceUploadSizeLimit');
    }

    if (
      !(
        file.name.endsWith('.csv') ||
        file.name.endsWith('.xls') ||
        file.name.endsWith('.xlsx')
      )
    ) {
      return t('dashboardPlatformDeviceUploadTypeHelptxt');
    }

    return null;
  };

  const handleCsvFileChange = (file: File) => {
    Papa.parse(file, {
      complete: result => {
        const data = result.data;
        setCsvData(data);
      },
      header: true,
    });
  };

  const handleXlsFileChange = (file: File) => {
    const reader = new FileReader();
    reader.onload = e => {
      const data = e.target?.result;
      if (data) {
        const workbook = XLSX.read(data, {type: 'binary'});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows: any[] = XLSX.utils.sheet_to_json(sheet, {header: 1});

        if (rows.length < 2) return [];
        const keys = rows[0] as string[];
        const result = rows
          .slice(1)
          .map(row =>
            row.reduce((obj: any, value: any, index: any) => {
              switch (keys[index]) {
                case '設備編號':
                  obj['deviceNo'] = value || null;
                  break;
                case '地點':
                  obj['location'] = value || null;
                  break;
                case '運轉時數':
                  obj['runHours'] =
                    typeof value === 'string' ? null : Math.round(value);
                  break;
                case '上次保養時數':
                  obj['lastMaintenanceHours'] =
                    typeof value === 'string' ? null : Math.round(value);
                  break;
                case '上次保養日期':
                  obj['lastMaintenanceDate'] = value || null;
                  break;
                case '第一道柴油是否更換':
                  obj['isFirstDieselReplaced'] = value || null;
                  break;
                default:
                  obj[keys[index]] = value || null;
              }
              return obj;
            }, {} as Record<string, any>),
          )
          .filter(
            item =>
              item.deviceNo !== null &&
              item.deviceNo !== undefined &&
              item.deviceNo !== '',
          );

        setCsvData(result);
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setUploadStatus('idle');

    const file = event.target.files?.[0];
    if (file) {
      const validationError = validateFile(file);
      if (validationError) {
        setError(validationError);
        return;
      }

      if (file.name.endsWith('.csv')) {
        handleCsvFileChange(file);
      } else {
        handleXlsFileChange(file);
      }
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleUpload = async () => {
    if (csvData.length === 0) {
      setError(t('dashboardPlatformDeviceUploadTypeErr'));
      return;
    }

    setLoading(true);
    setUploadStatus('idle');

    const response = await onSubmitApi({items: csvData});
    if (response && response.status === HttpStatusCode.Ok) {
      setUploadStatus('success');
    } else {
      setUploadStatus('error');
      setError(t('dashboardPlatformDeviceUploadFail'));
    }

    setLoading(false);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <input
        type='file'
        accept='.csv, .xls, .xlsx'
        onChange={handleFileChange}
        style={{display: 'none'}}
        id='upload-file'
        ref={fileInputRef}
      />
      <label htmlFor='upload-file'>
        <Button
          variant='contained'
          component='span'
          startIcon={<CloudUploadIcon />}
          color='primary'
          disabled={loading}
        >
          {t('dashboardPlatformDeviceUploadFile')}
        </Button>
      </label>

      {csvData.length > 0 && (
        <Typography variant='body2' color='text.secondary'>
          {t('dashboardPlatformDeviceAlreadyUpload')}
        </Typography>
      )}

      {loading ? (
        <CircularProgress size={24} />
      ) : uploadStatus === 'success' ? (
        <Button
          variant='contained'
          color='success'
          startIcon={<CheckCircleIcon />}
          disabled
        >
          {t('dashboardPlatformDeviceUploadSuccess')}
        </Button>
      ) : uploadStatus === 'error' ? (
        <></>
      ) : (
        csvData.length > 0 && (
          <Fade triggerOnce>
            <Button variant='contained' onClick={handleUpload} color='primary'>
              {t('submitBtn')}
            </Button>
          </Fade>
        )
      )}

      {error && (
        <Alert severity='error' variant='outlined'>
          {error}
        </Alert>
      )}
    </Paper>
  );
};

export default CsvUpload;
