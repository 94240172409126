import React from 'react';
import {TFunction} from 'i18next';
import {Alert, AlertTitle, Box, keyframes} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

// 淡入動畫
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// 圖標脈動動畫
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
`;

const NoPermissionAlert = ({t}: {t: TFunction<'translation', undefined>}) => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0',
        animation: `${fadeIn} 0.5s ease-out`,
      }}
    >
      <Alert
        severity='error'
        icon={
          <LockIcon
            fontSize='large'
            sx={{
              animation: `${pulse} 1.8s infinite ease-in-out`,
            }}
          />
        }
        style={{maxWidth: '600px', width: '100%'}}
      >
        <AlertTitle>
          {t('dashboardAlertNotPermissionTitle', 'Access Denied')}
        </AlertTitle>
        {t(
          'dashboardAlertNotPermission',
          'You do not have permission to access this section.',
        )}
      </Alert>
    </Box>
  );
};

export default NoPermissionAlert;
