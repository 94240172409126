import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  CircularProgress,
  Link,
  useMediaQuery,
  useTheme,
  Card,
  CardHeader,
} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
import {HttpStatusCode} from 'axios';
import {getEventList} from '../api/event';
import {EventDto} from '../api/dto/event';
import {TFunction} from 'i18next';
import Title from './Title';
import PopoverHelper from './Popover';
import EventIcon from '@mui/icons-material/Event';
import {Trans} from 'react-i18next';

export default function EventList({
  t,
}: {
  t: TFunction<'translation', undefined>;
}) {
  const [events, setEvents] = useState<EventDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState<EventDto | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const fetchEvents = async (currentPage: number) => {
    setLoading(true);
    try {
      const eventList = await getEventList({limit: 10, page: currentPage});
      if (eventList?.status === HttpStatusCode.Ok) {
        setEvents(prev =>
          currentPage === 1
            ? eventList?.data?.items
            : [...prev, ...(eventList?.data?.items || [])],
        );
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents(1);
  }, []);

  const loadMore = () => {
    const nextPage = page + 1;
    fetchEvents(nextPage);
    setPage(nextPage);
  };

  return (
    <React.Fragment>
      <Title>
        {t('dashboardAccountRecentEvent')}
        <PopoverHelper
          element={
            <Card>
              <CardHeader
                avatar={<EventIcon color='primary' />}
                title={t('dashboardAccountRecentEventPopoverTitle')}
                subheader={
                  <Trans
                    i18nKey='dashboardAccountRecentEventPopoverDescription'
                    values={{permission: 'events:View'}}
                    components={{strong: <strong />}}
                  />
                }
              />
            </Card>
          }
        />{' '}
      </Title>
      <Table
        size='small'
        sx={{borderRadius: 2, overflow: 'hidden', border: '1px solid #ddd'}}
      >
        <TableHead>
          <TableRow sx={{backgroundColor: '#f4f6f8'}}>
            {[
              'dashboardAccountEventDate',
              'dashboardAccountEventTitle',
              'dashboardAccountEventMessage',
              'dashboardAccountEventEntity',
              'dashboardAccountEventMethod',
            ].map(key => (
              <TableCell
                key={key}
                sx={{fontWeight: 'bold', textAlign: 'center'}}
              >
                {t(key)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                <CircularProgress size={24} />
              </TableCell>
            </TableRow>
          ) : events?.length > 0 ? (
            events.map(event => (
              <TableRow
                key={event.id}
                hover
                onClick={() => {
                  setSelectedEvent(event);
                  setOpenDialog(true);
                }}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f9fafb',
                    boxShadow: '0px 1px 4px rgba(0,0,0,0.1)',
                  },
                }}
              >
                <TableCell align='center'>
                  {new Date(event.createdAt).toLocaleString()}
                </TableCell>
                <TableCell align='center'>{event.title}</TableCell>
                <TableCell align='center'>{event.message}</TableCell>
                <TableCell align='center'>{event.entityName}</TableCell>
                <TableCell align='center'>{event.method}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align='center'>
                {t('dashboardNoEvents')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Link
        color='primary'
        href='#'
        onClick={e => e.preventDefault()}
        sx={{
          mt: 3,
          display: 'block',
          textAlign: 'center',
          fontSize: '0.9rem',
          fontWeight: 'bold',
        }}
      >
        <span onClick={loadMore}>{t('dashboardAccountSeeMoreEvent')}</span>
      </Link>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth='md'
        fullScreen={fullScreen}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 3,
            boxShadow: 3,
          },
        }}
      >
        <DialogActions
          sx={{
            justifyContent: 'space-between',
            padding: '12px 16px',
            borderBottom: '1px solid #ddd',
          }}
        >
          <DialogTitle
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              flexGrow: 1,
              fontSize: '1.25rem',
            }}
          >
            {t('dashboardAccountEventDetails')}
          </DialogTitle>
          <IconButton
            onClick={() => setOpenDialog(false)}
            sx={{
              '&:hover': {backgroundColor: 'rgba(0,0,0,0.1)'},
              transition: 'background-color 0.2s',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent
          sx={{
            padding: '20px',
            display: 'grid',
            gap: 2,
            backgroundColor: '#f9f9f9',
            borderRadius: '0 0 12px 12px',
          }}
        >
          {selectedEvent &&
            [
              'title',
              'message',
              'userId',
              'entityName',
              'entityId',
              'method',
              'body',
              'createdAt',
            ].map(field => (
              <DialogContentText
                key={field}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontSize: '0.95rem',
                  backgroundColor: 'white',
                  padding: '8px 12px',
                  borderRadius: 2,
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                }}
              >
                <strong style={{minWidth: '120px', color: '#555'}}>
                  {t(
                    `dashboardAccountEvent${
                      field.charAt(0).toUpperCase() + field.slice(1)
                    }`,
                  )}
                  :
                </strong>
                <span style={{color: '#333', whiteSpace: 'pre-wrap'}}>
                  {field === 'createdAt'
                    ? new Date(selectedEvent[field]).toLocaleString()
                    : field === 'body'
                    ? (() => {
                        try {
                          const json =
                            typeof selectedEvent[field] === 'string'
                              ? JSON.parse(selectedEvent[field])
                              : selectedEvent[field];
                          return JSON.stringify(json, null, 4);
                        } catch (err) {
                          return selectedEvent[field];
                        }
                      })()
                    : selectedEvent[field]}
                </span>
              </DialogContentText>
            ))}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
