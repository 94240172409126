import {PlatformFaceResultDto} from '../../api/dto/platformFace';
import {PieChart, pieArcLabelClasses} from '@mui/x-charts';
import {CopyAll as CopyAllIcon} from '@mui/icons-material';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  Card,
  CardContent,
  Stack,
  Divider,
  Avatar,
  Tooltip,
  IconButton,
  DialogActions,
} from '@mui/material';
import {useState, useRef, useEffect} from 'react';

export const ViewPLatformFaceColumn = ({cell, t, row}: any) => {
  const results = cell.getValue() as PlatformFaceResultDto[];
  const [open, setOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] =
    useState<PlatformFaceResultDto | null>(null);

  const [originalDimensions, setOriginalDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [renderedDimensions, setRenderedDimensions] = useState({
    width: 0,
    height: 0,
  });
  const imageRef = useRef<HTMLDivElement | null>(null);
  const imageUrl = row.original?.img;
  const pieParams = {
    height: 200,
    margin: {right: 5},
    slotProps: {legend: {hidden: true}},
  };

  const handleCopyLink = (imgUrl: string) => {
    navigator.clipboard
      .writeText(imgUrl)
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setOriginalDimensions({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };
    }
  }, [imageUrl]);

  // 若用 onEntered 處理首次渲染就能正確拿到 renderedDimensions，就可以移除 useEffect 依 open 重新計算的邏輯
  // useEffect(() => {
  //   if (imageRef.current) {
  //     setRenderedDimensions({
  //       width: imageRef.current.offsetWidth,
  //       height: imageRef.current.offsetHeight,
  //     });
  //   }
  // }, [imageUrl, open]);

  // 沒有資料時直接回傳
  if (!results || results.length === 0) return <></>;

  // 計算縮放比例
  const scaleX = originalDimensions.width
    ? renderedDimensions.width / originalDimensions.width
    : 1;
  const scaleY = originalDimensions.height
    ? renderedDimensions.height / originalDimensions.height
    : 1;

  // 根據縮放比例調整紅框位置與大小
  const regionStyle = selectedPerson?.region
    ? {
        position: 'absolute',
        top: selectedPerson.region.y * scaleY,
        left: selectedPerson.region.x * scaleX,
        width: selectedPerson.region.w * scaleX,
        height: selectedPerson.region.h * scaleY,
        border: '2px solid red',
        boxSizing: 'border-box',
        pointerEvents: 'none',
      }
    : {};

  return (
    <Box>
      {results.map((person, index) => {
        return (
          <Box
            key={index}
            sx={{
              position: 'relative',
              mb: 1,
              p: 1,
              border: '1px solid #ddd',
              borderRadius: '8px',
            }}
          >
            <Typography variant='body2'>
              <strong>#{index + 1}</strong> |{' '}
              <strong>{t('dashboardMenuAIPlatformFaceAge')}:</strong>{' '}
              {person.age} |{' '}
              <strong>{t('dashboardMenuAIPlatformFaceGender')}:</strong>{' '}
              {t(`dashboardMenuAIPlatformFaceGender_${person.dominant_gender}`)}
            </Typography>
            <Typography variant='body2'>
              <strong>{t('dashboardMenuAIPlatformFaceEmotion')}:</strong>{' '}
              {t(
                `dashboardMenuAIPlatformFaceEmotion_${person.dominant_emotion}`,
              )}{' '}
              | <strong>{t('dashboardMenuAIPlatformFaceRace')}:</strong>{' '}
              {t(`dashboardMenuAIPlatformFaceRace_${person.dominant_race}`)}
            </Typography>
            <Button
              variant='outlined'
              size='small'
              sx={{mt: 1}}
              onClick={() => {
                setSelectedPerson(person);
                setOpen(true);
              }}
            >
              {t('dashboardMenuAIPlatformFaceDetail')}
            </Button>
          </Box>
        );
      })}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth='sm'
        fullWidth
        TransitionProps={{
          onEntered: () => {
            if (imageRef.current) {
              setRenderedDimensions({
                width: imageRef.current.offsetWidth,
                height: imageRef.current.offsetHeight,
              });
            }
          },
        }}
      >
        <DialogTitle>{t('dashboardMenuAIPlatformFaceDetail')}</DialogTitle>
        <DialogContent>
          {selectedPerson && (
            <Box>
              <Typography variant='h6'>
                {t('dashboardMenuAIPlatformFaceBasic')}
              </Typography>
              <List>
                <Card sx={{maxWidth: 400, p: 2, boxShadow: 3}}>
                  <CardContent>
                    <Stack spacing={1.5}>
                      <Typography variant='h6' fontWeight='bold'>
                        {t('dashboardMenuAIPlatformFaceAge')}
                      </Typography>
                      <Typography variant='body1'>
                        {selectedPerson.age}
                      </Typography>
                      <Divider />

                      <Typography variant='h6' fontWeight='bold'>
                        {t('dashboardMenuAIPlatformFaceMainGender')}
                      </Typography>
                      <Typography variant='body1'>
                        {t(
                          `dashboardMenuAIPlatformFaceGender_${selectedPerson.dominant_gender}`,
                        )}
                      </Typography>
                      <Divider />

                      <Typography variant='h6' fontWeight='bold'>
                        {t('dashboardMenuAIPlatformFaceMainEmotion')}
                      </Typography>
                      <Typography variant='body1'>
                        {t(
                          `dashboardMenuAIPlatformFaceEmotion_${selectedPerson.dominant_emotion}`,
                        )}
                      </Typography>
                      <Divider />

                      <Typography variant='h6' fontWeight='bold'>
                        {t('dashboardMenuAIPlatformFaceMainRace')}
                      </Typography>
                      <Typography variant='body1'>
                        {t(
                          `dashboardMenuAIPlatformFaceRace_${selectedPerson.dominant_race}`,
                        )}
                      </Typography>
                      <Divider />

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <Typography
                          variant='h6'
                          fontWeight='bold'
                          sx={{marginRight: 1}}
                        >
                          {t('dashboardMenuAIPlatformFaceImg')}
                        </Typography>
                        {imageUrl ? (
                          <div
                            style={{
                              position: 'relative',
                              display: 'inline-block',
                            }}
                          >
                            <Avatar
                              ref={imageRef}
                              src={imageUrl}
                              alt='Person Image'
                              sx={{
                                width: '300px', // 可依需求調整顯示尺寸
                                height: '300px',
                                borderRadius: 2,
                                marginBottom: 2,
                              }}
                            />
                            <Box sx={{position: 'absolute', ...regionStyle}} />
                            <Tooltip
                              title={t('dashboardMenuAIPlatformFaceCopyImgUrl')}
                            >
                              <IconButton
                                onClick={() => handleCopyLink(imageUrl)}
                              >
                                <CopyAllIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : (
                          <Typography variant='body1' color='textSecondary'>
                            {t('No Image Available')}
                          </Typography>
                        )}
                      </div>
                    </Stack>
                  </CardContent>
                </Card>
              </List>

              <Typography variant='h6' sx={{mt: 2}}>
                {t('dashboardMenuAIPlatformFaceEmotionObj')}
              </Typography>
              <PieChart
                series={[
                  {
                    data: selectedPerson?.emotion
                      ? Object.entries(selectedPerson.emotion).map(
                          ([key, value]) => ({
                            id: key,
                            value: value,
                            label: t(
                              `dashboardMenuAIPlatformFaceEmotion_${key}`,
                            ),
                          }),
                        )
                      : [],
                    arcLabel: item => `${item.label} ${item.value.toFixed(1)}%`,
                    arcLabelMinAngle: 50,
                    arcLabelRadius: '60%',
                    highlightScope: {fade: 'global', highlight: 'item'},
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: 'gray',
                    },
                  },
                ]}
                {...pieParams}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                    fill: 'white',
                  },
                }}
              />

              <Typography variant='h6' sx={{mt: 2}}>
                {t('dashboardMenuAIPlatformFaceGenderObj')}
              </Typography>
              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 'Man',
                        value: selectedPerson?.gender?.Man,
                        label: t('dashboardMenuAIPlatformFaceGender_Man'),
                      },
                      {
                        id: 'Woman',
                        value: selectedPerson?.gender?.Woman,
                        label: t('dashboardMenuAIPlatformFaceGender_Woman'),
                      },
                    ],
                    arcLabel: item => `${item.label} ${item.value.toFixed(1)}%`,
                    arcLabelMinAngle: 50,
                    arcLabelRadius: '60%',
                    highlightScope: {fade: 'global', highlight: 'item'},
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: 'gray',
                    },
                  },
                ]}
                {...pieParams}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                    fill: 'white',
                  },
                }}
              />

              <Typography variant='h6' sx={{mt: 2}}>
                {t('dashboardMenuAIPlatformFaceRaceObj')}
              </Typography>
              <PieChart
                series={[
                  {
                    data: selectedPerson?.race
                      ? Object.entries(selectedPerson.race).map(
                          ([key, value]) => ({
                            id: key,
                            value: value,
                            label: t(`dashboardMenuAIPlatformFaceRace_${key}`),
                          }),
                        )
                      : [],
                    arcLabel: item => `${item.label} ${item.value.toFixed(1)}%`,
                    arcLabelMinAngle: 50,
                    arcLabelRadius: '60%',
                    highlightScope: {fade: 'global', highlight: 'item'},
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: 'gray',
                    },
                  },
                ]}
                {...pieParams}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                    fill: 'white',
                  },
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
