import React, {ReactElement, useEffect, useState} from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
  Button,
  TextField,
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  IconButton,
  Switch,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../../components/Dashboard';
import {Select, MenuItem} from '@mui/material';
import {
  getUserMe,
  patchUserMeChangePassword,
  updateUserMe,
} from '../../../api/user';
import {HttpStatusCode} from 'axios';
import {UserDto} from '../../../api/dto/user';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {TFunction} from 'i18next';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

interface UserInfoRowProps {
  label: string;
  value: string | number | boolean;
  isEditing: boolean;
  onChange?: (field: string, value: string | boolean) => void; // 修改為支持布林值
  field: string;
  onEditClick?: () => void;
  editable?: boolean;
  t: TFunction<'translation', undefined>;
}

function UserInfoRow({
  label,
  value,
  isEditing,
  onChange,
  field,
  onEditClick,
  t,
  editable = true,
}: UserInfoRowProps) {
  const isBooleanField = ['isEmailVerify', 'isSuspension'].includes(field);
  const isGenderField = field === 'gender'; // 判斷是否是 gender 欄位

  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 3}}>
      <Box sx={{flexGrow: 1}}>
        <Typography variant='subtitle2' color='text.secondary'>
          {label}
        </Typography>
        {isEditing ? (
          isBooleanField ? (
            <Switch
              checked={Boolean(value)}
              onChange={e => onChange?.(field, e.target.checked)}
              color='primary'
              sx={{mt: 1}}
            />
          ) : isGenderField ? (
            <Select
              fullWidth
              value={value || 'NONE'}
              onChange={(e: any) => onChange?.(field, e.target.value)}
            >
              <MenuItem value='MALE'>{t(`userGenderMALE`)}</MenuItem>
              <MenuItem value='FEMALE'>{t(`userGenderFEMALE`)}</MenuItem>
              <MenuItem value='NONE'>{t(`userGenderNONE`)}</MenuItem>
            </Select>
          ) : (
            <TextField
              fullWidth
              variant='outlined'
              value={value}
              onChange={e => onChange?.(field, e.target.value)}
            />
          )
        ) : (
          <Typography
            variant='body1'
            color='text.primary'
            sx={{fontWeight: 'medium'}}
          >
            {isGenderField
              ? value === 'MALE'
                ? t(`userGenderMALE`)
                : value === 'FEMALE'
                ? t(`userGenderFEMALE`)
                : t(`userGenderNONE`)
              : typeof value === 'boolean'
              ? value
                ? 'Yes'
                : 'No'
              : value || 'N/A'}
          </Typography>
        )}
      </Box>
      {!isEditing && editable && onEditClick && (
        <IconButton onClick={onEditClick} aria-label='edit'>
          <EditIcon />
        </IconButton>
      )}
      <Divider sx={{mt: 1}} />
    </Box>
  );
}

function AccountContent(): ReactElement {
  const {t} = useTranslation();
  const [userInfo, setUserInfo] = useState<UserDto | null>(null);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [editedInfo, setEditedInfo] = useState<Partial<UserDto>>({});
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });

  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleEditChange = (field: string, value: string | boolean) => {
    setEditedInfo(prev => ({...prev, [field]: value}));
  };

  const handleSave = async () => {
    try {
      const response = await updateUserMe(editedInfo);
      if (response && response.status === HttpStatusCode.Ok) {
        const userResponse = await getUserMe();
        if (userResponse) {
          setUserInfo(userResponse.data.data);
        }
        handleClick('success', t('updateSuccess'));
        setEditingField(null);
      } else {
        handleClick('error', JSON.stringify(response?.data));
      }
    } catch (error) {
      handleClick('error', 'Failed to update user information');
    }
  };

  const handleChangePassword = () => {
    if (oldPassword.trim().length < 6 || newPassword.trim().length < 6) {
      handleClick('error', t('dashboardUserMePasswordNeedMoreThan6'));
      return;
    }
    patchUserMeChangePassword({oldPassword, password: newPassword})
      ?.then(d => {
        if (d.status !== HttpStatusCode.Ok) {
          handleClick('error', JSON.stringify(d.data));
        } else {
          handleClick('success', t('dashboardUserMeChangePasswordSuccess'));
          setOldPassword('');
          setNewPassword('');
        }
      })
      .catch(err => {
        handleClick('error', err.toString());
      });
  };

  useEffect(() => {
    getUserMe()?.then(d => {
      if (d.status !== HttpStatusCode.Ok) {
        handleClick('error', JSON.stringify(d.data));
      } else {
        setUserInfo(d.data.data);
      }
    });
  }, []);

  return (
    <Container
      sx={{
        pt: {xs: 4, sm: 8},
        pb: {xs: 6, sm: 12},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 2, sm: 4},
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: {xs: 4, sm: 6},
          maxWidth: 500,
          width: '100%',
          borderRadius: 3,
          backgroundColor: 'background.paper',
        }}
      >
        <Typography
          component='h2'
          variant='h5'
          color='text.primary'
          sx={{mb: 4, textAlign: 'center'}}
        >
          {t('dashboardUserMe')}
        </Typography>

        {userInfo ? (
          <>
            <UserInfoRow
              t={t}
              label={t('dashboardUserMeEmail')}
              value={userInfo.email}
              isEditing={false}
              field='email'
              editable={false}
            />
            <UserInfoRow
              t={t}
              label={t('dashboardUserMePlanName')}
              value={userInfo?.group?.groupPlan?.plan?.name || ''}
              isEditing={false}
              field='planName'
              editable={false}
            />
            {[
              'username',
              'phoneNumber',
              'gender',
              'isEmailVerify',
              'isSuspension',
            ].map(field => (
              <UserInfoRow
                t={t}
                key={field}
                label={t(
                  `dashboardUserMe${
                    field.charAt(0).toUpperCase() + field.slice(1)
                  }`,
                )}
                value={
                  editedInfo[field as keyof UserDto] ??
                  userInfo[field as keyof UserDto]
                }
                isEditing={editingField === field}
                onChange={handleEditChange}
                field={field}
                onEditClick={
                  field === 'isSuspension' || field === 'isEmailVerify'
                    ? () => {
                        setEditingField(field);
                        setEditedInfo({
                          [field]: userInfo[field as keyof UserDto],
                        });
                      }
                    : () => {
                        setEditingField(field);
                        setEditedInfo({
                          [field]: userInfo[field as keyof UserDto],
                        });
                      }
                }
                editable={field !== 'isSuspension'} // 布林值設為不可編輯
              />
            ))}

            {editingField && (
              <Box
                sx={{mt: 4, display: 'flex', justifyContent: 'center', gap: 2}}
              >
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSave}
                >
                  {t('saveBtn')}
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => setEditingField(null)}
                >
                  {t('cancelBtn')}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Typography variant='body1' color='text.secondary' align='center'>
            Loading...
          </Typography>
        )}

        <Box sx={{mt: 4, width: '100%', maxWidth: 600}}>
          <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
            <Divider sx={{flex: 1}} />
            <Typography variant='h6' sx={{mx: 2, whiteSpace: 'nowrap'}}>
              {t('dashboardUserMeChangePassword')}
            </Typography>
            <Divider sx={{flex: 1}} />
          </Box>
          <Box
            component='form'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              type='password'
              label={t('dashboardUserMeOldPassword')}
              variant='outlined'
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
            />
            <TextField
              type='password'
              label={t('dashboardUserMeNewPassword')}
              variant='outlined'
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            <Button variant='contained' onClick={handleChangePassword}>
              {t('submitBtn')}
            </Button>
          </Box>
        </Box>

        <Snackbar
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
          open={open}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant='filled'
            sx={{width: '100%'}}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default function DashboardUserMe() {
  return <Dashboard element={<AccountContent />} />;
}
