import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
} from '../../components/MaterialTable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import {
  createUserMessage,
  updateUserMessage,
  getUserMessageList,
  deleteUserMessage,
} from '../../api/userAIMessage';
import {UserInfoCell} from '../../components/User/TabelCell';
import EditTableJsonColumn from '../../components/EditTable/EditTableJsonColumn';
import {StatusColumn} from '../../components/EditTable/ViewTableStatusColumn';
import {MRT_Cell} from 'material-react-table';
import {UserComponent} from '../../components/EditTable/EditUserColumn';

dayjs.extend(utc);

function validate(data: any) {
  return {};
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
  autoHideDuration: number;
}
function UserMessageContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
    autoHideDuration: ALERT_HIDE_DURATION,
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleClick = (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => {
    setState({
      ...state,
      type,
      message,
      open: true,
      autoHideDuration: autoHideDuration || ALERT_HIDE_DURATION,
    });
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        show: false,
        size: 60,
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                  {idValue}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'userId',
        header: t('userHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.userId,
          helperText: validationErrors?.userId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              userId: undefined,
            }),
        },
        Edit: UserComponent,
        Cell: ({row}) => <UserInfoCell t={t} user={row.original.user} />,
      },
      {
        accessorKey: 'scene',
        header: t('sceneHeader'),
        enableSorting: false,
        show: true,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.scene,
          helperText: validationErrors?.scene,
        },
      },
      {
        accessorKey: 'model',
        header: t('modelHeader'),
        show: false,
        editVariant: 'select',
        editSelectOptions: [{label: 'deepseek-r1:8b', value: 'deepseek-r1:8b'}],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.model,
          helperText: validationErrors?.model,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              model: undefined,
            }),
        },
      },
      {
        accessorKey: 'status',
        header: t('statusHeader'),
        editVariant: 'select',
        editSelectOptions: [
          {
            label: t('statusFinish'),
            value: 'Finish',
          },
          {label: t('statusFail'), value: 'Fail'},
          {
            label: t('statusPending'),
            value: 'Pending',
          },
        ],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              status: undefined,
            }),
        },
        Cell: ({cell}) => <StatusColumn t={t} cell={cell} />,
        accessorFn: row => {
          return row.status;
        },
      },
      {
        accessorKey: 'messages',
        header: t('messageHeader'),
        show: false,
        enableEditing: false,
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.messages,
          helperText: validationErrors?.messages,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              messages: undefined,
            }),
        },
        Edit: ({cell, row, column}) => (
          <EditTableJsonColumn t={t} cell={cell} row={row} column={column} />
        ),
      },
      {
        accessorKey: 'responseMessage',
        header: t('responseMessageHeader'),
        show: false,
        enableEditing: false,
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.responseMessage,
          helperText: validationErrors?.responseMessage,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              responseMessage: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuUserMessage')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTableUserMessageDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <TWithProviders
        props={{
          createApi: createUserMessage,
          updateApi: updateUserMessage,
          getListApi: getUserMessageList,
          deleteApi: deleteUserMessage,
        }}
        columns={columns}
        validate={validate}
        handleClick={handleClick}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardUserMessage() {
  const {t} = useTranslation();
  return <Dashboard element={<UserMessageContent />}></Dashboard>;
}
