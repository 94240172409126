import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../../components/Dashboard';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
} from '../../../components/MaterialTable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  IconButton,
  Snackbar,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {CreatedAtColumn} from '../../../components/Date/CreatedAtColumn';
import {usePlatformUsage} from '../../../context/platformUsage';
import {getPlatformCustomMenuById} from '../../../api/platformCustomMenu';
import {MRT_Cell} from 'material-react-table';
import {usePlatform} from '../../../context/platform';
import {useParams} from 'react-router';
import {
  PlatformCustomMenuDto,
  PostPlatformCustomMenuDto,
} from '../../../api/dto/platformCustomMenu';
import {
  createPlatformCustomFieldValue,
  deleteManyPlatformCustomFieldValue,
  getPlatformCustomFieldValueList,
  updatePlatformCustomFieldValue,
} from '../../../api/platformCustomFieldValue';
import {State} from '../../../utils';
import {PlatformCustomFieldBaseDto} from '../../../api/dto/platformCustomField';
import {PatchPlatformCustomFieldValueDto} from '../../../api/dto/platformCustomFieldValue';
dayjs.extend(utc);

function validate(data: any, t: any) {
  return {};
}

function PlatformCustomMenuContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage, setPlatformUsage} = usePlatformUsage();
  const {platform} = usePlatform();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;
  const {menuId} = useParams<{menuId: string}>();
  const [menu, setMenu] = useState<PlatformCustomMenuDto>();

  useEffect(() => {
    if (menuId) {
      getPlatformCustomMenuById(menuId)
        .then(data => {
          setMenu(data.data.data);
          localStorage.setItem('platformCustomMenuId', menuId);
        })
        .catch(err => {
          handleClick('error', JSON.stringify(err));
        });
    }
  }, [menuId]);

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const DynamicCellRenderer = ({
    data,
    fieldName,
  }: {
    data: any;
    fieldName: string;
  }) => {
    return <span>{data?.[fieldName] ?? ''}</span>;
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(() => {
    const baseColumns = [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        show: false,
        size: 60,
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                  {idValue}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}: any) => <CreatedAtColumn cell={cell} />,
      },
    ];

    const dynamicColumns: MRT_ColumnDef_Add_Show<any>[] =
      menu?.fields?.map((field: PlatformCustomFieldBaseDto) => {
        return {
          id: field.name,
          // 利用 accessorFn 直接從 row.data 取出欄位值
          accessorFn: (row: any) => row.data?.[field.name],
          header: field.name,
          // 自訂 Cell 將整個 data 傳入渲染元件
          Cell: ({cell}: {cell: MRT_Cell<any>}) => (
            <DynamicCellRenderer
              data={cell.row.original.data}
              fieldName={field.name}
            />
          ),
          muiTableBodyCellEditTextFieldProps: ({cell}: any) => {
            const value = cell.getValue();
            let error = false;
            let helperText = '';

            if (field.isRequired && !value) {
              error = true;
              helperText = t('fieldRequired');
            }

            if (field.validationRule) {
              const regex = new RegExp(field.validationRule);
              if (value && !regex.test(value)) {
                error = true;
                helperText = t('invalidFormat'); // 請確保 i18n 文案已定義
              }
            }

            return {
              required: field.isRequired || false,
              error,
              helperText,
            };
          },
        };
      }) || [];

    return [...baseColumns, ...dynamicColumns];
  }, [t, validationErrors, menu]);

  return (
    <Container
      id='platformCustomMenus'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box display='flex' alignItems='center' gap={2}>
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {menu?.name}
        </Typography>
      </Box>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.platformCustomFieldValueCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj
                    ?.maxLimitPlatformCustomFieldValueCount || 0}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper',
              border: '1px solid #ccc',
            }}
          />
        </Box>
      </Tooltip>

      <TWithProviders
        props={{
          createApi: async (data: PostPlatformCustomMenuDto) => {
            delete data.id;
            delete data.createdAt;
            const result = await createPlatformCustomFieldValue({data});
            return result;
          },
          updateApi: async (
            id: string,
            data: PatchPlatformCustomFieldValueDto,
          ) => {
            delete data.createdAt;
            const result = await updatePlatformCustomFieldValue(id, {
              data: data,
            });
            return result;
          },
          getListApi: getPlatformCustomFieldValueList,
          deleteBulkApi: deleteManyPlatformCustomFieldValue,
        }}
        columns={columns}
        validate={(data: any) => validate(data, t)}
        handleClick={handleClick}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>

      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlatformCustomMenu() {
  return (
    <>
      <Dashboard element={<PlatformCustomMenuContent />}></Dashboard>
    </>
  );
}
