import callApi from './index';
import {
  GetPlatformCustomMenuListDto,
  PatchPlatformCustomMenuDto,
  PlatformCustomMenuDto,
  PostPlatformCustomMenuDto,
} from './dto/platformCustomMenu';
import {AxiosResponse} from 'axios';

export const createPlatformCustomMenu = (data: PostPlatformCustomMenuDto) => {
  return callApi('post', `/platform/custom/menu`, data) as Promise<
    AxiosResponse<any, any>
  >;
};

export const getPlatformCustomMenuList = (
  data: GetPlatformCustomMenuListDto,
) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/custom/menu/list`, options);
};

export const getPlatformCustomMenuById = (
  id: string,
): Promise<AxiosResponse<{data: PlatformCustomMenuDto}, any>> => {
  return callApi('get', `/platform/custom/menu/${id}`) as Promise<
    AxiosResponse<{data: PlatformCustomMenuDto}, any>
  >;
};

export const updatePlatformCustomMenu = (
  id: string,
  data: PatchPlatformCustomMenuDto,
) => {
  return callApi('patch', `/platform/custom/menu/${id}`, data) as Promise<
    AxiosResponse<any, any>
  >;
};

export const deletePlatformCustomMenu = (id: string) => {
  return callApi('delete', `/platform/custom/menu/${id}`) as Promise<
    AxiosResponse<any, any>
  >;
};

export const deleteManyPlatformCustomMenu = (ids: string[]) => {
  return callApi('delete', `/platform/custom/menu/bulk`, {ids});
};
