import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Grid,
  Tooltip,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AlertColor,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CodeIcon from '@mui/icons-material/Code';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TFunction} from 'i18next';
import {usePlatformUsage} from '../../context/platformUsage';
import {getPermissionList} from '../../api/permission';
import {PermissionDto} from '../../api/dto/permission';
import {
  getPlatformCustomMenuById,
  createPlatformCustomMenu,
  updatePlatformCustomMenu,
} from '../../api/platformCustomMenu';
import {
  PlatformCustomMenuDto,
  PostPlatformCustomMenuDto,
} from '../../api/dto/platformCustomMenu';
import {ALERT_HIDE_DURATION} from '../../constant';
import {HttpStatusCode} from 'axios';
import {usePlatformCustomMenu} from '../../context/platformCustomMenu';
import {
  PlatformCustomFieldBaseDto,
  PlatformCustomFieldDto,
} from '../../api/dto/platformCustomField';

interface AddCustomMenuProps {
  t: TFunction;
  menuData?: PlatformCustomMenuDto;
  handleClick: (type: AlertColor, message: string) => void;
  onCloseDialog?: () => void; // 父層關閉 Dialog 的回呼
}

const regexPatterns = {
  email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
  phone: '^09\\d{8}$',
  idCard: '^[A-Z][12]\\d{8}$',
};

const AddCustomMenu: React.FC<AddCustomMenuProps> = ({
  t,
  menuData,
  handleClick,
  onCloseDialog,
}) => {
  const [internalDialogOpen, setInternalDialogOpen] = useState<boolean>(true);
  const dialogOpen = internalDialogOpen;

  const [m, setM] = useState(menuData);

  const [menuName, setMenuName] = useState<string>(menuData?.name || '');
  const [customFields, setCustomFields] = useState<
    PlatformCustomFieldBaseDto[]
  >(menuData?.customFields || []);
  const [selectedScope, setSelectedScope] = useState<string | undefined>(
    menuData?.scope,
  );
  const {platformUsage} = usePlatformUsage();
  const {setPlatformCustomMenus} = usePlatformCustomMenu();
  const [platformPermissionList, setPlatformPermissionList] = useState<
    PermissionDto[]
  >([]);

  useEffect(() => {
    if (menuData && menuData.id) {
      getPlatformCustomMenuById(menuData.id)
        .then(response => {
          const data = response.data;
          setMenuName(data.data.name);
          setSelectedScope(data.data.scope);
          setCustomFields(data.data.fields);
        })
        .catch(err => console.error(err));
    }
  }, [menuData]);

  useEffect(() => {
    if (menuData) {
      setInternalDialogOpen(true);
    }
  }, [menuData]);

  useEffect(() => {
    getPermissionList({limit: 1000})
      .then(data => setPlatformPermissionList(data.data.items))
      .catch(err => console.error(err));
  }, []);

  const scopeArray: string[] = Array.from(
    new Set(
      (platformPermissionList &&
        platformPermissionList.map(permission => permission.scope)) ||
        [],
    ),
  );

  const currentFields = platformUsage?.platformCustomFieldCount || 0;
  const maxFields =
    platformUsage?.ruleObj.maxLimitPlatformCustomFieldCount || 0;

  const handleFieldChange = (
    index: number,
    field: Partial<PlatformCustomFieldDto>,
  ) => {
    const updatedFields = [...customFields];
    updatedFields[index] = {...updatedFields[index], ...field};
    setCustomFields(updatedFields);
  };

  const handleRemoveField = (index: number) => {
    setCustomFields(customFields.filter((_, i) => i !== index));
  };

  const handleCloseDialog = () => {
    if (onCloseDialog) {
      onCloseDialog();
    } else {
      setInternalDialogOpen(false);
      if (!menuData) {
        setMenuName('');
        setSelectedScope('');
        setCustomFields([]);
      }
    }
  };

  const handleAddField = () => {
    if (currentFields < maxFields) {
      setCustomFields([
        ...(customFields || []),
        {
          name: '',
          isRequired: false,
          maxLimit: undefined,
          minLimit: undefined,
          sizeLimit: undefined,
          fileExtLimit: [],
          validationRule: '',
        },
      ]);
    }
  };

  const handleMenuSubmit = () => {
    const newMenu: PostPlatformCustomMenuDto = {
      name: menuName,
      scope: selectedScope,
      fields: customFields,
    };

    if (menuData && menuData.id) {
      newMenu.fields =
        newMenu.fields &&
        newMenu.fields.map((field: PlatformCustomFieldBaseDto) => {
          field.platformCustomMenuId = menuData.id;
          return field;
        });

      updatePlatformCustomMenu(menuData.id, newMenu)
        .then(response => {
          if (response.status !== HttpStatusCode.Ok) {
            handleClick('error', JSON.stringify(response.data));
          } else {
            handleClick('success', t('submitSuccess'));
            setPlatformCustomMenus(undefined);
            handleCloseDialog();
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        })
        .catch(err => console.error(err));
    } else {
      createPlatformCustomMenu(newMenu)
        .then(response => {
          if (response.status !== HttpStatusCode.Ok) {
            handleClick('error', JSON.stringify(response.data));
          } else {
            handleClick('success', t('submitSuccess'));
            setPlatformCustomMenus(undefined);
            handleCloseDialog();
            setTimeout(() => {
              window.location.reload();
            }, ALERT_HIDE_DURATION);
          }
        })
        .catch(err => console.error(err));
    }
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle sx={{textAlign: 'center', fontWeight: 'bold'}}>
          {menuData ? t('editCustomMenu') : t('addCustomMenuAddMenu')}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText sx={{mb: 2, textAlign: 'center'}}>
            {menuData
              ? t('editCustomMenuAddMenuContent')
              : t('addCustomMenuAddMenuContent')}
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <TextField
                label={t('addCustomMenuAddMenuMenuName')}
                fullWidth
                value={menuName}
                onChange={e => setMenuName(e.target.value)}
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel id='permission-select-label'>
                  {t('addCustomMenuAddMenuPermissionScope')}
                </InputLabel>
                <Select
                  labelId='permission-select-label'
                  value={selectedScope}
                  label={t('addCustomMenuAddMenuPermissionScope')}
                  onChange={e => setSelectedScope(e.target.value as string)}
                >
                  {scopeArray.map((scope, index) => (
                    <MenuItem key={index} value={scope}>
                      {scope}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box mt={2}>
            <Typography variant='subtitle2' sx={{mb: 1}}>
              {t('addCustomMenuAddMenuCustomField')}

              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '4px',
                  ml: 1,
                }}
              >
                <Typography variant='body2' color='primary' component='span'>
                  {currentFields}
                </Typography>
                /
                <Typography
                  variant='body2'
                  color='text.secondary'
                  component='span'
                >
                  {maxFields}
                </Typography>
              </Box>
            </Typography>

            {customFields?.map((field, index) => (
              <Accordion key={index} sx={{mt: 1}} defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='subtitle1'>
                    {field.name || t('addCustomMenuAddMenuFieldName')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item xs={8} sm={5}>
                      <TextField
                        label={t('addCustomMenuAddMenuFieldName')}
                        fullWidth
                        value={field.name}
                        onChange={e =>
                          handleFieldChange(index, {name: e.target.value})
                        }
                        variant='outlined'
                        size='small'
                      />
                    </Grid>
                    {/* 必填 */}
                    <Grid item xs={12} sm={6}>
                      <FormGroup row>
                        <Tooltip title={t('dashboardRequired')}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.isRequired}
                                onChange={e =>
                                  handleFieldChange(index, {
                                    isRequired: e.target.checked,
                                  })
                                }
                                size='small'
                              />
                            }
                            label={t('required')}
                          />
                        </Tooltip>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} sm={1}>
                      <IconButton
                        onClick={() => handleRemoveField(index)}
                        size='small'
                      >
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                    {/* 預設 Regex 選項 */}
                    <Grid item xs={12}>
                      <FormGroup row>
                        <Tooltip title={t('addCustomMenuAddMenuEmailTooltip')}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  field.validationRule === regexPatterns.email
                                }
                                onChange={() =>
                                  handleFieldChange(index, {
                                    validationRule: regexPatterns.email,
                                  })
                                }
                                size='small'
                              />
                            }
                            label={
                              <Box display='flex' alignItems='center'>
                                <EmailIcon fontSize='small' sx={{mr: 0.5}} />
                                {t('emailHeader')}
                              </Box>
                            }
                          />
                        </Tooltip>
                        <Tooltip title={t('addCustomMenuAddMenuPhoneTooltip')}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  field.validationRule === regexPatterns.phone
                                }
                                onChange={() =>
                                  handleFieldChange(index, {
                                    validationRule: regexPatterns.phone,
                                  })
                                }
                                size='small'
                              />
                            }
                            label={
                              <Box display='flex' alignItems='center'>
                                <PhoneIcon fontSize='small' sx={{mr: 0.5}} />
                                {t('phoneHeader')}
                              </Box>
                            }
                          />
                        </Tooltip>
                        <Tooltip title={t('addCustomMenuAddMenuIdNoTooltip')}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  field.validationRule === regexPatterns.idCard
                                }
                                onChange={() =>
                                  handleFieldChange(index, {
                                    validationRule: regexPatterns.idCard,
                                  })
                                }
                                size='small'
                              />
                            }
                            label={
                              <Box display='flex' alignItems='center'>
                                <AssignmentIndIcon
                                  fontSize='small'
                                  sx={{mr: 0.5}}
                                />
                                {t('idNoHeader')}
                              </Box>
                            }
                          />
                        </Tooltip>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <FormGroup row>
                        <Tooltip title={t('addCustomMenuAddMenuMin')} arrow>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size='small'
                                checked={
                                  field.minLimit !== undefined &&
                                  !isNaN(field.minLimit)
                                }
                                onChange={e => {
                                  if (e.target.checked) {
                                    handleFieldChange(index, {minLimit: 0});
                                  } else {
                                    handleFieldChange(index, {
                                      minLimit: undefined,
                                    });
                                  }
                                }}
                              />
                            }
                            label={
                              <Box display='flex' alignItems='center'>
                                <ArrowDownwardIcon
                                  fontSize='small'
                                  sx={{mr: 0.2}}
                                />
                                {t('addCustomMenuAddMenuMinLabel')}
                              </Box>
                            }
                          />
                        </Tooltip>
                        {field.minLimit !== undefined &&
                          !isNaN(field.minLimit) && (
                            <TextField
                              label={t('addCustomMenuAddMenuMinLabel')}
                              type='number'
                              value={field.minLimit}
                              onChange={e =>
                                handleFieldChange(index, {
                                  minLimit: Number(e.target.value),
                                })
                              }
                              variant='outlined'
                              size='small'
                              sx={{width: 100, ml: 1, mr: 4, fontSize: '1rem'}}
                              inputProps={{min: 0, max: 500}}
                            />
                          )}
                        <Tooltip title={t('addCustomMenuAddMenuMax')} arrow>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size='small'
                                checked={
                                  field.maxLimit !== undefined &&
                                  !isNaN(field.maxLimit)
                                }
                                onChange={e => {
                                  if (e.target.checked) {
                                    handleFieldChange(index, {maxLimit: 500});
                                  } else {
                                    handleFieldChange(index, {
                                      maxLimit: undefined,
                                    });
                                  }
                                }}
                              />
                            }
                            label={
                              <Box display='flex' alignItems='center'>
                                <ArrowUpwardIcon
                                  fontSize='small'
                                  sx={{mr: 0.2}}
                                />
                                {t('addCustomMenuAddMenuMaxLabel')}
                              </Box>
                            }
                          />
                        </Tooltip>
                        {field.maxLimit !== undefined &&
                          !isNaN(field.maxLimit) && (
                            <TextField
                              type='number'
                              label={t('addCustomMenuAddMenuMaxLabel')}
                              value={field.maxLimit}
                              onChange={e => {
                                handleFieldChange(index, {
                                  maxLimit: Number(e.target.value),
                                });
                              }}
                              variant='outlined'
                              size='small'
                              sx={{width: 100, ml: 1, fontSize: '1rem'}}
                              inputProps={{min: 0, max: 500}}
                            />
                          )}
                      </FormGroup>
                    </Grid>
                    {/* 自訂 Regex 設定：當 validationRule 既非空也非 preset 時，視為自訂 */}
                    <Grid item xs={12}>
                      <Tooltip
                        title={t('addCustomMenuAddMenuRegexTooltip')}
                        arrow
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              size='small'
                              checked={
                                // field.validationRule !== regexPatterns.email &&
                                // field.validationRule !== regexPatterns.phone &&
                                // field.validationRule !== regexPatterns.idCard &&
                                field.validationRule !== ''
                              }
                              onChange={e => {
                                if (e.target.checked) {
                                  // 啟用自訂 regex，預設清空供輸入
                                  handleFieldChange(index, {
                                    validationRule: '',
                                  });
                                } else {
                                  // 取消自訂 regex，清空值
                                  handleFieldChange(index, {
                                    validationRule: '',
                                  });
                                }
                              }}
                            />
                          }
                          label={
                            <Box display='flex' alignItems='center'>
                              <CodeIcon fontSize='small' sx={{mr: 0.5}} />
                              {t('addCustomMenuAddMenuRegexBox')}
                            </Box>
                          }
                        />
                      </Tooltip>
                    </Grid>
                    {
                      <Grid item xs={12}>
                        <TextField
                          label={t('addCustomMenuAddMenuRegexText')}
                          fullWidth
                          value={field.validationRule || ''}
                          onChange={e =>
                            handleFieldChange(index, {
                              validationRule: e.target.value,
                            })
                          }
                          variant='outlined'
                          size='small'
                          sx={{mt: 0.5}}
                        />
                      </Grid>
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
            {currentFields < maxFields && (
              <Box mt={1} display='flex' justifyContent='center'>
                <Button
                  onClick={handleAddField}
                  variant='contained'
                  color='primary'
                  size='small'
                >
                  {t('addCustomMenuAddMenuAddField')}
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center', pb: 2}}>
          <Button onClick={handleCloseDialog} variant='outlined' size='small'>
            {t('cancelBtn')}
          </Button>
          <Button
            onClick={handleMenuSubmit}
            variant='contained'
            color='primary'
            size='small'
          >
            {t('submitBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCustomMenu;
