import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  useMaterialReactTable,
  MRT_RowData,
  MRT_TableInstance,
  MRT_Localization,
} from 'material-react-table';
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {BaseRO} from '../api/dto';
import {HttpStatusCode} from 'axios';
import {useTranslation} from 'react-i18next';
import NoPermissionAlert from './Alert/NoPermission';
import {Cancel, Cancel as CancelIcon, Close, Save} from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {MRT_Localization_EN} from 'material-react-table/locales/en';
import {MRT_Localization_ZH_HANS} from 'material-react-table/locales/zh-Hans';
import {MRT_Localization_ZH_HANT} from 'material-react-table/locales/zh-Hant';
import {MRT_Localization_JA} from 'material-react-table/locales/ja';

export type MRT_ColumnDef_Add_Show<T extends MRT_RowData> = MRT_ColumnDef<T> & {
  show?: boolean;
};

type MaterialTableType = {
  createApi: Function;
  getListApi: Function;
  updateApi?: Function;
  deleteApi?: Function;
  deleteBulkApi?: Function;
};

const DataElement = <T extends BaseRO>({
  props,
  columns,
  validate,
  onlyEditDeleteByIncludeIds,
  onlyEditDeleteByExcludeIds,
  displayMode = 'modal',
  setValidateErrors,
  renderActionsFunc,
  refreshFunc,
  handleClick,
}: {
  props: MaterialTableType;
  columns: MRT_ColumnDef_Add_Show<T>[];
  validate: Function;
  onlyEditDeleteByIncludeIds?: string[];
  onlyEditDeleteByExcludeIds?: string[];
  displayMode?: 'row' | 'modal' | 'custom' | undefined;
  setValidateErrors: Dispatch<
    SetStateAction<Record<string, string | undefined>>
  >;
  renderActionsFunc?: (
    row: MRT_Row<T>,
    table: MRT_TableInstance<T>,
    openDeleteConfirmModal: (row: MRT_Row<T>) => void,
  ) => any;
  refreshFunc?: (refetch: Function) => void;
  handleClick?: (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => void;
}) => {
  const {t, i18n} = useTranslation();
  const {createApi, updateApi, getListApi, deleteApi, deleteBulkApi} = props;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<MRT_Row<T> | null>(null);
  const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false);
  const [bulkDeleteIds, setBulkDeleteIds] = useState<string[]>([]);

  const {mutateAsync: createFunc, isPending: isCreatingFunc} = useCreate<T>(
    createApi,
    handleClick,
  );

  const {
    data: fetcheds = {data: {data: []}, status: HttpStatusCode.Conflict},
    isError: isLoadingError,
    isFetching,
    isLoading,
    refetch,
  } = useGetList(getListApi, handleClick);
  const {mutateAsync: updateFunc, isPending: isUpdatingFunc} = useUpdate<T>(
    updateApi as any,
    handleClick,
  );
  const {mutateAsync: deleteFunc, isPending: isDeletingFunc} = useDelete<T>(
    deleteApi as any,
    handleClick,
  );

  const [localI18n, setLocalI18n] =
    useState<MRT_Localization>(MRT_Localization_EN);
  useEffect(() => {
    switch (i18n.resolvedLanguage) {
      case 'ms-MY':
      case 'en-US':
        setLocalI18n(MRT_Localization_EN);
        break;
      case 'zh-TW':
        setLocalI18n(MRT_Localization_ZH_HANT);
        break;
      case 'zh-CN':
        setLocalI18n(MRT_Localization_ZH_HANS);
        break;
      case 'ja-JP':
        setLocalI18n(MRT_Localization_JA);
        break;
    }
  }, [i18n.resolvedLanguage]);

  const handleCreate: MRT_TableOptions<T>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validate(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidateErrors(newValidationErrors);
      return;
    }
    setValidateErrors({});
    await createFunc(values as any);
    table.setCreatingRow(null);
  };

  const handleSave: MRT_TableOptions<T>['onEditingRowSave'] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validate(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidateErrors(newValidationErrors);
      return;
    }
    setValidateErrors({});
    await updateFunc(values as any);
    table.setEditingRow(null);
  };

  const handleDeleteConfirm = async () => {
    if (selectedRow) {
      await deleteFunc(selectedRow.original.id);
    }
    setOpenConfirmDialog(false);
    setSelectedRow(null);
  };

  const openDeleteConfirmModal = (row: MRT_Row<T>) => {
    setSelectedRow(row);
    setOpenConfirmDialog(true);
  };

  if (refreshFunc) {
    refreshFunc(refetch);
  }

  const notShowColumnObj = columns.reduce((d, n) => {
    if (n.show === false) {
      return {...d, [n.accessorKey as string]: n.show};
    }

    return d;
  }, {});

  const table = useMaterialReactTable({
    columns,
    data:
      fetcheds.status === HttpStatusCode.Ok
        ? (fetcheds as any)?.data?.items || []
        : [],
    enableRowSelection: !!deleteBulkApi,
    createDisplayMode: displayMode,
    editDisplayMode: displayMode,
    enableEditing: true,
    enableColumnOrdering: true,
    enableHiding: true,
    getRowId: row => row.id,
    muiToolbarAlertBannerProps: isLoadingError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    localization: {
      ...localI18n,
      noRecordsToDisplay: t('materialReactTableNotFound'),
      actions: t('materialReactTableActions'),
      hideAll: t('materialReactTableHideAll'),
      showAll: t('materialReactTableShowAll'),
      resetOrder: t('materialReactTableResetOrder'),
      toggleDensity: t('materialReactTableToggleDensity'),
      showHideColumns: t('materialReactTableShowHideColumns'),
      showHideSearch: t('materialReactTableShowHideSearch'),
      showHideFilters: t('materialReactTableShowHideFilters'),
      toggleFullScreen: t('materialReactTableToggleFullScreen'),
    },
    onCreatingRowCancel: () => setValidateErrors({}),
    onCreatingRowSave: handleCreate,
    onEditingRowCancel: () => setValidateErrors({}),
    onEditingRowSave: handleSave,
    renderCreateRowDialogContent: ({table, internalEditComponents}: any) => {
      return (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#f5f7fa', // 淺灰藍背景
              padding: '12px 24px', // 增加內距
              borderBottom: '1px solid #dfe6e9', // 柔和分隔線
              typography: 'h6', // 統一字體樣式
              fontWeight: 600,
              color: '#34495e', // 深藍灰文字
            }}
          >
            {t('dashboardTableCreateBtn')}
            <IconButton
              onClick={() => table.setCreatingRow(null)}
              sx={{
                color: '#7f8c8d', // 灰色圖標
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  color: '#e74c3c', // 懸停時變紅色
                  backgroundColor: '#fdedec', // 淺紅背景
                },
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider sx={{borderColor: '#dfe6e9'}} /> {/* 統一分隔線顏色 */}
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3, // 加大間距，讓內容更舒適
              p: 3,
              backgroundColor: '#fff', // 純白背景
              borderRadius: '8px', // 內部圓角
              maxWidth: '500px', // 控制寬度，避免過寬
            }}
          >
            {internalEditComponents}
          </DialogContent>
          <Divider sx={{borderColor: '#dfe6e9'}} />
          <DialogActions
            sx={{
              justifyContent: 'space-between',
              p: 2,
              backgroundColor: '#f9fbfc', // 淺背景色增加層次感
            }}
          >
            <Button
              onClick={() => table.setCreatingRow(null)}
              color='error'
              variant='outlined' // 改為 outlined，提升視覺輕盈感
              startIcon={<Cancel />}
              sx={{
                borderRadius: '8px',
                textTransform: 'none', // 取消全大寫，提升可讀性
                padding: '6px 16px',
                fontWeight: 500,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: '#fdedec', // 懸停時淺紅背景
                  borderColor: '#e74c3c',
                },
              }}
            >
              {t('cancelBtn')}
            </Button>
            <Button
              onClick={async () => {
                const creatingRow = table.getState().creatingRow;
                if (!creatingRow) return;

                const values: Record<string, any> = creatingRow
                  .getAllCells()
                  .reduce((acc: any, cell: any) => {
                    acc[cell.column.id] = cell.getValue();
                    return acc;
                  }, {} as Record<string, any>);

                await table.options.onCreatingRowSave?.({
                  exitCreatingMode: () => table.setCreatingRow(null),
                  row: creatingRow,
                  table,
                  values,
                });
              }}
              color='primary'
              variant='contained'
              startIcon={<Save />}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                padding: '6px 16px',
                fontWeight: 500,
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // 輕微陰影
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // 懸停時陰影加深
                  backgroundColor: '#2980b9', // 深藍色懸停效果
                },
              }}
            >
              {t('saveBtn')}
            </Button>
          </DialogActions>
        </>
      );
    },
    renderEditRowDialogContent: ({table, internalEditComponents}: any) => {
      return (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#f5f7fa', // 淺灰藍背景
              padding: '12px 24px', // 增加內距
              borderBottom: '1px solid #dfe6e9', // 柔和分隔線
              typography: 'h6', // 統一字體樣式
              fontWeight: 600,
              color: '#34495e', // 深藍灰文字
            }}
          >
            {t('dashboardTableEditBtn')}
            <IconButton
              onClick={() => table.setEditingRow(null)}
              sx={{
                color: '#7f8c8d', // 灰色圖標
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  color: '#e74c3c', // 懸停時變紅色
                  backgroundColor: '#fdedec', // 淺紅背景
                },
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <Divider sx={{borderColor: '#dfe6e9'}} /> {/* 統一分隔線顏色 */}
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3, // 加大間距，讓內容更舒適
              p: 3,
              backgroundColor: '#fff', // 純白背景
              borderRadius: '8px', // 內部圓角
              maxWidth: '500px', // 控制寬度，避免過寬
            }}
          >
            {internalEditComponents}
          </DialogContent>
          <Divider sx={{borderColor: '#dfe6e9'}} />
          <DialogActions
            sx={{
              justifyContent: 'space-between',
              p: 2,
              backgroundColor: '#f9fbfc', // 淺背景色增加層次感
            }}
          >
            <Button
              onClick={() => table.setEditingRow(null)}
              color='error'
              variant='outlined' // 改為 outlined，提升視覺輕盈感
              startIcon={<Cancel />}
              sx={{
                borderRadius: '8px',
                textTransform: 'none', // 取消全大寫，提升可讀性
                padding: '6px 16px',
                fontWeight: 500,
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: '#fdedec', // 懸停時淺紅背景
                  borderColor: '#e74c3c',
                },
              }}
            >
              {t('cancelBtn')}
            </Button>
            <Button
              onClick={async () => {
                const editingRow = table.getState().editingRow;
                if (!editingRow) return;
                const values: Record<string, any> = editingRow
                  .getAllCells()
                  .reduce((acc: any, cell: any) => {
                    acc[cell.column.id] = cell.getValue();
                    return acc;
                  }, {} as Record<string, any>);

                await table.options.onEditingRowSave?.({
                  exitEditingMode: () => table.setEditingRow(null),
                  row: editingRow,
                  table,
                  values,
                });
              }}
              color='primary'
              variant='contained'
              startIcon={<Save />}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                padding: '6px 16px',
                fontWeight: 500,
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // 輕微陰影
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // 懸停時陰影加深
                  backgroundColor: '#2980b9', // 深藍色懸停效果
                },
              }}
            >
              {t('saveBtn')}
            </Button>
          </DialogActions>
        </>
      );
    },
    renderRowActions: ({row, table}) => {
      const isInInclude =
        onlyEditDeleteByIncludeIds &&
        onlyEditDeleteByIncludeIds.includes(row.id);
      const isInExclude =
        onlyEditDeleteByExcludeIds &&
        onlyEditDeleteByExcludeIds.includes(row.id);
      const isExcludedMode =
        onlyEditDeleteByExcludeIds && onlyEditDeleteByExcludeIds?.length > 0;

      if (renderActionsFunc) {
        return renderActionsFunc(row, table, openDeleteConfirmModal);
      }

      if (!onlyEditDeleteByIncludeIds && !onlyEditDeleteByExcludeIds) {
        return (
          <Box sx={{display: 'flex', gap: '1rem'}}>
            {updateApi && (
              <Tooltip title={t('dashboardTableEditBtn')}>
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {deleteApi && (
              <Tooltip title={t('deleteBtn')}>
                <IconButton
                  color='error'
                  onClick={() => openDeleteConfirmModal(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      }

      if (isInInclude || (isExcludedMode && !isInExclude)) {
        return (
          <Box sx={{display: 'flex', gap: '1rem'}}>
            {updateApi && (
              <Tooltip title='Edit'>
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {deleteApi && (
              <Tooltip title='Delete'>
                <IconButton
                  color='error'
                  onClick={() => openDeleteConfirmModal(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      }
    },
    renderTopToolbarCustomActions: ({table}) => {
      const handleMultiDelete = async () => {
        const selectedRowIds = table
          .getSelectedRowModel()
          .rows.map(row => row.original.id);

        if (selectedRowIds.length === 0) return;

        setBulkDeleteIds(selectedRowIds);
        setOpenBulkDeleteDialog(true);
      };

      return (
        <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
          {deleteBulkApi && (
            <Button
              variant='contained'
              color='error'
              onClick={handleMultiDelete}
              disabled={table.getSelectedRowModel().rows.length === 0}
              startIcon={<DeleteIcon />}
              sx={{
                fontSize: '16px',
                textTransform: 'none',
                borderRadius: '8px',
                padding: '8px 16px',
              }}
            >
              {t('dashboardTableDeleteSelected')}
            </Button>
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              table.setCreatingRow(true);
            }}
            startIcon={<AddCircleIcon />}
            sx={{
              fontSize: '16px',
              textTransform: 'none',
              borderRadius: '8px',
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('dashboardTableCreateBtn')}
          </Button>
        </Box>
      );
    },
    state: {
      isLoading,
      isSaving: isCreatingFunc || isUpdatingFunc || isDeletingFunc,
      showAlertBanner: isLoadingError,
      showProgressBars: isFetching,
    },
    initialState: {
      columnVisibility: {
        ...notShowColumnObj,
      },
    },
  });

  if (!fetcheds.status) {
    return <CircularProgress />;
  }

  if (
    fetcheds.status !== HttpStatusCode.Ok &&
    fetcheds.status !== HttpStatusCode.Conflict
  ) {
    return <NoPermissionAlert t={t} />;
  }

  return (
    <>
      <MaterialReactTable table={table} />

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby='delete-confirm-dialog'
        maxWidth='xs'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '12px', // 統一圓角
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // 柔和陰影
            backgroundColor: '#fff', // 純白背景
          },
        }}
      >
        <DialogContent
          sx={{
            p: 3, // 統一內距
            backgroundColor: '#fff', // 保持一致
          }}
        >
          <Typography
            variant='h6'
            color='#34495e' // 深藍灰文字，與之前一致
            fontWeight={600} // 加粗
            gutterBottom
          >
            {t('dashboardConfirmMessage')}
          </Typography>
        </DialogContent>
        <Divider sx={{borderColor: '#dfe6e9'}} /> {/* 統一分隔線 */}
        <DialogActions
          sx={{
            justifyContent: 'space-between', // 與之前一致
            p: 2, // 統一內距
            backgroundColor: '#f9fbfc', // 淺背景色
          }}
        >
          <Button
            onClick={() => setOpenConfirmDialog(false)}
            variant='outlined'
            color='secondary'
            startIcon={<CancelIcon />}
            sx={{
              borderRadius: '8px', // 統一圓角
              textTransform: 'none', // 無全大寫
              padding: '6px 16px', // 統一內距
              fontWeight: 500, // 中等字重
              borderColor: '#7f8c8d', // 灰色邊框
              color: '#7f8c8d', // 灰色文字
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: '#e74c3c', // 懸停時紅色邊框
                backgroundColor: '#fdedec', // 淺紅背景
                color: '#e74c3c', // 紅色文字
              },
            }}
          >
            {t('dashboardConfirmMessageN')}
          </Button>

          <Button
            onClick={handleDeleteConfirm}
            variant='contained'
            color='error'
            startIcon={<DeleteIcon />}
            sx={{
              borderRadius: '8px', // 統一圓角
              textTransform: 'none', // 無全大寫
              padding: '6px 16px', // 統一內距
              fontWeight: 500, // 中等字重
              backgroundColor: '#e74c3c', // 紅色背景
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // 輕微陰影
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: '#c0392b', // 懸停時深紅色
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // 陰影加深
              },
            }}
          >
            {t('dashboardConfirmMessageY')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openBulkDeleteDialog}
        onClose={() => setOpenBulkDeleteDialog(false)}
        aria-labelledby='bulk-delete-dialog-title'
        maxWidth='xs'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '12px', // 統一圓角
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // 柔和陰影
            backgroundColor: '#fff', // 純白背景
          },
        }}
      >
        <DialogTitle
          id='bulk-delete-dialog-title'
          sx={{
            backgroundColor: '#f5f7fa', // 淺灰藍背景
            padding: '12px 24px', // 統一內距
            borderBottom: '1px solid #dfe6e9', // 柔和分隔線
            typography: 'h6', // 統一字體
            fontWeight: 600, // 加粗
            color: '#34495e', // 深藍灰文字
          }}
        >
          {t('dashboardConfirmMessage')}
        </DialogTitle>
        <Divider sx={{borderColor: '#dfe6e9'}} /> {/* 統一分隔線 */}
        <DialogActions
          sx={{
            justifyContent: 'space-between', // 與之前一致
            p: 2, // 統一內距
            backgroundColor: '#f9fbfc', // 淺背景色
          }}
        >
          <Button
            onClick={() => setOpenBulkDeleteDialog(false)}
            variant='outlined'
            color='secondary'
            sx={{
              borderRadius: '8px', // 統一圓角
              textTransform: 'none', // 無全大寫
              padding: '6px 16px', // 統一內距
              fontWeight: 500, // 中等字重
              borderColor: '#7f8c8d', // 灰色邊框
              color: '#7f8c8d', // 灰色文字
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: '#e74c3c', // 懸停時紅色邊框
                backgroundColor: '#fdedec', // 淺紅背景
                color: '#e74c3c', // 紅色文字
              },
            }}
          >
            {t('cancelBtn')}
          </Button>

          <Button
            onClick={async () => {
              if (!deleteBulkApi) {
                return;
              }

              const deleteResult = await deleteBulkApi(bulkDeleteIds);
              if (deleteResult.status !== HttpStatusCode.Ok) {
                return Promise.reject(
                  deleteResult?.data?.message || JSON.stringify(deleteResult),
                );
              }

              table.resetRowSelection();

              queryClient.setQueryData(['datas'], (prev: any) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  data: {
                    ...prev.data,
                    items: prev?.data?.items?.filter(
                      (data: T) => !bulkDeleteIds.includes(data.id),
                    ),
                  },
                };
              });
              setOpenBulkDeleteDialog(false);
              setBulkDeleteIds([]);
            }}
            variant='contained'
            color='error'
            sx={{
              borderRadius: '8px', // 統一圓角
              textTransform: 'none', // 無全大寫
              padding: '6px 16px', // 統一內距
              fontWeight: 500, // 中等字重
              backgroundColor: '#e74c3c', // 紅色背景
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)', // 輕微陰影
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: '#c0392b', // 懸停時深紅色
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)', // 陰影加深
              },
            }}
          >
            {t('deleteBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function useCreate<T extends BaseRO>(
  createApi: Function,
  handleClick?: (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => void,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: T) => {
      const create = await createApi(data);
      if (create.status !== HttpStatusCode.Ok) {
        handleClick &&
          handleClick('error', JSON.stringify(create.data?.message));
        return Promise.reject(create?.data?.message || JSON.stringify(create));
      }
      return Promise.resolve(create);
    },
    onMutate: (newData: T) => {
      queryClient.setQueryData(['datas'], (prev: any) => {
        return [
          {
            ...newData,
            id: (Math.random() + 1).toString(36).substring(7),
          },
        ] as T[];
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

function useGetList<T extends BaseRO>(
  getListApi: Function,
  handleClick?: (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => void,
) {
  return useQuery<{data: T[]; status: string}>({
    queryKey: ['datas'],
    queryFn: async () => {
      const getList = await getListApi({limit: 1000});
      return getList;
    },
    refetchOnWindowFocus: false,
  });
}

function useUpdate<T extends BaseRO>(
  updateApi: Function,
  handleClick?: (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => void,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: T) => {
      const {id, ...rest} = data;
      const update = await updateApi(id, rest);
      if (update.status !== HttpStatusCode.Ok) {
        handleClick &&
          handleClick('error', JSON.stringify(update.data?.message));
        Promise.reject(update?.data?.message || JSON.stringify(update));
      }
      return Promise.resolve(update);
    },
    onMutate: (newInfo: T) => {
      queryClient.setQueryData(['datas'], (prevList: any) => {
        return prevList?.data?.items?.map((prev: T) =>
          prev.id === newInfo.id ? newInfo : prev,
        );
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

function useDelete<T extends BaseRO>(
  deleteApi: Function,
  handleClick?: (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => void,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const deleteRow = await deleteApi(id);
      if (deleteRow.status !== HttpStatusCode.Ok) {
        handleClick &&
          handleClick('error', JSON.stringify(deleteRow?.data?.message));
        Promise.reject(deleteRow?.data?.message || JSON.stringify(deleteRow));
      }
      return Promise.resolve(deleteRow);
    },
    onMutate: (id: string) => {
      queryClient.setQueryData(['datas'], (prev: any) => {
        return prev?.data?.items?.filter((data: T) => data.id !== id);
      });
    },
    onSettled: () => queryClient.invalidateQueries({queryKey: ['datas']}),
  });
}

const queryClient = new QueryClient();
const TWithProviders = ({
  props,
  columns,
  validate,
  onlyEditDeleteByIncludeIds,
  onlyEditDeleteByExcludeIds,
  displayMode,
  setValidateErrors,
  renderActionsFunc,
  refreshFunc,
  handleClick,
}: {
  props: MaterialTableType;
  columns: MRT_ColumnDef<any>[];
  validate: Function;
  onlyEditDeleteByIncludeIds?: string[];
  onlyEditDeleteByExcludeIds?: string[];
  displayMode?: 'row' | 'modal' | 'custom' | undefined;
  setValidateErrors: Dispatch<
    SetStateAction<Record<string, string | undefined>>
  >;
  renderActionsFunc?: (
    row: MRT_Row<any>,
    table: MRT_TableInstance<any>,
    openDeleteConfirmModal: (row: MRT_Row<any>) => void,
  ) => any;
  refreshFunc?: (refetch: Function) => void;
  handleClick?: (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => void;
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataElement
        onlyEditDeleteByIncludeIds={onlyEditDeleteByIncludeIds}
        onlyEditDeleteByExcludeIds={onlyEditDeleteByExcludeIds}
        props={props}
        columns={columns}
        renderActionsFunc={renderActionsFunc}
        validate={validate}
        displayMode={displayMode}
        refreshFunc={refreshFunc}
        setValidateErrors={setValidateErrors}
        handleClick={handleClick}
      />
    </QueryClientProvider>
  );
};

export default TWithProviders;

export const validateRequired = (value: string) => !!value?.length;
export const validateUrl = (value: string) =>
  !value?.length ||
  value
    .toLowerCase()
    .match(
      /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/,
    );

export const validateMoreThan20 = (value: string) =>
  !value || value.length > 20;

export const validateLengthMoreThanEqual = (value: string, min: number) =>
  !value || value?.length >= min;
export const validateEmail = (email: string) =>
  !!email?.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const validateEmailListRequired = (
  value: {mailAddress: string}[],
): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every(
      item =>
        typeof item.mailAddress === 'string' &&
        emailRegex.test(item.mailAddress),
    )
  );
};

export const validateEmailListOptional = (
  values: {mailAddress: string}[],
): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return Array.isArray(values) && values.length
    ? values.every(
        item =>
          typeof item.mailAddress === 'string' &&
          emailRegex.test(item.mailAddress),
      )
    : true;
};

export const validateLoginType = (value: string) => {
  return (
    {
      email: true,
      google: true,
      linebot: true,
    }[value] ?? false
  );
};
