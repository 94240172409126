import {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import {PlanDto} from '../../api/dto/plan';
import InfoIcon from '@mui/icons-material/Info';

export const PlanInfoCell = ({
  plan,
  t,
  showType = 'link',
}: {
  plan: PlanDto | null;
  t: any;
  showType?: 'icon' | 'link';
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
      {(() => {
        switch (showType) {
          case 'icon':
            return (
              <IconButton onClick={handleOpen}>
                <InfoIcon />
              </IconButton>
            );
          case 'link':
            return (
              <span
                onClick={handleOpen}
                style={{
                  cursor: 'pointer',
                  color: '#007bff',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
              >
                {plan?.name}
              </span>
            );
          default:
            return <></>;
        }
      })()}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle sx={{bgcolor: '#f5f5f5', fontWeight: 'bold'}}>
          {t('dashboardTablePlanModulePlanTitle')}
        </DialogTitle>
        <DialogContent dividers>
          {plan ? (
            <Box
              sx={{
                p: 2,
                typography: 'body1',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography>
                <strong>ID:</strong> {plan.id}
              </Typography>
              <Divider />
              <Typography>
                <strong>{t('dashboardTablePlanModulePlanName')}:</strong>{' '}
                {plan.name}
              </Typography>
              <Divider />
            </Box>
          ) : (
            <Typography color='error' align='center'>
              {t('dashboardTablePlanModulePlanNotFound')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center', bgcolor: '#f5f5f5'}}>
          <Button onClick={handleClose} variant='contained' color='primary'>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
