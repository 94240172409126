import React, {useState} from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
  IconButton,
  Popover,
  Tooltip,
} from '@mui/material';
import {
  CreditScore,
  Payment,
  AccountBalanceWallet,
  VolumeUp,
  Face,
} from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';

const CreditUsagePage = ({onClose, t}: {onClose?: () => void; t: any}) => {
  return (
    <>
      <Container maxWidth='md' sx={{marginTop: 4}}>
        <Card
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            overflow: 'hidden',
            border: '1.5px solid #333', // 添加深色邊框
          }}
        >
          <Box
            sx={{
              background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
              padding: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CreditScore sx={{color: '#fff', fontSize: 40, mr: 1}} />
            <Typography
              variant='h4'
              component='div'
              sx={{color: '#fff', flexGrow: 1}}
            >
              {t('dashboardTableCreditUse')}
            </Typography>
            <IconButton onClick={onClose} size='small' sx={{color: 'white'}}>
              <CloseIcon />
            </IconButton>
          </Box>

          <CardContent sx={{padding: 3}}>
            <Typography variant='body1' color='text.secondary' paragraph>
              {t('dashboardTableCreditDescription1')}
            </Typography>
            <Grid container spacing={4}>
              {/* 學分儲值 */}
              <Grid item xs={12} md={6}>
                <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                  <Payment sx={{color: '#1976d2', fontSize: 30, mr: 1}} />
                  <Typography variant='h6' component='div'>
                    {t('dashboardTableCreditRecharge')}
                  </Typography>
                  <Tooltip title={t('dashboardTableCreditGotoRecharge')}>
                    <IconButton
                      sx={{ml: 1, color: '#9e9e9e', p: 0.5}}
                      onClick={() =>
                        window.open(
                          '/order/pay/02a70f1d-1812-5f58-b3b2-b2657fc99757',
                          '_blank',
                        )
                      }
                    >
                      <OpenInNewIcon sx={{fontSize: 20}} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography variant='body2' color='text.secondary' paragraph>
                  {t('dashboardTableCreditRechargeDescription')}
                </Typography>
              </Grid>
              {/* 學分消費 */}
              <Grid item xs={12} md={6}>
                <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                  <AccountBalanceWallet
                    sx={{color: '#1976d2', fontSize: 30, mr: 1}}
                  />
                  <Typography variant='h6' component='div'>
                    {t('dashboardTableCreditConsumption')}
                  </Typography>
                </Box>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <VolumeUp sx={{color: '#4caf50'}} />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('dashboardTableCreditTTS')}
                      secondary={t('dashboardTableCreditTTSDescription')}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Face sx={{color: '#f44336'}} />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('dashboardTableCreditAIFace')}
                      secondary={t('dashboardTableCreditAIFaceDescription')}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            {/* 使用說明 */}
            <Box sx={{mt: 3}}>
              <Typography
                variant='subtitle1'
                color='text.secondary'
                gutterBottom
              >
                {t('dashboardTableCreditInstructions')}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {t('dashboardTableCreditInstructionsDescription1')}
                <br />
                {t('dashboardTableCreditInstructionsDescription2')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default CreditUsagePage;
