import dayjs from 'dayjs';
import {useEffect, useState} from 'react';

export const DateField = ({row, column, cell, validationErrors}: any) => {
  const date = dayjs(cell.getValue()).isValid()
    ? dayjs(cell.getValue()).format('YYYY-MM-DD')
    : null;

  row._valuesCache[column.id] = date;
  const [initialDate, setInitialDate] = useState<string | null>(date);

  useEffect(() => {
    if (row.original.lastMaintenanceDate) {
      setInitialDate(row.original.lastMaintenanceDate);
    }
  }, []);

  return {
    type: 'date',
    defaultValue: initialDate || '',
    value: row._valuesCache[column.id],
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      row._valuesCache[column.id] = newValue;
    },
    error: !!validationErrors[row.original.id],
    helperText: validationErrors[row.original.id] || '',
    InputLabelProps: {shrink: true},
  };
};
