import React, {createContext, useContext, useEffect, useState} from 'react';
import {UserDto} from '../api/dto/user';
import {getUserMe} from '../api/user';
import {HttpStatusCode} from 'axios';
import {useNavigate} from 'react-router-dom';
import {ZUSER_PLATFORM_ID} from '../constant';
import {PlatformDto} from '../api/dto/platform';

interface UserContextType {
  user?: UserDto;
  setUser: (user?: UserDto) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({children}: {children: React.ReactNode}) => {
  const platform = localStorage.getItem('platform') as unknown as PlatformDto;
  const navigate = useNavigate();
  const [user, setUser] = useState<UserDto | undefined>(() => {
    const cachedUser = localStorage.getItem('user');
    return cachedUser ? JSON.parse(cachedUser) : undefined;
  });

  useEffect(() => {
    if (!user) {
      getUserMe()?.then(d => {
        if (d.status !== HttpStatusCode.Ok) {
          navigate('/sign-in');
          return;
        }

        if (
          platform &&
          platform.isUserEmailVerify &&
          d.data?.data?.isEmailVerify === false
        ) {
          navigate('/user/email/verification?returnUrl=/dashboard');
          return;
        }

        if (
          d.data?.data?.platformId === ZUSER_PLATFORM_ID &&
          d.data?.data?.ownerPlatformCount === 0
        ) {
          navigate('/user/step');
          return;
        }

        setUser(d.data?.data);
        localStorage.setItem('groupId', d?.data?.data?.groupId);
        localStorage.setItem('user', JSON.stringify(d.data?.data));
      });
    }
  }, []);

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
