import {AxiosResponse} from 'axios';
import {
  GetPlatformListDto,
  GetPlatformUserGrowDto,
  GetPlatformVersionCheckRO,
  PatchPlatformDto,
  PlatformDto,
  PlatformUsageDto,
  PostPlatformDto,
  UploadPlatformIconDto,
} from './dto/platform';
import callApi from './index';

export const createPlatform = (data: PostPlatformDto) => {
  return callApi('post', `/platform`, data);
};

export const getPlatformList = (data: GetPlatformListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/list`, options);
};

export const getPlatformById = (
  _?: string,
): Promise<AxiosResponse<{data: PlatformDto}, any>> | undefined => {
  return callApi('get', `/platform`);
};

export const getPlatformByName = (
  name: string,
): Promise<AxiosResponse<{data: PlatformDto}, any>> | undefined => {
  return callApi('get', `/platform/name/${name}`);
};

export const getPlatformUsage = ():
  | Promise<AxiosResponse<{data: PlatformUsageDto}, any>>
  | undefined => {
  return callApi('get', `/platform/usage`);
};

export const getPlatformPublicList = (
  data: GetPlatformListDto,
): Promise<AxiosResponse<{items: PlatformDto[]}, any>> | undefined => {
  return callApi('get', `/platform/public/list`, data);
};

export const getPlatformPublic = ():
  | Promise<AxiosResponse<{data: PlatformDto}, any>>
  | undefined => {
  return callApi('get', `/platform/public`);
};

export const getPlatformUserGrow = (
  data?: GetPlatformUserGrowDto,
): Promise<
  AxiosResponse<
    {
      data: {
        [key: string]: number;
      };
    },
    any
  >
> => {
  return callApi('get', `/platform/user/grow`, data) as Promise<
    AxiosResponse<{data: {[key: string]: number}}, any>
  >;
};

export const getPlatformVersionCheck = (): Promise<
  AxiosResponse<
    {
      data: GetPlatformVersionCheckRO;
    },
    any
  >
> => {
  return callApi('get', `/platform/version/check`) as Promise<
    AxiosResponse<{data: GetPlatformVersionCheckRO}, any>
  >;
};

export const patchPlatformVersionUpdate = (): Promise<
  AxiosResponse<
    {
      data: number;
    },
    any
  >
> => {
  return callApi('patch', `/platform/version/update`) as Promise<
    AxiosResponse<{data: number}, any>
  >;
};

export const updatePlatform = (_: string, data: PatchPlatformDto) => {
  if (data.isActive !== undefined) {
    data.isActive = Boolean(data.isActive);
  }

  return callApi('patch', `/platform`, data);
};

export const uploadPlatformIcon = (data: UploadPlatformIconDto) => {
  return callApi('put', `/platform/icon/upload`, data, {}, true);
};

export const deletePlatform = (_?: string) => {
  return callApi('delete', `/platform`);
};
