import {useEffect, useState} from 'react';
import {getUserList} from '../../api/user';
import {MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material';

export const UserComponent = ({cell, row, column, table, ...props}: any) => {
  const [options, setOptions] = useState<Array<{id: string; email: string}>>(
    [],
  );
  const [selectedValue, setSelectedValue] = useState<string>(
    (row._valuesCache[column.id] as string) || '',
  );

  useEffect(() => {
    getUserList({limit: 1000}).then(res => setOptions(res.data.items));
  }, []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setSelectedValue(newValue);
    row._valuesCache[column.id] = newValue;
  };

  return (
    <div>
      <Typography sx={{color: 'gray', fontSize: '1rem'}}>
        {column.columnDef.header}
      </Typography>
      <Select value={selectedValue} onChange={handleChange} fullWidth>
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.email}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
