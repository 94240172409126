import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import {
  getPlatformById,
  getPlatformVersionCheck,
  patchPlatformVersionUpdate,
} from '../api/platform';
import {getUserById, getUserMe} from '../api/user';
import {TFunction} from 'i18next';
import {UserDto} from '../api/dto/user';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import NoPermissionAlert from './Alert/NoPermission';
import {UserInfoCell} from './User/TabelCell';
import PopoverHelper from './Popover';
import {AlertColor, Theme, Tooltip, useMediaQuery} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {HttpStatusCode} from 'axios';
import {useTheme} from '@mui/material/styles';
import {useModule} from '../context/module';

export default function PlatformPlan({
  t,
  handleClick,
}: {
  t: TFunction<'translation', undefined>;
  handleClick: (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => void;
}) {
  const [userId, setUserId] = useState<string | null>(null);
  const [user, setUser] = useState<UserDto | null>(null);
  const [hasPlatformAccess, setHasPlatformAccess] = useState(true);
  const [loading, setLoading] = useState(true);
  const platformId = localStorage.getItem('platformId') || '';
  const [loginUser, setLoginUser] = useState<UserDto>();
  const [updating, setUpdating] = useState(false);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [updateCompleted, setUpdateCompleted] = useState(false);
  const {setModules} = useModule();
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const anchorOrigin: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'right';
  } = {
    vertical: 'top',
    horizontal: isMobile ? 'left' : 'right',
  };

  const badgeSx = {
    '& .MuiBadge-badge': {
      backgroundColor: '#ffcccc',
      color: '#a00',
      top: '50%',
      left: isMobile ? 0 : 'auto',
      right: isMobile ? 'auto' : 0,
      transform: isMobile ? 'translate(-100%, -50%)' : 'translate(100%, -50%)',
      minWidth: '88px',
      padding: '4px 8px',
      fontSize: '12px',
      lineHeight: 1,
    },
  };

  const handleUpdate = async () => {
    setUpdating(true);
    try {
      const response = await patchPlatformVersionUpdate();
      if (response.status !== HttpStatusCode.Ok) {
        handleClick('error', JSON.stringify(response.data));
        return;
      }
      setUpdateCompleted(true);
      setUpdateAvailable(false);
      setModules(undefined);
      setTimeout(() => {
        setUpdateCompleted(false);
      }, 3000);
    } catch (error) {
      handleClick('error', JSON.stringify(error));
    }
    setUpdating(false);
  };

  useEffect(() => {
    const fetchUserMe = async () => {
      try {
        const userResponse = await getUserMe();
        if (userResponse?.data?.data) {
          setLoginUser(userResponse.data.data);
        }
      } catch (error) {}
    };

    fetchUserMe();
  }, []);

  useEffect(() => {
    const fetchPlatform = async () => {
      try {
        const platformResponse = await getPlatformById();
        if (platformResponse?.data?.data) {
          setUserId(platformResponse.data.data.userId);
        } else {
          setHasPlatformAccess(false);
        }
      } catch (error) {
        setHasPlatformAccess(false);
      }
    };

    fetchPlatform();
  }, [platformId]);

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }
      try {
        const userResponse = await getUserById(userId);
        if (userResponse?.data?.data) {
          setUser(userResponse.data.data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchUserPlan();
  }, [userId]);

  useEffect(() => {
    const checkUpdate = async () => {
      try {
        const response = await getPlatformVersionCheck();
        if (response.status !== HttpStatusCode.Ok) {
          setUpdateAvailable(false);
          return;
        }
        setUpdateAvailable(response?.data?.data?.isNeedUpdate);
      } catch (error) {}
    };
    checkUpdate();
  }, []);

  const renderHeader = () => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Title>{t('dashboardAccountPlatformPlanTitle')}</Title>
        <PopoverHelper
          element={
            <Typography variant='body2'>
              {t('dashboardAccountPlatformPlanPopover')}
            </Typography>
          }
        />
      </Box>
      {updateAvailable && !updateCompleted && (
        <Tooltip title={t('dashboardAccountPlatformPlanUpdateVersion')}>
          <IconButton
            onClick={handleUpdate}
            aria-label='update platform'
            disabled={updating}
            sx={{
              backgroundColor: theme => theme.palette.error.light,
              color: theme => theme.palette.error.contrastText,
              animation: 'blink-animation 1s ease-in-out 2', // 讓它閃爍 2 次
              '@keyframes blink-animation': {
                '0%': {opacity: 1},
                '50%': {opacity: 0.3},
                '100%': {opacity: 1},
              },
            }}
          >
            <AutorenewIcon fontSize='small' sx={{fontSize: 20, opacity: 0.8}} />
          </IconButton>
        </Tooltip>
      )}

      {updateCompleted && (
        <Tooltip title={t('dashboardAccountPlatformPlanUpdateVersionSuccess')}>
          <IconButton
            disabled
            sx={{
              backgroundColor: theme => theme.palette.success.light,
              color: theme => theme.palette.success.contrastText,
              transition:
                'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
            }}
          >
            <CheckCircleIcon
              fontSize='small'
              sx={{
                fontSize: 20,
                color: theme => theme.palette.success.main,
                transform: 'scale(1.2)',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  if (loading) {
    return (
      <>
        {renderHeader()}
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='200px'
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  const getPlanColor = (plan: string) => {
    switch (plan) {
      case 'Basic':
        return {color: 'green', icon: <CheckCircleIcon />};
      case 'Pro':
        return {color: 'blue', icon: <CheckCircleIcon />};
      case 'Premium':
        return {color: 'gold', icon: <CheckCircleIcon />};
      case 'Free':
        return {color: 'gray', icon: <CancelIcon />};
      default:
        return {color: 'black', icon: <CancelIcon />};
    }
  };

  return (
    <React.Fragment>
      {renderHeader()}

      {hasPlatformAccess ? (
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <UserInfoCell t={t} user={user} showType='icon' />
          <Typography component='p' variant='h6'>
            {t('dashboardAccountPlatformPlanOnwer')}: {user?.username || 'N/A'}{' '}
            {user?.id === loginUser?.id ? (
              <Typography
                component='span'
                variant='h6'
                color='primary'
                fontWeight='bold'
              >
                ({t('dashboardCreatorIsLoginUser')})
              </Typography>
            ) : null}
          </Typography>
        </Box>
      ) : (
        <NoPermissionAlert t={t} />
      )}

      {hasPlatformAccess && user?.group?.groupPlan?.plan && (
        <Typography
          component='p'
          variant='h6'
          sx={{
            color: getPlanColor(user?.group?.groupPlan?.plan.name).color,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <IconButton disabled>
            {getPlanColor(user?.group?.groupPlan?.plan.name).icon}
          </IconButton>
          {t('dashboardAccountPlatformPlanPlan')}:{' '}
          {user?.group?.groupPlan?.plan.name || 'No Plan'}
        </Typography>
      )}

      {hasPlatformAccess && user?.group?.groupPlan?.expirationAt && (
        <Typography color='text.secondary' sx={{mt: 2}}>
          <IconButton disabled>
            <TimerOffIcon />
          </IconButton>
          {t('dashboardAccountPlatformPlanExpireDate')}:{' '}
          {new Date(user?.group?.groupPlan?.expirationAt).toLocaleDateString()}
        </Typography>
      )}
    </React.Fragment>
  );
}
