import {AxiosResponse} from 'axios';
import {
  GetPermissionListDto,
  PatchPermissionDto,
  PermissionDto,
  PostPermissionDto,
} from './dto/permission';
import callApi from './index';

export const createPermission = (data: PostPermissionDto) => {
  return callApi('post', `/permission`, data);
};

export const getPermissionList = (
  data: GetPermissionListDto,
): Promise<AxiosResponse<{items: PermissionDto[]}, any>> => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/permission/list`, options) as Promise<
    AxiosResponse<{items: PermissionDto[]}, any>
  >;
};

export const updatePermission = (id: string, data: PatchPermissionDto) => {
  return callApi('patch', `/permission/${id}`, data);
};

export const deletePermission = (id: string) => {
  return callApi('delete', `/permission/${id}`);
};

export const deleteManyPermission = (ids: string[]) => {
  return callApi('delete', `/permission/bulk`, {ids});
};
