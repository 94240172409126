import {AxiosResponse} from 'axios';
import {
  GetRoleListDto,
  PatchRoleDto,
  PatchRolePermissionDto,
  PostRoleDto,
  RoleDto,
} from './dto/role';
import callApi from './index';

export const createRole = (
  data: PostRoleDto,
): Promise<AxiosResponse<{data: RoleDto}, any>> | undefined => {
  return callApi('post', `/role`, data);
};

export const getRoleList = (
  data: GetRoleListDto,
): Promise<AxiosResponse<{items: RoleDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/role/list`, options);
};

export const updateRole = (id: string, data: PatchRoleDto) => {
  return callApi('patch', `/role/${id}`, data);
};

export const updateRolePermissions = (
  id: string,
  data: PatchRolePermissionDto,
) => {
  return callApi('put', `/role/${id}/permissions`, data);
};

export const deleteRole = (id: string) => {
  return callApi('delete', `/role/${id}`);
};

export const deleteManyRole = (ids: string[]) => {
  return callApi('delete', `/role/bulk`, {ids});
};
