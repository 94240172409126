import * as React from 'react';
import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Avatar from '@mui/material/Avatar';
import {DynamicMenu, DynamicMobileMenu} from './listItems';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import {
  SwipeableDrawer,
  Tooltip,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemText,
  ButtonBase,
} from '@mui/material';
import NotificationButton from './User/Notification';
import {PlatformProvider, usePlatform} from '../context/platform';
import {ModuleProvider, useModule} from '../context/module';
import {UserProvider, useUser} from '../context/user';
import CreditRecharge from './Credit/CreditRecharge';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History'; // 新增歷史圖標
import {
  PlatformCreditProvider,
  usePlatformCredit,
} from '../context/platformCredit';
import CreditHistoryDialog from './Credit/CreditHistoryDialog';
import {PlatformUsageProvider} from '../context/platformUsage';
import {cleanUserLocalStorage} from '../utils';
import {Business} from '@mui/icons-material';
import {PlatformCustomMenuProvider} from '../context/platformCustomMenu';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface Account {
  token: string;
  platformId: string;
  username: string;
  platformName: string;
}

// 自訂現代化主題
const modernTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      light: '#e3f2fd',
      dark: '#115293',
    },
    secondary: {
      main: '#f06292',
      light: '#ffcccb',
      dark: '#c2185b',
    },
    background: {
      default: '#f4f6f8',
      paper: '#fff',
    },
    text: {
      primary: '#34495e',
      secondary: '#7f8c8d',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h6: {fontWeight: 600},
    body1: {fontSize: '0.95rem'},
  },
  shape: {borderRadius: 12},
  components: {
    MuiButton: {
      styleOverrides: {
        root: {textTransform: 'none', borderRadius: 8, padding: '6px 16px'},
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'none',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {root: {boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)'}},
    },
  },
});

// 自訂 AppBar
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#2980b9',
  borderRadius: '0 0 12px 12px',
  width: '100%',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
}));

// 自訂 Drawer
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({theme, open}) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: open ? drawerWidth : theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: open ? drawerWidth : theme.spacing(9),
    },
    backgroundColor: '#fff',
    borderRadius: '0 12px 12px 0',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
}));

function _Dashboard({element}: {element: React.ReactElement}) {
  const {t, i18n} = useTranslation();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const localeI18n = localStorage.getItem('i18n');
  const [open, setOpen] = React.useState(
    localStorage.getItem('drawerOpen') === 'true' && !isMobile,
  );
  const [openCredit, setOpenCredit] = React.useState<boolean>(false);
  const {platform} = usePlatform();
  const {modules} = useModule();
  const {user} = useUser();
  const {platformCredit} = usePlatformCredit();
  const [clicked, setClicked] = React.useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleAccountSwitch = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAccount = (accountKey: string) => {
    const tokensStr = localStorage.getItem('platformTokenList');
    if (tokensStr) {
      const platformTokenList = JSON.parse(tokensStr);
      const selectedToken = platformTokenList[accountKey].token;
      const selectedPlatformId = platformTokenList[accountKey].platformId;
      cleanUserLocalStorage();
      if (selectedToken) {
        localStorage.setItem('token', selectedToken);
        navigate('/dashboard');
      }
      if (selectedPlatformId) {
        localStorage.setItem('platformId', selectedPlatformId);
      }
    }
    handleMenuClose();
    window.location.reload();
  };

  const tokensStr = localStorage.getItem('platformTokenList');
  const platformTokenList = tokensStr ? JSON.parse(tokensStr) : {};
  const activeToken = localStorage.getItem('token');

  const availableAccounts: Array<Record<string, Account>> = (
    Object.entries(platformTokenList) as [string, Account][]
  )
    .filter(([, account]) => account.token !== activeToken)
    .map(([userId, account]) => ({[userId]: account}));

  const handleLoginAnotherAccount = () => {
    navigate('/sign-in');
    handleMenuClose();
  };

  const handleClickCreditHistory = () => {
    setOpenCredit(true); // 觸發信用歷史對話框
  };

  const handleClickRecharge = () => {
    setClicked(true);
    setDialogOpen(true);
    setTimeout(() => setClicked(false), 100);
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const toggleDrawer = (forceOpen?: boolean) => {
    const newOpenState = forceOpen !== undefined ? forceOpen : !open;
    setOpen(newOpenState);
    localStorage.setItem('drawerOpen', String(newOpenState));
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('platformTokenList');
    localStorage.removeItem('token');
    cleanUserLocalStorage();
    navigate('/');
  };

  React.useEffect(() => {
    if (localeI18n) i18n.changeLanguage(localeI18n);
  }, [localeI18n, i18n]);

  return (
    <ThemeProvider theme={modernTheme}>
      <Box sx={{display: 'flex', height: '100vh', overflow: 'hidden'}}>
        <CssBaseline />

        {/* AppBar */}
        <AppBar position='fixed' open={open}>
          <Toolbar
            sx={{
              pr: '16px',
              pl: isMobile ? '8px' : '24px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={() => toggleDrawer()}
              sx={{
                mr: isMobile ? 1 : 2,
                '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.1)'},
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              sx={{
                flexGrow: 1,
                fontWeight: 600,
                fontSize: isMobile ? '1rem' : '1.25rem',
              }}
            >
              {t('dashboardTitle')}
            </Typography>

            {platformCredit && (
              <Tooltip title={t('viewCreditHistory', 'View Credit History')}>
                <ButtonBase
                  onClick={handleClickCreditHistory}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    borderRadius: '12px',
                    padding: isMobile ? '4px 8px' : '6px 12px',
                    mr: 1,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.25)',
                      transform: 'scale(1.02)', // 輕微放大效果
                    },
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      color: '#fff',
                      fontWeight: 500,
                      mr: 0.5,
                      fontSize: isMobile ? '0.9rem' : '1rem',
                    }}
                  >
                    {platformCredit?.credits}
                  </Typography>
                  <img
                    src='/credit.png'
                    alt='credit icon'
                    style={{
                      width: isMobile ? '16px' : '20px',
                      height: isMobile ? '16px' : '20px',
                      marginRight: '4px',
                    }}
                  />
                  {/* <HistoryIcon
                    sx={{
                      fontSize: isMobile ? 16 : 18,
                      color: 'rgba(255, 255, 255, 0.8)',
                      ml: 0.5,
                    }}
                  /> */}
                  <IconButton
                    color='inherit'
                    onClick={e => {
                      e.stopPropagation(); // 防止觸發 ButtonBase 的點擊
                      handleClickRecharge();
                    }}
                    sx={{p: 0.5, ml: 0.5}}
                  >
                    <AddIcon sx={{fontSize: isMobile ? 16 : 18}} />
                  </IconButton>
                </ButtonBase>
              </Tooltip>
            )}

            <LanguageSelector i18n={i18n} theme='blue' />
            <NotificationButton t={t} />
            <Tooltip title={t('dashboardAccountSetting')}>
              <IconButton
                onClick={handleAccountSwitch}
                sx={{p: 0, ml: isMobile ? 1 : 2}}
              >
                <Avatar
                  alt={user?.username}
                  src='/default-avatar.png'
                  sx={{
                    width: isMobile ? 32 : 36,
                    height: isMobile ? 32 : 36,
                    border: '2px solid #fff',
                  }}
                />
              </IconButton>
            </Tooltip>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: '12px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  width: isMobile ? '90vw' : 'auto',
                  maxWidth: '300px',
                },
              }}
            >
              {availableAccounts.length > 0 ? (
                availableAccounts.map((accountObj: Record<string, Account>) => {
                  const accountKey = Object.keys(accountObj)[0];
                  const account = accountObj[accountKey];
                  return (
                    <MenuItem
                      key={account.username}
                      onClick={() => handleSelectAccount(accountKey)}
                      sx={{py: 1.5}}
                    >
                      <ListItemText
                        primary={account.username}
                        secondary={
                          <Box
                            sx={{display: 'flex', alignItems: 'center', gap: 1}}
                          >
                            <Business
                              fontSize='small'
                              sx={{color: '#7f8c8d'}}
                            />
                            <Typography variant='body2' color='text.secondary'>
                              {account.platformName}
                            </Typography>
                          </Box>
                        }
                        primaryTypographyProps={{fontWeight: 500}}
                      />
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled>{t('dashboardAccountNoAccount')}</MenuItem>
              )}
              <Divider sx={{borderColor: '#dfe6e9'}} />
              <MenuItem onClick={handleLoginAnotherAccount} sx={{py: 1.5}}>
                {t('dashboardAccountLoginAnother')}
              </MenuItem>
              <MenuItem onClick={handleLogout} sx={{py: 1.5}}>
                {t('dashboardAccountLogoutAll')}
              </MenuItem>
            </Menu>

            <CreditRecharge
              t={t}
              open={dialogOpen}
              onClose={handleDialogClose}
              layout='vertical'
            />
            <CreditHistoryDialog
              t={t}
              open={openCredit}
              onClose={() => setOpenCredit(false)}
            />
          </Toolbar>
        </AppBar>

        {/* 桌面版 Drawer */}
        {!isMobile && (
          <Drawer variant='permanent' anchor='left' open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Link
                href={platform?.homePageUrl ?? '/'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'transform 0.2s ease',
                  '&:hover': {transform: 'scale(1.05)'},
                }}
              >
                <img
                  src={platform?.icon || '/logo-symbol.png'}
                  alt='brand-logo'
                  width={50}
                  height={50}
                  style={{objectFit: 'contain'}}
                />
              </Link>
              <IconButton onClick={() => toggleDrawer()}>
                <ChevronLeftIcon sx={{color: '#34495e'}} />
              </IconButton>
            </Toolbar>
            <Divider sx={{borderColor: '#dfe6e9'}} />
            <List component='nav' sx={{px: 1}}>
              <DynamicMenu
                open={open}
                modules={modules}
                t={t}
                onToggleDrawer={toggleDrawer}
              />
            </List>
          </Drawer>
        )}

        {/* 手機版 SwipeableDrawer */}
        {isMobile && (
          <SwipeableDrawer
            anchor='left'
            open={open}
            onClose={() => toggleDrawer()}
            onOpen={() => toggleDrawer()}
            sx={{
              '& .MuiPaper-root': {
                width: '80vw',
                maxWidth: drawerWidth,
                height: '100%',
                borderRadius: '0 12px 12px 0',
                backgroundColor: '#fff',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2,
                pt: 3,
              }}
            >
              <Link
                href={platform?.homePageUrl ?? '/'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  transition: 'transform 0.2s ease',
                  '&:hover': {transform: 'scale(1.05)'},
                }}
              >
                <img
                  src={platform?.icon || '/logo-symbol.png'}
                  alt='brand-logo'
                  width={40}
                  height={40}
                  style={{objectFit: 'contain'}}
                />
              </Link>
              <IconButton onClick={() => toggleDrawer()}>
                <ChevronLeftIcon sx={{color: '#34495e'}} />
              </IconButton>
            </Toolbar>
            <Divider sx={{borderColor: '#dfe6e9'}} />
            <List
              component='nav'
              sx={{
                px: 2,
                py: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                height: 'calc(100% - 80px)',
              }}
            >
              <DynamicMobileMenu modules={modules} t={t} />
            </List>
          </SwipeableDrawer>
        )}

        {/* 主內容區域 */}
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            backgroundColor: '#f4f6f8',
            minHeight: '100vh',
            padding: isMobile ? 2 : 3,
            marginLeft: !isMobile && open ? `${drawerWidth}px` : 0,
            width: !isMobile && open ? `calc(100% - ${drawerWidth}px)` : '100%',
            transition: 'margin-left 0.3s ease, width 0.3s ease',
            overflowY: 'auto',
          }}
        >
          {element}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard({element}: {element: React.ReactElement}) {
  return (
    <PlatformProvider>
      <UserProvider>
        <PlatformCreditProvider type='ai'>
          <PlatformUsageProvider>
            <ModuleProvider>
              <PlatformCustomMenuProvider>
                <_Dashboard element={element} />
              </PlatformCustomMenuProvider>
            </ModuleProvider>
          </PlatformUsageProvider>
        </PlatformCreditProvider>
      </UserProvider>
    </PlatformProvider>
  );
}
