import callApi from './index';
import {GetPlatformFaceListDto, PostPlatformFaceDto} from './dto/platformFace';

export const createPlatformFace = (data: PostPlatformFaceDto) => {
  return callApi('post', `/platform/face`, data);
};

export const getPlatformFaceList = (data: GetPlatformFaceListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/face/list`, options);
};

export const deletePlatformFace = (id: string) => {
  return callApi('delete', `/platform/face/${id}`);
};

export const deleteManyPlatformFace = (ids: string[]) => {
  return callApi('delete', `/platform/face/bulk`, {ids});
};
