import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Container,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Dashboard from '../../../components/Dashboard';
import {ALERT_HIDE_DURATION} from '../../../constant';
import NoPermissionAlert from '../../../components/Alert/NoPermission';
import {getWebhookList} from '../../../api/webhook';
import {GetWebhookListDto, WebhookDto} from '../../../api/dto/webhook';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import WebhookDetailsModal from '../../../components/Webhook/WebhookDetailModal';

function PlatformWebhook(): ReactElement {
  const {t} = useTranslation();
  const [webhooks, setWebhooks] = useState<WebhookDto[]>([]);
  const [selectedWebhook, setSelectedWebhook] = useState<WebhookDto | null>(
    null,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // 控制是否還有更多資料

  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setPage(prevPage => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!hasMore) return;
      setLoading(true);
      const obj: GetWebhookListDto = {limit: 10, page};
      try {
        const response = await getWebhookList(obj);
        if (response?.data?.items?.length) {
          setWebhooks(prev => [...prev, ...response.data.items]);
        } else {
          setHasMore(false);
        }
      } catch (err) {
        setError(JSON.stringify(err));
        setOpen(true);
      }
      setLoading(false);
    };
    fetchData();
  }, [page]);

  return (
    <Container
      id='webhook'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Typography component='h2' variant='h4' color='text.primary'>
        {t('dashboardPlatformWebhookPageTitle')}
      </Typography>

      {error ? (
        <NoPermissionAlert t={t} />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('methodHeader')}</TableCell>
                <TableCell>{t('statusHeader')}</TableCell>
                <TableCell>{t('endpointHeader')}</TableCell>
                <TableCell>{t('createdAtHeader')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {webhooks.map((webhook, index) => (
                <TableRow
                  key={index}
                  ref={index === webhooks.length - 1 ? lastElementRef : null}
                  onClick={() => {
                    setSelectedWebhook(webhook);
                    setModalOpen(true);
                  }}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {backgroundColor: '#FFF5CC'},
                    '&:active': {backgroundColor: '#FFDD77'},
                    backgroundColor:
                      selectedWebhook === webhook ? '#FFE4B5' : 'inherit',
                  }}
                >
                  <TableCell>{webhook.method}</TableCell>
                  <TableCell>{webhook.responseStatus}</TableCell>
                  <TableCell>{webhook.endpoint}</TableCell>
                  <TableCell>
                    {dayjs(webhook.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {loading && <Typography>Loading...</Typography>}
      {!hasMore && <Typography>{t('noData')}</Typography>}

      <Snackbar
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={() => setOpen(false)}
      >
        <Alert severity='error' variant='filled' sx={{width: '100%'}}>
          {error}
        </Alert>
      </Snackbar>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <WebhookDetailsModal
          t={t}
          selectedWebhook={selectedWebhook}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </Container>
  );
}

export default function DashboardPlatformWebhook() {
  return <Dashboard element={<PlatformWebhook />} />;
}
