import {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
  Container,
  CircularProgress,
  Alert,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {styled} from '@mui/system';
import {getShortenUrlHash} from '../../api/shortenUrl';
import {HttpStatusCode} from 'axios';
import {useTranslation} from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// 自訂抖動動畫的 TextField
const ShakeTextField = styled(TextField)(({theme}) => ({
  '&.shake': {
    animation: 'shake 0.5s',
  },
  '@keyframes shake': {
    '0%': {transform: 'translateX(0)'},
    '25%': {transform: 'translateX(-5px)'},
    '50%': {transform: 'translateX(5px)'},
    '75%': {transform: 'translateX(-5px)'},
    '100%': {transform: 'translateX(0)'},
  },
}));

const ShortenUrlPage = () => {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const {hash} = useParams<{hash: string}>();
  const navigate = useNavigate();
  const [url, setUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [openPasswordDialog, setOpenPasswordDialog] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [shake, setShake] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false); // 新增：控制密碼顯示
  const [errorCount, setErrorCount] = useState<number>(() => {
    const stored = localStorage.getItem(`errorCount_${hash}`);
    return stored ? parseInt(stored, 10) : 0;
  });
  const [lockUntil, setLockUntil] = useState<number | null>(() => {
    const stored = localStorage.getItem(`lockUntil_${hash}`);
    return stored ? parseInt(stored, 10) : null;
  });
  const [remainingTime, setRemainingTime] = useState<number>(0);

  useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  useEffect(() => {
    if (!hash) {
      setError(t('shortenUrlInvalid'));
      setLoading(false);
      return;
    }

    const now = Date.now();
    if (lockUntil && now < lockUntil) {
      setError(t('shortenUrlTooManyAttempts'));
      setLoading(false);
    } else if (lockUntil && now >= lockUntil) {
      localStorage.removeItem(`errorCount_${hash}`);
      localStorage.removeItem(`lockUntil_${hash}`);
      setErrorCount(0);
      setLockUntil(null);
      fetchUrl(hash);
    } else {
      fetchUrl(hash);
    }
  }, [hash, t]);

  useEffect(() => {
    if (!lockUntil) return;

    const updateRemainingTime = () => {
      const now = Date.now();
      const remaining = Math.max(0, Math.floor((lockUntil - now) / 1000));
      setRemainingTime(remaining);

      if (remaining === 0) {
        setLockUntil(null);
        setErrorCount(0);
        localStorage.removeItem(`errorCount_${hash}`);
        localStorage.removeItem(`lockUntil_${hash}`);
        setError(null);
        setOpenPasswordDialog(true);
        fetchUrl(hash || '');
      }
    };

    updateRemainingTime();
    const interval = setInterval(updateRemainingTime, 1000);
    return () => clearInterval(interval);
  }, [lockUntil, hash]);

  const isLocked = () => {
    if (!lockUntil) return false;
    const now = Date.now();
    return now < lockUntil;
  };

  const fetchUrl = async (hash: string, password?: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await getShortenUrlHash(
        hash,
        password ? {password} : {},
      );

      if (response.status === HttpStatusCode.Ok) {
        const redirectUrl = response.data.data.redirectUrl;
        setUrl(redirectUrl);
        setLoading(false);
        window.location.href = redirectUrl;
      } else if (
        response.status === HttpStatusCode.Forbidden &&
        response.data.code === 5700
      ) {
        setOpenPasswordDialog(true);
        setLoading(false);
      } else if (
        response.status === HttpStatusCode.Forbidden &&
        response.data.code === 5701
      ) {
        const newErrorCount = errorCount + 1;
        setErrorCount(newErrorCount);
        localStorage.setItem(`errorCount_${hash}`, newErrorCount.toString());
        setPasswordError(t('shortenUrlInCorrectPassword'));
        setShake(true);
        setTimeout(() => setShake(false), 500);
        setLoading(false);
        setOpenPasswordDialog(true);

        if (newErrorCount >= 3) {
          const lockTime = Date.now() + 5 * 60 * 1000;
          setLockUntil(lockTime);
          localStorage.setItem(`lockUntil_${hash}`, lockTime.toString());
          setOpenPasswordDialog(false);
          setPasswordError(null);
          setError(t('shortenUrlTooManyAttempts'));
        }
      } else {
        setError(response.data.message || t('fetchUrlFailed'));
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      const errorMessage = err.response?.data?.message || t('fetchUrlFailed');
      setError(errorMessage);
    }
  };

  const handlePasswordSubmit = () => {
    if (hash && !isLocked()) {
      fetchUrl(hash, password);
      setPassword('');
    }
  };

  const handleCopy = () => {
    if (url) {
      navigator.clipboard.writeText(url);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <Container
      maxWidth='sm'
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 3,
          p: 4,
          width: '100%',
          textAlign: 'center',
        }}
      >
        {loading ? (
          <Box sx={{display: 'flex', justifyContent: 'center', py: 4}}>
            <CircularProgress size={60} thickness={4} />
          </Box>
        ) : error ? (
          <Alert
            severity='error'
            sx={{borderRadius: 2, boxShadow: 1, py: 2, mb: 2}}
          >
            <Typography variant='h6' fontWeight='bold'>
              {t('errorTitle')}
            </Typography>
            <Typography variant='body1'>
              {error}
              {isLocked() && (
                <>
                  <br />
                  {t('shortenUrlRemainingTime')}:{' '}
                  {Math.floor(remainingTime / 60)}
                  {t('minutes')} {remainingTime % 60}
                  {t('seconds')}
                </>
              )}
            </Typography>
          </Alert>
        ) : url ? (
          <Alert
            severity='success'
            sx={{borderRadius: 2, boxShadow: 1, py: 2, mb: 2}}
          >
            <Typography variant='h5' fontWeight='bold' color='success.main'>
              {t('urlFound')}
            </Typography>
            <Typography
              variant='body1'
              sx={{wordBreak: 'break-all', mt: 1, color: '#1976d2'}}
            >
              {url}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={handleCopy}
              sx={{
                mt: 2,
                borderRadius: 1,
                textTransform: 'none',
                px: 4,
                boxShadow: 2,
              }}
            >
              {t('copyLink')}
            </Button>
          </Alert>
        ) : null}
      </Box>

      <Dialog
        open={openPasswordDialog && !isLocked()}
        disableEscapeKeyDown
        sx={{backdropFilter: 'blur(3px)'}}
        onClose={() => {}}
      >
        <DialogTitle>
          <Typography variant='h6' fontWeight='bold'>
            {t('shortenUrlPasswordRequired')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <ShakeTextField
            autoFocus
            margin='dense'
            label={t('shortenUrlEnterPassword')}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant='outlined'
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && handlePasswordSubmit()}
            error={!!passwordError}
            helperText={
              passwordError ||
              `${t('shortenUrlAttemptsTimes')}: ${3 - errorCount}`
            }
            className={shake ? 'shake' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleTogglePasswordVisibility}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPasswordDialog(false)} color='inherit'>
            {t('cancelBtn')}
          </Button>
          <Button
            onClick={handlePasswordSubmit}
            variant='contained'
            color='primary'
            disabled={!password.trim() || isLocked()}
          >
            {t('submitBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ShortenUrlPage;
