import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; // 權限管理
import EditNoteIcon from '@mui/icons-material/EditNote'; // 編輯權限
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'; // 檢視權限
import BlockIcon from '@mui/icons-material/Block'; // 刪除權限
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'; // 擁有所有權限

export const ActionColumn = ({cell, t}: any) => {
  const action = cell.getValue();

  const getActionDisplay = (action: any) => {
    switch (action) {
      case 'Create':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#FFA500'}}
          >
            <AdminPanelSettingsIcon style={{marginRight: 5}} />
            {t('actionCreate')} {/* 具備新增權限 */}
          </span>
        );
      case 'Edit':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#4CAF50'}}
          >
            <EditNoteIcon style={{marginRight: 5}} />
            {t('actionEdit')} {/* 具備編輯權限 */}
          </span>
        );
      case 'View':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#2196F3'}}
          >
            <RemoveRedEyeIcon style={{marginRight: 5}} />
            {t('actionView')} {/* 具備查看權限 */}
          </span>
        );
      case 'Delete':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#F44336'}}
          >
            <BlockIcon style={{marginRight: 5}} />
            {t('actionDelete')} {/* 具備刪除權限 */}
          </span>
        );
      case '*':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#000000'}}
          >
            <VerifiedUserIcon style={{marginRight: 5}} />
            {t('actionALL')} {/* 擁有所有權限 */}
          </span>
        );
      default:
        return action;
    }
  };

  return getActionDisplay(action);
};
