import React, {ReactElement, useEffect, useState} from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
  Link,
  Alert,
  AlertColor,
  Snackbar,
  SnackbarOrigin,
  IconButton,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import PolicyIcon from '@mui/icons-material/Policy';
import {useTranslation} from 'react-i18next';
import {HttpStatusCode} from 'axios';
import Dashboard from '../../../components/Dashboard';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {getPlatformById, uploadPlatformIcon} from '../../../api/platform';
import {getUserById} from '../../../api/user';
import {UserDto} from '../../../api/dto/user';
import {PlatformDto} from '../../../api/dto/platform';
import NoPermissionAlert from '../../../components/Alert/NoPermission';
import {LanguageObject} from '../../../components/LanguageSelector';
import PopoverHelper from '../../../components/Popover';
import {TFunction} from 'i18next';
import Cropper from 'react-easy-crop';
import {usePlatform} from '../../../context/platform';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

function PolicyInfoRow({label, value}: {label: string; value: string}) {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', mb: 3}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='subtitle2'
          color='text.secondary'
          sx={{fontWeight: 500}}
        >
          {label}
        </Typography>
        {value ? (
          <Link href={value} target='_blank' rel='noopener noreferrer'>
            <IconButton aria-label='policy'>
              <PolicyIcon color='primary' />
            </IconButton>
          </Link>
        ) : (
          <Typography
            variant='body1'
            color='text.primary'
            sx={{fontWeight: 600}}
          >
            N/A
          </Typography>
        )}
      </Box>
      <Divider sx={{mt: 1, opacity: 0.6}} />
    </Box>
  );
}

function PlatformInfoRow({
  label,
  value,
  t,
}: {
  label: string;
  value: string | number | boolean;
  t: TFunction<'translation', undefined>;
}) {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', mb: 2}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='subtitle2'
          color='text.secondary'
          sx={{fontWeight: 500}}
        >
          {label}
          {label === t('dashboardPlatformInfoPlatformLanguage') ? (
            <PopoverHelper
              element={
                <Box sx={{p: 2, maxWidth: 300}}>
                  <Typography variant='body2' color='text.primary'>
                    {t('dashboardPlatformInfoPlatformLanguagePopoverText')}
                  </Typography>
                </Box>
              }
            />
          ) : null}
        </Typography>
        <Typography variant='body1' color='text.primary' sx={{fontWeight: 600}}>
          {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value || 'N/A'}
        </Typography>
      </Box>
      <Divider sx={{mt: 1, opacity: 0.6}} />
    </Box>
  );
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // 避免跨域問題
    image.src = url;
  });

const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: any,
): Promise<Blob> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  if (!ctx) throw new Error('Could not get canvas context');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Canvas is empty'));
      }
    }, 'image/jpeg');
  });
};

function InfoContent(): ReactElement {
  const {t} = useTranslation();
  const [platformData, setPlatformData] = useState<PlatformDto>();
  const [userData, setUserData] = useState<UserDto>();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const [uploading, setUploading] = useState(false);
  const {setPlatform} = usePlatform();

  const [showCropModal, setShowCropModal] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  // 修改後：先讀取檔案並進入裁切模式
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result as string);
      setShowCropModal(true);
    };
    reader.readAsDataURL(file);
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropSave = async () => {
    try {
      if (!imageSrc || !croppedAreaPixels) return;
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const croppedFile = new File([croppedImageBlob], 'croppedImage.jpg', {
        type: 'image/jpeg',
      });
      setShowCropModal(false);
      setImageSrc(null);
      setCrop({x: 0, y: 0});
      setZoom(1);
      // 將裁切後的檔案上傳
      const formData = new FormData();
      formData.append('file', croppedFile);
      setUploading(true);
      try {
        const response = await uploadPlatformIcon({file: croppedFile});
        if (response?.status === HttpStatusCode.Ok) {
          const updatedPlatformData = {
            ...platformData,
            icon: response.data.icon,
          };
          setPlatformData(updatedPlatformData as PlatformDto);
          handleClick(
            'success',
            t('dashboardPlatformInfoUploadingIconSuccess'),
          );
          fetchPlatformAndUser();
          setPlatform(undefined);
        } else {
          handleClick('error', JSON.stringify(response?.data));
        }
      } catch (error) {
        handleClick('error', JSON.stringify(error));
      } finally {
        setUploading(false);
      }
    } catch (error) {
      handleClick('error', t('dashboardPlatformInfoCropImageError'));
    }
  };

  const fetchPlatformAndUser = async () => {
    try {
      const platformResponse = await getPlatformById();
      const platform = platformResponse && platformResponse.data;
      if (
        platformResponse &&
        platformResponse.status === HttpStatusCode.Unauthorized
      ) {
        // handleClick('error', JSON.stringify(platformResponse?.data));
      }
      if (platform?.data?.userId) {
        const userResponse = await getUserById(platform.data.userId);
        setUserData(userResponse?.data.data);
      }
      setPlatformData(platform?.data);
    } catch (error) {
      handleClick(
        'error',
        t('failedToFetchData') || 'Failed to fetch platform data',
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlatformAndUser();
  }, [t, platformData]);

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='50vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      sx={{
        pt: {xs: 4, sm: 8},
        pb: {xs: 6, sm: 12},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 2, sm: 4},
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: {xs: 4, sm: 6},
          maxWidth: 600,
          width: '100%',
          borderRadius: 3,
          backgroundColor: 'background.paper',
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          sx={{mb: 4, textAlign: 'center', fontWeight: 'bold'}}
        >
          {t('dashboardPlatformInfo')}
        </Typography>

        {platformData ? (
          <>
            {platformData.icon && (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                mb={2}
              >
                <img
                  src={platformData.icon}
                  alt={t('platformIcon') || 'Platform Icon'}
                  style={{
                    maxWidth: '60%',
                    height: 'auto',
                    borderRadius: '8px',
                  }}
                />
              </Box>
            )}

            <Button
              variant='contained'
              component='label'
              startIcon={<UploadIcon />}
              disabled={uploading}
              sx={{mb: 2}}
            >
              {uploading
                ? t('dashboardPlatformInfoUploadingIcon')
                : t('dashboardPlatformInfoUploadIcon')}
              <input
                type='file'
                hidden
                accept='image/*'
                onChange={handleFileChange}
              />
            </Button>

            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformName')}
              value={platformData.name}
              t={t}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformUID')}
              value={platformData.userId}
              t={t}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformLanguage')}
              value={LanguageObject[platformData.language]}
              t={t}
            />
            <PolicyInfoRow
              label={t('dashboardPlatformInfoPlatformPolicyUrl')}
              value={platformData?.policyUrl || ''}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlatformHash')}
              value={platformData.hash}
              t={t}
            />
          </>
        ) : (
          <NoPermissionAlert t={t} />
        )}

        {userData?.group?.groupPlan && (
          <>
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlanName')}
              value={userData?.group?.groupPlan?.plan?.name || ''}
              t={t}
            />
            <PlatformInfoRow
              label={t('dashboardPlatformInfoPlanExpired')}
              value={
                isNaN(
                  new Date(
                    userData?.group?.groupPlan?.expirationAt || '',
                  ).getTime(),
                ) || !userData?.group?.groupPlan?.expirationAt
                  ? t('dashboardPlatformInfoPlanForever')
                  : new Date(
                      userData?.group?.groupPlan?.expirationAt,
                    ).toLocaleDateString()
              }
              t={t}
            />
          </>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
          open={open}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant='filled'
            sx={{width: '100%'}}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </Paper>

      <Dialog
        open={showCropModal}
        onClose={() => setShowCropModal(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {t('dashboardPlatformInfoCropImageTitle') || 'Crop Image'}
        </DialogTitle>
        <DialogContent>
          {imageSrc && (
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: 400,
                background: '#333',
              }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCropModal(false)}>
            {t('cancelBtn')}
          </Button>
          <Button
            onClick={handleCropSave}
            variant='contained'
            disabled={uploading}
          >
            {uploading ? <CircularProgress size={20} /> : t('submitBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default function DashboardPlatformInfo() {
  return <Dashboard element={<InfoContent />} />;
}
