import {GetPlanModuleListDto} from './dto/planModule';
import {PostPlatformCreditDto} from './dto/platformCredit';
import callApi from './index';

export const createPlanModule = (data: PostPlatformCreditDto) => {
  return callApi('post', `/plan/module`, data);
};

export const getPlanModuleList = (data: GetPlanModuleListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/plan/module/list`, options);
};

export const getPlanModulePlatformList = (data: GetPlanModuleListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/plan/module/platform/list`, options);
};

export const deletePlanModule = (id: string) => {
  return callApi('delete', `/plan/module/${id}`);
};
