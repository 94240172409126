import React, {useState, useEffect} from 'react';
import {
  Link,
  Menu,
  PaletteMode,
  useTheme,
  useMediaQuery,
  Drawer,
  IconButton,
} from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import {useTranslation} from 'react-i18next';
import LanguageSelector from './LanguageSelector';
import {useNavigate} from 'react-router-dom';

const logoStyle = {
  width: '60px',
  height: 'auto',
  opacity: 0.8,
  cursor: 'pointer',
};

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function AppAppBar() {
  const theme = useTheme();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userAvatar, setUserAvatar] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    if (!sectionElement) {
      navigate(`/`);
    }
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({behavior: 'smooth'});
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleAvatarClick = () => {
    navigate('/dashboard');
  };

  return (
    <div>
      <AppBar
        position='fixed'
        sx={{
          boxShadow: 0,
          bgcolor: 'rgba(0, 48, 96, 0.85)',
          backgroundImage: 'none',
          borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
          mt: 1,
          backdropFilter: 'blur(10px)',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: 'lg',
            margin: '0 auto',
            width: '100%',
          }}
        >
          <Typography
            variant='h6'
            sx={{
              fontWeight: 'bold',
              letterSpacing: 1.5,
              color: '#00E5FF',
              textTransform: 'uppercase',
              fontFamily: 'Orbitron, sans-serif',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Link
              href='/'
              sx={{
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <img
                src={'/logo-symbol.png'}
                style={logoStyle}
                alt='logo of zzztech'
              />
            </Link>
            {!isMobile && 'ZUsers'}
          </Typography>

          {isMobile ? (
            <IconButton onClick={() => setOpen(true)}>
              <MenuIcon sx={{color: 'white'}} />
            </IconButton>
          ) : (
            <Box sx={{display: 'flex', gap: 4}}>
              <Link
                href='/about-us'
                color='inherit'
                underline='none'
                sx={{
                  fontWeight: 'bold',
                  color: 'white',
                  textTransform: 'uppercase',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    color: '#00E5FF',
                    textShadow: '0 0 10px #00E5FF',
                  },
                }}
              >
                {t('aboutUs')}
              </Link>
              <Link
                href='/api-document'
                color='inherit'
                underline='none'
                sx={{
                  fontWeight: 'bold',
                  color: 'white',
                  textTransform: 'uppercase',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': {
                    color: '#00E5FF',
                    textShadow: '0 0 10px #00E5FF',
                  },
                }}
              >
                {t('apidocs')}
              </Link>
              <Box
                onMouseEnter={(e: any) => setAnchorEl(e.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
                sx={{cursor: 'pointer'}}
              >
                <Button
                  variant='outlined'
                  sx={{
                    borderColor: '#00E5FF',
                    color: '#00E5FF',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    letterSpacing: 1,
                    '&:hover': {
                      bgcolor: '#00E5FF',
                      color: '#003060',
                      boxShadow: '0 0 15px #00E5FF',
                    },
                  }}
                >
                  {t('menu')}
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    onMouseLeave: () => setAnchorEl(null), // 滑鼠離開時關閉
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      bgcolor: '#001F3F',
                      color: 'white',
                      borderRadius: 2,
                      border: '1px solid rgba(0, 229, 255, 0.3)',
                      boxShadow: '0 0 15px rgba(0, 229, 255, 0.5)',
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => scrollToSection('features')}
                    sx={{
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#00E5FF',
                        color: '#001F3F',
                      },
                    }}
                  >
                    {t('features')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToSection('highlights')}
                    sx={{
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#00E5FF',
                        color: '#001F3F',
                      },
                    }}
                  >
                    {t('highlights')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToSection('pricing')}
                    sx={{
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#00E5FF',
                        color: '#001F3F',
                      },
                    }}
                  >
                    {t('pricing')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => scrollToSection('faq')}
                    sx={{
                      color: 'white',
                      '&:hover': {
                        bgcolor: '#00E5FF',
                        color: '#001F3F',
                      },
                    }}
                  >
                    {t('faq')}
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          )}

          <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
            <Box sx={{width: 250, p: 2}}>
              <MenuItem onClick={() => scrollToSection('features')}>
                {t('features')}
              </MenuItem>
              <MenuItem onClick={() => scrollToSection('highlights')}>
                {t('highlights')}
              </MenuItem>
              <MenuItem onClick={() => scrollToSection('pricing')}>
                {t('pricing')}
              </MenuItem>
              <MenuItem onClick={() => scrollToSection('faq')}>
                {t('faq')}
              </MenuItem>
            </Box>
          </Drawer>
          <MenuItem sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            {isLoggedIn ? (
              <Avatar
                src={userAvatar || undefined}
                onClick={handleAvatarClick}
                sx={{
                  cursor: 'pointer',
                  width: 40, // Slightly larger for better visibility
                  height: 40,
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: '50%',
                  border: '2px solid transparent', // Initial transparent border
                  transition: 'all 0.3s ease', // Smooth transition for hover effect
                  '&:hover': {
                    borderColor: '#00E5FF', // Glowing neon blue border on hover
                    boxShadow: '0 0 8px #00E5FF', // Subtle glow effect
                    transform: 'scale(1.05)', // Slightly enlarge the avatar on hover
                  },
                }}
              />
            ) : (
              <>
                <Button
                  variant='outlined'
                  href={'/sign-in'}
                  sx={{
                    borderColor: '#00E5FF',
                    color: '#00E5FF',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    letterSpacing: 1,
                    '&:hover': {
                      bgcolor: '#00E5FF',
                      color: '#003060',
                      boxShadow: '0 0 15px #00E5FF',
                    },
                  }}
                >
                  {t('getStarted')}
                </Button>
              </>
            )}
            <LanguageSelector i18n={i18n} theme='blue'></LanguageSelector>{' '}
          </MenuItem>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
