import React, {useState} from 'react';
import {Popover, IconButton, Box} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface PopoverHelperProps {
  element: React.ReactNode;
  vertical?: 'top' | 'bottom';
}

const PopoverHelper: React.FC<PopoverHelperProps> = ({
  element,
  vertical = 'top',
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size='small'
        sx={{
          color: 'grey.600',
          fontSize: '18px',
          verticalAlign: 'middle',
          mb: 1,
        }}
        onClick={handleOpen}
      >
        <HelpOutlineIcon fontSize='inherit' />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: vertical,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: vertical === 'top' ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        sx={{mt: -1}}
      >
        <Box sx={{p: 2, maxWidth: 500}}>{element}</Box>
      </Popover>
    </>
  );
};

export default PopoverHelper;
