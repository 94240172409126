import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  IconButton,
  InputAdornment,
  Snackbar,
  SnackbarOrigin,
  Switch,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import {usePlatformUsage} from '../../context/platformUsage';
import {
  createBulkPlatformDevice,
  createPlatformDevice,
  deleteManyPlatformDevice,
  deletePlatformDevice,
  getPlatformDeviceList,
  updatePlatformDevice,
} from '../../api/platformDevices';
import EditTableBooleanColumn from '../../components/EditTable/EditTableBooleanColumn';
import {MRT_Cell} from 'material-react-table';
import {DeviceStatusColumn} from '../../components/EditTable/ViewTableDeviceStatusColumn';
import CsvUpload from '../../components/Csv/upload';
import {AxiosResponse, HttpStatusCode} from 'axios';
import {DateField} from '../../components/EditTable/EditTableDateColumn';
dayjs.extend(utc);

function validate(data: any, t: any) {
  return {
    deviceNo: !validateRequired(data.deviceNo) ? t('dashboardRequired') : '',
    status: !validateRequired(data.status) ? t('dashboardRequired') : '',
  };
}
interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

function PlatformDeviceContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage, setPlatformUsage} = usePlatformUsage();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const submitDataToApi = async (
    data: any,
  ): Promise<AxiosResponse<any, any> | undefined> => {
    const response = await createBulkPlatformDevice(data);
    if (!response) {
      return response;
    }

    if (response.status !== HttpStatusCode.Ok) {
      handleClick('error', JSON.stringify(response?.data));
      return response;
    }
    setPlatformUsage(undefined);
    handleClick('success', t('dashboardPlatformDeviceUploadSuccess'));
    return response;
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(() => {
    const filteredColumns = [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        show: false,
        size: 60,
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                  {idValue}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'deviceNo',
        header: t('deviceNoHeader'),
        muiEditTextFieldProps: ({row}: any) => {
          return {
            disabled: row?.index < 0 ? false : true,
            required: true,
            error: !!validationErrors?.deviceNo,
            helperText: validationErrors?.deviceNo,
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                deviceNo: undefined,
              }),
          };
        },
      },
      {
        accessorKey: 'lastMaintenanceDate',
        header: t('lastMaintenanceDateHeader'),
        show: false,
        muiEditTextFieldProps: ({row, column, cell}: any) => {
          return DateField({row, column, cell, validationErrors});
        },
        Cell: ({cell}: any) => {
          const date = dayjs(cell.getValue());
          return date.isValid() ? date.format('YYYY-MM-DD') : ''; // 若無效則回傳空字串
        },
      },
      {
        accessorKey: 'lastMaintenanceHours',
        header: t('lastMaintenanceHoursHeader'),
        show: false,
        muiEditTextFieldProps: {
          type: 'number',
          error: !!validationErrors?.lastMaintenanceHours,
          helperText: validationErrors?.lastMaintenanceHours,
          InputProps: {
            endAdornment: <InputAdornment position='end'>H</InputAdornment>,
          },
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              lastMaintenanceHours: undefined,
            }),
        },
        Cell: ({cell}: any) => `${cell.getValue()} H`,
      },
      {
        accessorKey: 'date',
        header: t('dateHeader'),
        show: true,
        muiEditTextFieldProps: ({row, column, cell}: any) => {
          return DateField({row, column, cell, validationErrors});
        },
        Cell: ({cell}: any) => {
          const date = dayjs(cell.getValue());
          return date.isValid() ? date.format('YYYY-MM-DD') : ''; // 若無效則回傳空字串
        },
      },
      {
        accessorKey: 'isFirstDieselReplaced',
        header: t('isFirstDieselReplacedHeader'),
        editSelectOptions: ['true', 'false'],
        muiEditTextFieldProps: {
          select: true,
          defaultChecked: true,
          defaultValue: true,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              isFirstDieselReplaced: undefined,
            }),
        },
        Edit: ({cell, row, column}: any) => (
          <EditTableBooleanColumn
            label={t('isFirstDieselReplacedHeader')}
            t={t}
            cell={cell}
            row={row}
            column={column}
          />
        ),
        Cell: ({cell}: {cell: MRT_Cell<any>}) => (
          <Switch
            checked={cell.getValue<boolean>()}
            inputProps={{'aria-label': 'controlled'}}
            color='primary'
            disabled
          />
        ),
      },
      {
        accessorKey: 'status',
        header: t('statusHeader'),
        editVariant: 'select' as any,
        editSelectOptions: [
          {
            label: t('deviceStatusExport'),
            value: '出庫',
          },
          {
            label: t('deviceStatusImport'),
            value: '回庫',
          },
          {
            label: t('deviceStatusMaintenanceComplete'),
            value: '保養完成',
          },
          {
            label: t('deviceStatusFirstDieselReplaced'),
            value: '更換第一道柴油',
          },
        ],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              status: undefined,
            }),
        },
        Cell: ({cell}: any) => <DeviceStatusColumn t={t} cell={cell} />,
        accessorFn: (row: any) => {
          return row.status;
        },
      },

      {
        accessorKey: 'runHours',
        header: t('runHoursHeader'),
        muiEditTextFieldProps: {
          type: 'number',
          InputProps: {
            endAdornment: <InputAdornment position='end'>H</InputAdornment>,
          },
          error: !!validationErrors?.runHours,
          helperText: validationErrors?.runHours,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              runHours: undefined,
            }),
        },
        Cell: ({cell}: any) => `${cell.getValue()} H`,
      },
      {
        accessorKey: 'location',
        header: t('locationHeader'),
        muiEditTextFieldProps: {
          error: !!validationErrors?.location,
          helperText: validationErrors?.location,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              location: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        show: false,
        Cell: ({cell}: any) => <CreatedAtColumn cell={cell} />,
      },
    ];

    return filteredColumns;
  }, [t, validationErrors]);

  return (
    <Container
      id='platformDevices'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardPlatformDevice')}
        </Typography>
      </Box>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.platformDeviceCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj?.maxLimitPlatformDeviceCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper',
              border: '1px solid #ccc',
            }}
          />
        </Box>
      </Tooltip>

      <CsvUpload onSubmitApi={submitDataToApi} t={t} />

      <TWithProviders
        props={{
          createApi: createPlatformDevice,
          updateApi: updatePlatformDevice,
          getListApi: getPlatformDeviceList,
          // deleteApi: deletePlatformDevice,
          deleteBulkApi: deleteManyPlatformDevice,
        }}
        columns={columns}
        validate={(data: any) => validate(data, t)}
        handleClick={handleClick}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlatformDevice() {
  return (
    <>
      <Dashboard element={<PlatformDeviceContent />}></Dashboard>
    </>
  );
}
