import callApi from './index';
import {
  GetPlatformDeviceListDto,
  PatchPlatformDeviceDto,
  PostBulkPlatformDeviceDto,
  PostPlatformDeviceDto,
} from './dto/platformDevice';

export const createPlatformDevice = (data: PostPlatformDeviceDto) => {
  data.runHours = parseInt(data.runHours as any);
  data.lastMaintenanceHours = parseInt(data.lastMaintenanceHours as any);

  return callApi('post', `/platform/device`, data);
};

export const createBulkPlatformDevice = (data: PostBulkPlatformDeviceDto) => {
  return callApi('post', `/platform/device/bulk`, data);
};

export const getPlatformDeviceList = (data: GetPlatformDeviceListDto) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/device/list`, options);
};

export const updatePlatformDevice = (
  id: string,
  data: PatchPlatformDeviceDto,
) => {
  data.runHours = parseInt(data.runHours as any);
  data.lastMaintenanceHours = parseInt(data.lastMaintenanceHours as any);

  return callApi('patch', `/platform/device/${id}`, data);
};

export const deletePlatformDevice = (id: string) => {
  return callApi('delete', `/platform/device/${id}`);
};

export const deleteManyPlatformDevice = (ids: string[]) => {
  return callApi('delete', `/platform/device/bulk`, {ids});
};
