// DynamicMenu.tsx
import React, {useEffect, useState} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Divider,
  List,
  Collapse,
  ListItem,
  ListSubheader,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Snackbar,
  Alert,
  AlertColor,
  Box,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MessageIcon from '@mui/icons-material/Message';
import AddIcon from '@mui/icons-material/Add';
import WebhookIcon from '@mui/icons-material/Webhook';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ExtensionIcon from '@mui/icons-material/Extension';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import LinkIcon from '@mui/icons-material/Link';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import SendIcon from '@mui/icons-material/Send';
import DevicesIcon from '@mui/icons-material/Devices';
import FaceIcon from '@mui/icons-material/Face';
import EventNoteTwoToneIcon from '@mui/icons-material/EventNoteTwoTone';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {TFunction} from 'i18next';
import {ModuleDto} from '../api/dto/module';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Link, useLocation} from 'react-router-dom';
import AddCustomMenu from './CustomMenu/AddCustomMenu';
import {usePlatformUsage} from '../context/platformUsage';
import {usePlatformCustomMenu} from '../context/platformCustomMenu';
import {deletePlatformCustomMenu} from '../api/platformCustomMenu';

import {HttpStatusCode} from 'axios';
import PopoverHelper from './Popover';
import {Trans} from 'react-i18next';
import {postUserMePermissionCheck} from '../api/user';

export const iconMap: Record<string, {name: string; icon: React.ReactElement}> =
  {
    Dashboard: {name: 'dashboardMenuDashboard', icon: <DashboardIcon />},
    Platform: {name: 'dashboardMenuPlatform', icon: <CardTravelTwoToneIcon />},
    User: {name: 'dashboardMenuUser', icon: <PeopleIcon />},
    Role: {name: 'dashboardMenuRole', icon: <EngineeringTwoToneIcon />},
    PlatformPermission: {
      name: 'dashboardMenuPermission',
      icon: <AdminPanelSettingsTwoToneIcon />,
    },
    Plan: {name: 'dashboardMenuPlan', icon: <FeaturedPlayListIcon />},
    PlanModule: {
      name: 'dashboardMenuPlanModule',
      icon: <EventNoteTwoToneIcon />,
    },
    Verification: {
      name: 'dashboardMenuVerification',
      icon: <VerifiedUserIcon />,
    },
    UserNotification: {
      name: 'dashboardMenuUserNotification',
      icon: <NotificationsIcon />,
    },
    PlatformContact: {
      name: 'dashboardMenuPlatformContact',
      icon: <ContactMailIcon />,
    },
    PlatformInfo: {name: 'dashboardPlatformInfo', icon: <InfoIcon />},
    PlatformUsage: {
      name: 'dashboardPlatformUsagePage',
      icon: <DisplaySettingsIcon />,
    },
    PlatformWebhook: {
      name: 'dashboardPlatformWebhookPage',
      icon: <WebhookIcon />,
    },
    AccountInfo: {name: 'dashboardUserMe', icon: <AccountCircleIcon />},
    AccountUsage: {name: 'dashboardAccount', icon: <AssignmentIcon />},
    AccountPermission: {
      name: 'dashboardAccountPermission',
      icon: <PlaylistAddCheckIcon />,
    },
    UserMessage: {name: 'dashboardMenuUserMessage', icon: <MessageIcon />},
    AIPlatformVoice: {
      name: 'dashboardMenuAIPlatformVoice',
      icon: <RecordVoiceOverIcon />,
    },
    AITTS: {name: 'dashboardMenuAITTS', icon: <VoiceChatIcon />},
    PlatformEmail: {name: 'dashboardPlatformEmail', icon: <SendIcon />},
    PlatformDevice: {name: 'dashboardPlatformDevice', icon: <DevicesIcon />},
    AIPlatformFace: {name: 'dashboardMenuAIPlatformFace', icon: <FaceIcon />},
    ShortenUrl: {name: 'dashboardMenuShortenUrl', icon: <LinkIcon />},
  };

type Props = {
  modules: ModuleDto[] | undefined;
  t: TFunction<'translation', undefined>;
  open: boolean;
  onToggleDrawer: (forceOpen?: boolean) => void; // 新增回調
};

const ALERT_HIDE_DURATION = 6000;

interface State {
  open: boolean;
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
}

export const DynamicMenu: React.FC<Props> = ({
  modules,
  t,
  open,
  onToggleDrawer,
}) => {
  const location = useLocation();
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({});
  const {platformUsage, setPlatformUsage} = usePlatformUsage();
  const {platformCustomMenus, setPlatformCustomMenus} = usePlatformCustomMenu();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [menuToDelete, setMenuToDelete] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<'add' | 'edit'>('add');
  const [currentMenu, setCurrentMenu] = useState<any>(null);
  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const [canEditPlatformCustomMenus, setCanEditPlatformCustomMenus] =
    useState(false);
  const [canDeletePlatformCustomMenus, setCanDeletePlatformCustomMenus] =
    useState(false);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (platformCustomMenus && platformCustomMenus.length > 0) {
        try {
          const editResponse = await postUserMePermissionCheck({
            scope: 'platform-custom-menu',
            actions: ['Edit'],
          });
          const deleteResponse = await postUserMePermissionCheck({
            scope: 'platform-custom-menu',
            actions: ['Delete'],
          });
          setCanEditPlatformCustomMenus(editResponse?.data.data ?? false);
          setCanDeletePlatformCustomMenus(deleteResponse?.data.data ?? false);
        } catch (error) {
          console.error('Permission check error:', error);
          setCanEditPlatformCustomMenus(false);
          setCanDeletePlatformCustomMenus(false);
        }
      }
    };
    fetchPermissions();
  }, [platformCustomMenus]);

  const handleClick = (
    type: 'success' | 'error' | 'warning' | 'info',
    message: string,
  ) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setMenuToDelete(null);
  };

  const handleConfirmDelete = async () => {
    const result = await deletePlatformCustomMenu(menuToDelete.id);
    if (result.status !== HttpStatusCode.Ok) {
      handleClick('error', JSON.stringify(result.data));
    } else {
      handleClick('success', t('deleteSuccess'));
      setPlatformCustomMenus(undefined);
      setPlatformUsage(undefined);
    }
    setOpenDeleteDialog(false);
    setMenuToDelete(null);
  };

  useEffect(() => {
    const storedState = localStorage.getItem('menuState');
    if (storedState) {
      setOpenSections(JSON.parse(storedState));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('menuState', JSON.stringify(openSections));
  }, [openSections]);

  const handleToggle = (section: string) => {
    if (!open) {
      onToggleDrawer(true); // 縮起時點擊箭頭，展開 Drawer
    } else {
      setOpenSections(prev => ({...prev, [section]: !prev[section]})); // 已展開時切換分類
    }
  };

  if (!modules) return null;

  const categorizedModules = modules.reduce<Record<string, any[]>>(
    (acc, module) => {
      acc[module.type] = acc[module.type] || [];
      acc[module.type].push(module);
      return acc;
    },
    {},
  );

  const sectionsMap: Record<string, string> = {
    Main: t('dashboardMain'),
    PlatformInfo: t('dashboardPlatformDetail'),
    AI: t('dashboardAIDetail'),
    AccountInfo: t('dashboardAccountDetail'),
    Custom: t('dashboardCustomDetail'),
  };

  const sections = Object.entries(sectionsMap).map(([key, title]) => ({
    key,
    title,
  }));

  const customCount = (platformCustomMenus && platformCustomMenus.length) || 0;

  return (
    <>
      <List sx={{width: '100%', padding: open ? 1 : 0}}>
        {sections.map(({key, title}) =>
          categorizedModules[key] ? (
            <div key={key}>
              <ListItemButton
                onClick={() => handleToggle(key)}
                sx={{
                  borderRadius: open ? '12px' : '8px',
                  justifyContent: open ? 'initial' : 'center',
                  px: open ? 2 : 1,
                  minWidth: 0,
                }}
              >
                {open ? (
                  <ListItemText primary={title} />
                ) : (
                  <ListItemIcon sx={{minWidth: 0}}>
                    {openSections[key] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemIcon>
                )}
                {open &&
                  categorizedModules[key].length > 0 &&
                  (openSections[key] ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {categorizedModules[key].length > 0 && (
                <Collapse
                  in={open ? openSections[key] : false}
                  timeout='auto'
                  unmountOnExit
                >
                  <List component='div' disablePadding>
                    {categorizedModules[key].map(
                      module =>
                        iconMap[module.name] && (
                          <ListItemButton
                            key={module.link}
                            sx={{
                              pl: open ? 4 : 2,
                              borderRadius: open ? '8px' : '50%',
                              backgroundColor:
                                location.pathname === module.link
                                  ? '#FFE5B4'
                                  : 'transparent',
                              '&:hover': {backgroundColor: '#FFF2D4'},
                              justifyContent: open ? 'initial' : 'center',
                            }}
                            component={Link}
                            to={module.link}
                          >
                            <ListItemIcon sx={{minWidth: open ? 40 : 0}}>
                              {iconMap[module.name]?.icon}
                            </ListItemIcon>
                            {open && (
                              <ListItemText
                                primary={t(iconMap[module.name]?.name)}
                              />
                            )}
                          </ListItemButton>
                        ),
                    )}
                  </List>
                </Collapse>
              )}
              <Divider sx={{my: 1, mx: open ? 1 : 0}} />
            </div>
          ) : null,
        )}
        {platformCustomMenus && (
          <>
            <ListItemButton
              onClick={() => handleToggle('Custom')}
              sx={{
                borderRadius: open ? '12px' : '8px',
                justifyContent: open ? 'initial' : 'center',
                px: open ? 2 : 1,
                minWidth: 0,
              }}
            >
              {open ? (
                <ListItemText
                  primary={
                    <Typography variant='body1' component='div'>
                      <Box
                        component='span'
                        sx={{display: 'inline-flex', alignItems: 'center'}}
                      >
                        {sectionsMap['Custom']}
                        <PopoverHelper
                          element={
                            <Box p={1}>
                              <Typography variant='body2'>
                                <Trans
                                  i18nKey='dashboardCustomMenuPopoverDescription'
                                  values={{
                                    permission: 'platform-custom-menu:View',
                                  }}
                                  components={{strong: <strong />}}
                                />
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>
                      <Typography
                        variant='caption'
                        component='span'
                        sx={{ml: 3, color: 'text.secondary'}}
                      >
                        {customCount} /{' '}
                        {platformUsage?.ruleObj
                          .maxLimitPlatformCustomMenuCount || 0}
                      </Typography>
                    </Typography>
                  }
                />
              ) : (
                <ListItemIcon sx={{minWidth: 0}}>
                  {openSections['Custom'] ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
              )}
              {open &&
                (openSections['Custom'] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            {platformCustomMenus.length > 0 && (
              <Collapse
                in={open ? openSections['Custom'] : false}
                timeout='auto'
                unmountOnExit
              >
                <List component='div' disablePadding>
                  {platformCustomMenus.map((customMenu, index) => (
                    <ListItemButton
                      key={customMenu.id || index}
                      sx={{
                        pl: open ? 4 : 2,
                        borderRadius: open ? '8px' : '50%',
                        backgroundColor:
                          location.pathname ===
                          `/dashboard/custom/${customMenu.id}`
                            ? '#FFE5B4'
                            : 'transparent',
                        '&:hover': {backgroundColor: '#FFF2D4'},
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      component={Link}
                      to={`/dashboard/custom/${customMenu.id}`}
                    >
                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <ListItemIcon sx={{minWidth: open ? 40 : 0}}>
                          <ExtensionIcon fontSize='small' />
                        </ListItemIcon>
                        {open && <ListItemText primary={customMenu.name} />}
                      </Box>
                      {open && (
                        <Box>
                          {canEditPlatformCustomMenus && (
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                setCurrentMenu(customMenu);
                                setDialogMode('edit');
                                setDialogOpen(true);
                              }}
                              color='primary'
                              size='small'
                            >
                              <EditIcon fontSize='small' />
                            </IconButton>
                          )}
                          {canDeletePlatformCustomMenus && (
                            <IconButton
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                setMenuToDelete(customMenu);
                                setOpenDeleteDialog(true);
                              }}
                              color='error'
                              size='small'
                            >
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          )}
                        </Box>
                      )}
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
            {open && (
              <ListItemButton
                onClick={() => {
                  setCurrentMenu(null);
                  setDialogMode('add');
                  setDialogOpen(true);
                }}
                sx={{borderRadius: '12px', px: 2}}
              >
                <ListItemIcon sx={{minWidth: 40}}>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={t('addCustomMenuAddMenu')} />
              </ListItemButton>
            )}
            <Divider sx={{my: 1, mx: open ? 1 : 0}} />
          </>
        )}

        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby='menu-dialog-title'
        >
          <DialogTitle id='menu-dialog-title'>
            {dialogMode === 'edit' ? t('editCustomMenu') : t('addCustomMenu')}
          </DialogTitle>
          <DialogContent>
            <AddCustomMenu
              t={t}
              menuData={currentMenu}
              onCloseDialog={() => setDialogOpen(false)}
              handleClick={handleClick}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby='delete-dialog-title'
          aria-describedby='delete-dialog-description'
        >
          <DialogTitle id='delete-dialog-title'>
            {t('dashboardConfirmMessage')}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>
              {t('dashboardConfirmMessageN')}
            </Button>
            <Button onClick={handleConfirmDelete} color='error' autoFocus>
              {t('dashboardConfirmMessageY')}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
          open={state.open}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant='filled'
            sx={{width: '100%'}}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </List>
    </>
  );
};

export const DynamicMobileMenu: React.FC<{
  modules: ModuleDto[] | undefined;
  t: (key: string) => string;
}> = ({modules, t}) => {
  if (!modules || modules.length === 0) return <></>;

  const sectionsMap: Record<string, string> = {
    Main: t('dashboardMain'),
    PlatformInfo: t('dashboardPlatformDetail'),
    AI: t('dashboardAIDetail'),
    AccountInfo: t('dashboardAccountDetail'),
    Custom: t('dashboardCustomDetail'),
  };

  const menuItems = modules.reduce<
    {
      section: string;
      items: {text: string; icon: React.ReactElement; onClick: () => void}[];
    }[]
  >((acc, module) => {
    if (module.name === '*') return acc;
    const iconData = iconMap[module.name] || {
      name: module.name,
      icon: <HelpOutlineIcon />,
    };

    let section = acc.find(s => s.section === module.type);
    if (!section) {
      section = {section: module.type, items: []};
      acc.push(section);
    }
    section.items.push({
      text: iconData.name,
      icon: iconData.icon,
      onClick: () => (window.location.href = module.link),
    });
    return acc;
  }, []);

  return (
    <>
      {menuItems.map((item, index) => (
        <React.Fragment key={index}>
          {item.section !== 'Main' && (
            <ListSubheader
              component='div'
              inset
              sx={{fontWeight: 'bold', color: 'text.primary', mt: 2, mb: 1}}
            >
              {t(sectionsMap[item.section] || item.section)}
            </ListSubheader>
          )}
          {item.items.map((subItem, subIndex) => (
            <ListItem
              key={`${index}-${subIndex}`}
              sx={{
                flexDirection: 'column',
                textAlign: 'center',
                mb: 1,
                p: 1,
                transition: 'all 0.2s ease',
                '&:hover': {transform: 'scale(1.02)', boxShadow: 3},
              }}
              onClick={subItem.onClick}
            >
              <ListItemIcon
                sx={{
                  width: 70,
                  height: 70,
                  borderRadius: 2,
                  backgroundColor: 'background.paper',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1,
                  boxShadow: 1,
                }}
              >
                {subItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={t(subItem.text)}
                primaryTypographyProps={{
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  color: 'text.secondary',
                }}
              />
            </ListItem>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};
