import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Box,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Popover,
  Card,
} from '@mui/material';
import dayjs from 'dayjs';
import {useState, useRef, useEffect} from 'react';
import {PlatformCreditRecordDto} from '../../api/dto/platformCreditRecord';
import {getPlatformCreditRecordList} from '../../api/platformCreditRecord';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CreditUsageCard from '../../page/credit';

export default function CreditHistoryDialog({t, open, onClose}: any) {
  const [creditHistory, setCreditHistory] = useState<PlatformCreditRecordDto[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const listRef = useRef<HTMLDivElement>(null);

  const [anchorElPopover, setAnchorElPopover] = useState<HTMLElement | null>(
    null,
  );

  const handlePopoverClose = () => {
    setAnchorElPopover(null);
  };

  const handleHelpClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPopover(event.currentTarget);
    setTimeout(() => {
      onClose();
    }, 100);
  };

  const fetchCreditHistory = async (pageNumber: number) => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const res = await getPlatformCreditRecordList({
        limit: 10,
        page: pageNumber,
      });
      localStorage.removeItem('platformCredit:ai');
      if (!res?.data?.items || res.data.items.length === 0) {
        setHasMore(false);
      } else {
        setCreditHistory(prev => [...prev, ...res.data.items]);
        setPage(prev => prev + 1);
      }
    } catch (error) {
      console.error('Load Credit error:', error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    if (!listRef.current) return;
    const {scrollTop, scrollHeight, clientHeight} = listRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 50) {
      fetchCreditHistory(page);
    }
  };

  useEffect(() => {
    if (open) {
      setCreditHistory([]);
      setPage(1);
      setHasMore(true);
    }
  }, [open]);

  useEffect(() => {
    if (open && hasMore) {
      fetchCreditHistory(1);
    }
  }, [open, hasMore]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
        <DialogTitle
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            backgroundColor: '#f5f5f5',
            position: 'relative',
          }}
        >
          {t('dashboardTableCreditRecordTitle')}
          <IconButton
            onClick={handleHelpClick}
            sx={{position: 'absolute', right: 48, top: 8}}
          >
            <HelpOutlineIcon />
          </IconButton>
          <IconButton
            onClick={onClose}
            sx={{position: 'absolute', right: 8, top: 8}}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          ref={listRef}
          onScroll={handleScroll}
          sx={{maxHeight: '50rem', overflowY: 'auto', padding: '16px'}}
        >
          {loading && creditHistory.length === 0 && (
            <Box sx={{display: 'flex', justifyContent: 'center', py: 2}}>
              <CircularProgress size={24} />
            </Box>
          )}

          {!loading && creditHistory.length === 0 && (
            <Typography
              variant='body2'
              sx={{textAlign: 'center', py: 2, color: 'gray'}}
            >
              {t('dashboardTableCreditRecordNoData')}
            </Typography>
          )}

          <List>
            {creditHistory.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  boxShadow: 1,
                  padding: '12px',
                  mb: 1,
                }}
              >
                <ListItem alignItems='flex-start'>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: item.credits > 0 ? 'green' : 'red',
                        }}
                      >
                        {item.type} {item.credits}{' '}
                        <img
                          src='/credit.png'
                          alt='credit icon'
                          style={{
                            width: '1.6rem',
                            height: '1.6rem',
                            objectFit: 'contain',
                            marginLeft: '4px',
                          }}
                        />
                      </Typography>
                    }
                    secondary={
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 0.5,
                          }}
                        >
                          {/* 創建時間 */}
                          <Typography variant='caption' sx={{color: '#888'}}>
                            {dayjs(item.createdAt).format('YYYY年M月D日 HH:mm')}
                          </Typography>

                          {/* 記錄內容 */}
                          <Typography
                            variant='body1'
                            color='text.secondary'
                            sx={{whiteSpace: 'pre-wrap'}}
                          >
                            {item.note}
                          </Typography>

                          {/* 到期時間提示 */}
                          {item.expiredAt && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: dayjs(item.expiredAt).isBefore(
                                  dayjs(),
                                )
                                  ? '#FFEBEE'
                                  : '#FFF3E0',
                                padding: '4px 8px',
                                borderRadius: 1,
                                width: 'fit-content',
                                mt: 0.5,
                              }}
                            >
                              <Typography
                                variant='caption'
                                sx={{
                                  color: dayjs(item.expiredAt).isBefore(dayjs())
                                    ? 'red'
                                    : '#888',
                                  fontWeight: 500,
                                }}
                              >
                                {t('dashboardTableCreditRecordExpiredAt')}:{' '}
                                {dayjs(item.expiredAt).format(
                                  'YYYY年M月D日 HH:mm',
                                )}
                              </Typography>
                              {dayjs(item.expiredAt).isBefore(dayjs()) ? (
                                <WarningAmberIcon
                                  color='error'
                                  fontSize='small'
                                />
                              ) : dayjs(item.expiredAt).diff(
                                  dayjs(),
                                  'months',
                                ) <= 3 ? (
                                <WarningAmberIcon
                                  color='warning'
                                  fontSize='small'
                                />
                              ) : null}
                            </Box>
                          )}
                        </Box>
                      </>
                    }
                  />
                </ListItem>
                {index < creditHistory.length - 1 && <Divider sx={{my: 1}} />}
              </Box>
            ))}
          </List>

          {loading && creditHistory.length > 0 && (
            <Box sx={{display: 'flex', justifyContent: 'center', py: 2}}>
              <CircularProgress size={24} />
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Popover
        open={Boolean(anchorElPopover)}
        anchorEl={anchorElPopover}
        onClose={handlePopoverClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              p: 0,
            },
          },
        }}
      >
        <CreditUsageCard onClose={handlePopoverClose} t={t} />
      </Popover>
    </>
  );
}
