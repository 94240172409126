import {AxiosResponse} from 'axios';
import callApi from './index';
import {
  GetUserNotificationListDto,
  PatchUserNotificationDto,
  PostUserNotificationDto,
  UserNotificationDto,
} from './dto/userNotification';

export const createUserNotification = (
  data: PostUserNotificationDto,
): Promise<AxiosResponse<{data: UserNotificationDto}, any>> | undefined => {
  return callApi('post', `/user/notification`, data);
};

export const patchUserNotification = (
  id: string,
  data: PatchUserNotificationDto,
) => {
  return callApi('patch', `/user/notification/${id}`, data);
};

export const getUserNotificationList = (
  data: GetUserNotificationListDto,
): Promise<AxiosResponse<{items: UserNotificationDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/user/notification/list`, options);
};

export const getUserMeNotificationList = (
  data: GetUserNotificationListDto,
): Promise<AxiosResponse<{items: UserNotificationDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'ASC', ...data};
  return callApi('get', `/user/me/notification/list`, options);
};

export const getUserNotificationById = (
  id: string,
): Promise<AxiosResponse<{data: UserNotificationDto}, any>> | undefined => {
  return callApi('get', `/user/notification/${id}`);
};

export const deleteUserNotification = (id: string) => {
  return callApi('delete', `/user/notification/${id}`);
};

export const deleteManyUserNotification = (ids: string[]) => {
  return callApi('delete', `/user/notification/bulk`, {ids});
};
