import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import {
  createPlanModule,
  deletePlanModule,
  getPlanModuleList,
} from '../../api/planModule';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  SnackbarOrigin,
  Switch,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import EditTableBooleanColumn from '../../components/EditTable/EditTableBooleanColumn';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import {usePlatformUsage} from '../../context/platformUsage';
import {getModuleList} from '../../api/module';
import {getPlanList} from '../../api/plan';
import {ModuleInfoCell} from '../../components/EditTable/ViewModuleInfoCell';
import {PlanInfoCell} from '../../components/EditTable/ViewPlanInfoCell';
import {iconMap} from '../../components/listItems';
dayjs.extend(utc);

function validate(data: any) {
  return {
    planId: !validateRequired(data.planId) ? 'PlanId is Required' : '',
    moduleId: !validateRequired(data.moduleId) ? 'ModuleId is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

const PlanEditComponent = ({cell, row, column, table, ...props}: any) => {
  const [options, setOptions] = useState<Array<{id: string; name: string}>>([]);
  const [selectedValue, setSelectedValue] = useState<string>(
    (row._valuesCache[column.id] as string) || '',
  );

  useEffect(() => {
    getPlanList({limit: 1000}).then(res => setOptions(res.data.items));
  }, []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setSelectedValue(newValue);
    row._valuesCache[column.id] = newValue;
  };

  return (
    <div>
      <Typography sx={{color: 'gray', fontSize: '1rem'}}>
        {column.columnDef.header}
      </Typography>
      <Select value={selectedValue} onChange={handleChange} fullWidth>
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const ModuleEditComponent = ({cell, row, column, t, ...props}: any) => {
  const [options, setOptions] = useState<Array<{id: string; name: string}>>([]);
  const [selectedValue, setSelectedValue] = useState<string>(
    (row._valuesCache[column.id] as string) || '',
  );

  useEffect(() => {
    getModuleList({limit: 100})
      ?.then(res => {
        setOptions(res.data.items);
      })
      .catch(err => console.error(err));
  }, []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setSelectedValue(newValue);
    row._valuesCache[column.id] = newValue;
  };

  return (
    <div>
      <Typography sx={{color: 'gray', fontSize: '1rem'}}>
        {column.columnDef.header}
      </Typography>
      <Select value={selectedValue} onChange={handleChange} fullWidth>
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {t(iconMap[option.name]?.name)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

function PlanModuleContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        show: false,
        enableEditing: false,
        size: 60,
        Cell: ({cell}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                  {idValue}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'planId',
        header: t('planHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.planId,
          helperText: validationErrors?.planId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              planId: undefined,
            }),
        },
        size: 10,
        Edit: PlanEditComponent,
        Cell: ({row}) => <PlanInfoCell t={t} plan={row.original.plan} />,
      },
      {
        accessorKey: 'moduleId',
        header: t('moduleHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.moduleId,
          helperText: validationErrors?.moduleId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              moduleId: undefined,
            }),
        },
        size: 10,
        Edit: props => ModuleEditComponent({...props, t}),
        Cell: ({row}) => <ModuleInfoCell t={t} module={row.original.module} />,
      },
      {
        accessorKey: 'isShow',
        header: t('isShowHeader'),
        editSelectOptions: ['true', 'false'],
        muiEditTextFieldProps: {
          select: true,
          defaultChecked: true,
          defaultValue: true,

          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              isShow: undefined,
            }),
        },
        Edit: ({cell, row, column}) => (
          <EditTableBooleanColumn t={t} cell={cell} row={row} column={column} />
        ),
        Cell: ({cell}) => (
          <Switch
            checked={cell.getValue<boolean>()}
            inputProps={{'aria-label': 'controlled'}}
            color='primary'
            disabled
          />
        ),
      },

      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuPlanModule')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardMenuPlanModuleDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        ></Box>
      </Tooltip>
      <TWithProviders
        props={{
          createApi: createPlanModule,
          getListApi: getPlanModuleList,
          deleteApi: deletePlanModule,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlanModule() {
  return <Dashboard element={<PlanModuleContent />}></Dashboard>;
}
