import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import 'react-quill/dist/quill.snow.css';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Button,
  Chip,
  IconButton,
  Paper,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import {usePlatformUsage} from '../../context/platformUsage';
import {
  createPlatformContact,
  deleteManyPlatformContact,
  getPlatformContactList,
  updatePlatformContact,
} from '../../api/platformContact';
import {MRT_Cell} from 'material-react-table';
import {StatusColumn} from '../../components/EditTable/ViewTableStatusColumn';
import IframeSettings from '../../components/PlatformContact/IframeSetting';
import {usePlatform} from '../../context/platform';
import PopoverHelper from '../../components/Popover';
dayjs.extend(utc);

function validate(data: any, t: any) {
  return {
    nickname: !validateRequired(data.nickname) ? t('dashboardRequired') : '',
    subject: !validateRequired(data.subject) ? t('dashboardRequired') : '',
    content: !validateRequired(data.content) ? t('dashboardRequired') : '',
  };
}
interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

function PlatformContactContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage, setPlatformUsage} = usePlatformUsage();
  const {platform} = usePlatform();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleScrollToIframeSettings = () => {
    const element = document.getElementById('iframeSettings');
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});
    }
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(() => {
    const filteredColumns = [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        show: false,
        size: 60,
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                  {idValue}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'nickname',
        header: t('nicknameHeader'),
        muiEditTextFieldProps: ({row}: any) => ({
          required: true,
          error: !!validationErrors?.nickname,
          helperText: validationErrors?.nickname,
          disabled: row?.index < 0 ? false : true,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              nickname: undefined,
            });
          },
        }),
      },
      {
        accessorKey: 'phone',
        header: t('phoneHeader'),
        size: 20,
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.phone,
          helperText: validationErrors?.phone,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              phone: undefined,
            });
          },
        },
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Box
                  sx={{
                    maxWidth: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                    {idValue}
                  </Typography>
                </Box>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'email',
        header: t('emailHeader'),
        muiEditTextFieldProps: ({row}: any) => ({
          required: false,
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              email: undefined,
            });
          },
        }),
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Box
                  sx={{
                    maxWidth: 200,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                    {idValue}
                  </Typography>
                </Box>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'type',
        header: t('typeHeader'),
        show: false,
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.type,
          helperText: validationErrors?.type,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              type: undefined,
            });
          },
        },
      },
      {
        accessorKey: 'externalType',
        header: t('externalTypeHeader'),
        show: false,
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.externalType,
          helperText: validationErrors?.externalType,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              externalType: undefined,
            });
          },
        },
      },
      {
        accessorKey: 'location',
        header: t('locationHeader'),
        show: false,
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.location,
          helperText: validationErrors?.location,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              location: undefined,
            });
          },
        },
      },
      {
        accessorKey: 'subject',
        header: t('subjectContactHeader'),
        muiEditTextFieldProps: ({row}: any) => ({
          required: true,
          error: !!validationErrors?.subject,
          helperText: validationErrors?.subject,
          disabled: row?.index < 0 ? false : true,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              subject: undefined,
            });
          },
        }),
      },
      {
        accessorKey: 'content',
        header: t('contentHeader'),
        show: false,
        enableEditing: false,
        muiEditTextFieldProps: ({row}: any) => ({
          required: true,
          error: !!validationErrors?.content,
          helperText: validationErrors?.content,
          disabled: row?.index < 0 ? false : true,
          onFocus: () => {
            return setValidationErrors({
              ...validationErrors,
              content: undefined,
            });
          },
        }),
      },
      {
        accessorKey: 'status',
        header: t('statusHeader'),
        editVariant: 'select' as any,
        editSelectOptions: [
          {
            label: t('statusFinish'),
            value: 'Finish',
          },
          {label: t('statusFail'), value: 'Fail'},
          {
            label: t('statusPending'),
            value: 'Pending',
          },
          {
            label: t('statusLock'),
            value: 'Lock',
          },
          {
            label: t('statusMark'),
            value: 'Mark',
          },
        ],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.status,
          helperText: validationErrors?.status,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              status: undefined,
            }),
        },
        Cell: ({cell}: any) => <StatusColumn t={t} cell={cell} />,
        accessorFn: (row: any) => {
          return row.status;
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}: any) => <CreatedAtColumn cell={cell} />,
      },
    ];

    return filteredColumns;
  }, [t, validationErrors]);

  return (
    <Container
      id='platformContacts'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box display='flex' alignItems='center' gap={2}>
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuPlatformContact')}
        </Typography>
        <IconButton onClick={handleScrollToIframeSettings} color='primary'>
          <ArrowDownwardIcon />
        </IconButton>
      </Box>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.platformContactCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj?.maxLimitPlatformContactCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper',
              border: '1px solid #ccc',
            }}
          />
        </Box>
      </Tooltip>

      <TWithProviders
        props={{
          createApi: createPlatformContact,
          updateApi: updatePlatformContact,
          getListApi: getPlatformContactList,
          deleteBulkApi: deleteManyPlatformContact,
        }}
        columns={columns}
        validate={(data: any) => validate(data, t)}
        handleClick={handleClick}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <div id='iframeSettings'>
        <IframeSettings platformId={platform?.id || ''} t={t} />
      </div>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardPlatformContact() {
  return (
    <>
      <Dashboard element={<PlatformContactContent />}></Dashboard>
    </>
  );
}
