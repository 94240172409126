import callApi from './index';
import {
  GetPlatformCustomFieldValueListDto,
  PatchPlatformCustomFieldValueDto,
  PostPlatformCustomFieldValueDto,
} from './dto/platformCustomFieldValue';

export const createPlatformCustomFieldValue = (
  data: PostPlatformCustomFieldValueDto,
) => {
  return callApi('post', `/platform/custom/field/value`, data);
};

export const getPlatformCustomFieldValueList = (
  data: GetPlatformCustomFieldValueListDto,
) => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/custom/field/value/list`, options);
};

export const updatePlatformCustomFieldValue = (
  id: string,
  data: PatchPlatformCustomFieldValueDto,
) => {
  return callApi('patch', `/platform/custom/field/value/${id}`, data);
};

export const deletePlatformCustomFieldValue = (id: string) => {
  return callApi('delete', `/platform/custom/field/value/${id}`);
};

export const deleteManyPlatformCustomFieldValue = (ids: string[]) => {
  return callApi('delete', `/platform/custom/field/value/bulk`, {ids});
};
