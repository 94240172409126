import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import {LineChart, axisClasses} from '@mui/x-charts';
import {ChartsTextStyle} from '@mui/x-charts/ChartsText';
import Title from './Title';
import {getPlatformUserGrow} from '../api/platform';
import {TFunction} from 'i18next';
import NoPermissionAlert from './Alert/NoPermission';
import PopoverHelper from './Popover';
import {Box, Typography} from '@mui/material';

export default function PlatformUserGrow({
  t,
}: {
  t: TFunction<'translation', undefined>;
}) {
  const theme = useTheme();
  const [data, setData] = React.useState<{time: string; amount: number}[]>([]);
  const [platformId, setPlatformId] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');

  React.useEffect(() => {
    const savedPlatformId = localStorage.getItem('platformId');
    if (savedPlatformId) {
      setPlatformId(savedPlatformId);
    }
  }, []);

  React.useEffect(() => {
    async function fetchUserGrowth() {
      if (!platformId) {
        return;
      }
      try {
        const response = await getPlatformUserGrow({days: 30});

        const userGrowth = response.data.data;
        const formattedData = Object.keys(userGrowth).map(date => ({
          time: date,
          amount: userGrowth[date] || 0,
        }));

        // 過濾掉無效的資料
        const validData = formattedData.filter(item => item.amount !== null);

        setData(validData);
      } catch (error: any) {
        setError('error no permission');
      }
    }
    fetchUserGrowth();
  }, [platformId]);

  if (error) {
    return (
      <>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Title>{t('dashboardAccountPlatformUserGrowTitle')}</Title>
          <PopoverHelper
            element={
              <Typography variant='body2'>
                {t('dashboardAccountPlatformUserGrowPopover')}
              </Typography>
            }
          />
        </Box>
        <NoPermissionAlert t={t} />
      </>
    );
  }

  return (
    <React.Fragment>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Title>{t('dashboardAccountPlatformUserGrowTitle')}</Title>
        <PopoverHelper
          element={
            <Typography variant='body2'>
              {t('dashboardAccountPlatformUserGrowPopover')}
            </Typography>
          }
        />
      </Box>

      <div style={{width: '100%', flexGrow: 1, overflow: 'hidden'}}>
        <LineChart
          dataset={data}
          margin={{
            top: 16,
            right: 20,
            left: 70,
            bottom: 30,
          }}
          xAxis={[
            {
              scaleType: 'point',
              dataKey: 'time',
              tickNumber: data.length, // 顯示最近 7 天
              tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
            },
          ]}
          yAxis={[
            {
              label: t('dashboardAccountPlatformUserGrowUserCount'),
              labelStyle: {
                ...(theme.typography.body1 as ChartsTextStyle),
                fill: theme.palette.text.primary,
              },
              tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
              // tickNumber: 5, // 自動調整 Y 軸分隔
            },
          ]}
          series={[
            {
              dataKey: 'amount',
              showMark: false,
              color: theme.palette.primary.light,
            },
          ]}
          sx={{
            [`.${axisClasses.root} line`]: {
              stroke: theme.palette.text.secondary,
            },
            [`.${axisClasses.root} text`]: {fill: theme.palette.text.secondary},
            [`& .${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-25px)',
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}
