import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {useTranslation} from 'react-i18next';
import {postSignIn} from '../api/auth';
import LoginIcon from '@mui/icons-material/Login';
import {
  Alert,
  AlertColor,
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import {HttpStatusCode} from 'axios';
import {ALERT_HIDE_DURATION, ZUSER_PLATFORM_ID} from '../constant';
import {getPlatformPublicList} from '../api/platform';
import {GoogleLoginButton} from '../components/GoogleLogin';
import Copyright from '../components/Copyright';
import './SignIn.css';
import {PlatformDto} from '../api/dto/platform';
import {getUserMe} from '../api/user';
import AuthIcon from '../components/Auth/Icon';
import LanguageSelector from '../components/LanguageSelector';
import {cleanUserLocalStorage} from '../utils';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', // Customize primary color
    },
    background: {
      default: '#f4f6f8', // Light background color
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
  autoHideDuration: number;
}

export default function SignIn() {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [email, setEmail] = React.useState('');
  const [cancelUrl, setCancelUrl] = React.useState('/');
  const [returnUrl, setReturnUrl] = React.useState('/dashboard');
  const [policyUrl, setPolicyUrl] = React.useState('/policy');
  const [searchPlatformId, setSearchPlatformId] = React.useState<string>('');
  const [disabledPlatformId, setDisabledPlatformId] =
    React.useState<boolean>(false);
  const [password, setPassword] = React.useState('');
  const [platformId, setPlatformId] = React.useState(
    searchPlatformId || ZUSER_PLATFORM_ID,
  );
  const [platformObj, setPlatformObj] = React.useState<{
    [key: string]: PlatformDto;
  }>({});
  const [platformList, setPlatformList] = React.useState<PlatformDto[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, setState] = React.useState<State>({
    open: false,
    autoHideDuration: ALERT_HIDE_DURATION,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;
  const {autoHideDuration} = state;

  const handleClick = (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => {
    setState({
      ...state,
      type,
      message,
      open: true,
      autoHideDuration: autoHideDuration || ALERT_HIDE_DURATION,
    });
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const successFunc = (signinData: {data: any}) => {
    cleanUserLocalStorage();
    localStorage.setItem('token', signinData.data.data.token);

    // 取得登入 token、userId 與 platformId（假設這三個欄位皆存在）
    const token: string = signinData.data.data.token;
    const userId: string = signinData.data.data.userId;
    localStorage.setItem('token', token);

    getUserMe()?.then((d: any) => {
      if (d.status !== HttpStatusCode.Ok) {
        handleClick('error', JSON.stringify(d.data));
        return;
      }

      const username: string = d.data.data.username;
      const tokenListStr: string | null =
        localStorage.getItem('platformTokenList');
      const tokenList: Record<
        string,
        {
          token: string;
          platformId: string;
          username: string;
          platformName: string;
        }
      > = tokenListStr
        ? (JSON.parse(tokenListStr) as Record<
            string,
            {
              token: string;
              platformId: string;
              username: string;
              platformName: string;
            }
          >)
        : {};
      tokenList[userId] = {
        token,
        platformId,
        username,
        platformName: platformObj[platformId].name,
      };
      localStorage.setItem('platformTokenList', JSON.stringify(tokenList));

      handleClick('success', t('signinSuccess'));

      let resultUrl: string | URL = returnUrl;
      try {
        const url = new URL(returnUrl);
        const urlParams = new URLSearchParams(url.search);
        urlParams.set('token', token);
        urlParams.set('event', 'signin');
        url.search = urlParams.toString();
        resultUrl = url;
      } catch (e) {}

      if (platformObj[platformId].isUserEmailVerify) {
        if (d.data.data.isEmailVerify === false) {
          window.location.replace(
            `/user/email/verification?returnUrl=${resultUrl}`,
          );
        } else {
          window.location.replace(resultUrl);
        }
      } else {
        window.location.replace(resultUrl);
      }
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    localStorage.setItem('platformId', platformId);
    const signin = await postSignIn({
      email,
      password,
    });

    if (signin?.status === HttpStatusCode.Ok) {
      successFunc(signin);
    } else {
      handleClick('error', signin?.data.message);
    }
  };

  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  React.useEffect(() => {
    const fetchPlatformList = async () => {
      setIsLoading(true);
      getPlatformPublicList({limit: 1000})
        ?.then(d => {
          const platformList = d?.data ? d?.data.items.map(item => item) : [];
          setPlatformList(platformList);
          const platformObj = platformList.reduce((prev, curr) => {
            return {
              ...prev,
              [curr.id]: curr,
            };
          }, {});
          setPlatformObj(platformObj);
          if (platformList.length > 0) {
            setIsLoading(false);
          }
        })
        .catch(e => {
          handleClick('error', e.message || 'Failed to load platforms');
        });
    };

    fetchPlatformList();
  }, []);

  React.useEffect(() => {
    setPolicyUrl(platformObj[platformId]?.policyUrl ?? policyUrl);
  }, [platformId]);

  React.useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);
    const platformId = urlSearch.get('platformId');
    const returnUrlParam = urlSearch.get('returnUrl');
    const cancelUrlParam = urlSearch.get('cancelUrl');

    if (platformId) {
      setPolicyUrl('');
      platformList.forEach(platform => {
        if (platform.id === platformId) {
          const {returnUrl = [], cancelUrl = []} = platform;

          if (returnUrlParam && !returnUrl.includes(returnUrlParam)) {
            setIsLoading(true);
            handleClick(
              'error',
              t('signinReturnUrlError'),
              ALERT_HIDE_DURATION * 100,
            );
            return;
          }

          if (cancelUrlParam && !cancelUrl.includes(cancelUrlParam)) {
            setIsLoading(true);
            handleClick(
              'error',
              t('signinCancelUrlError'),
              ALERT_HIDE_DURATION * 100,
            );
            return;
          }

          setPlatformId(platform.id);
          localStorage.setItem('platformId', platformId);

          setReturnUrl(returnUrlParam || returnUrl?.[0] || '/dashboard');
          setCancelUrl(cancelUrlParam || cancelUrl?.[0] || '/');
          setPolicyUrl(platform.policyUrl ?? '');
        }
      });

      setSearchPlatformId(platformId);
      setDisabledPlatformId(true);
    }
  }, [platformList, t]);

  return (
    <ThemeProvider theme={theme}>
      <div className='background-animation'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>
      <Button
        color='primary'
        variant='contained'
        size='small'
        component='a'
        rel='noopener'
        onClick={() => window.location.replace(cancelUrl)}
      >
        <KeyboardReturnIcon />
      </Button>
      <Container component='main' maxWidth='xs'>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4,
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '90%',
                  sm: '400px',
                },
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 3,
                p: 3,
                mt: 6,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <LanguageSelector i18n={i18n} theme='darkBlue' iconOnly />
              </Box>
              <AuthIcon platform={platformObj[platformId]} />
              <Typography component='h1' variant='h5'>
                {t('signin')}
              </Typography>
              <Box component='form' onSubmit={handleSubmit} sx={{mt: 1}}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label={t('signinEmail')}
                  name='email'
                  autoComplete='email'
                  autoFocus
                  value={email}
                  onChange={({target: {value}}) => {
                    setEmail(value);
                  }}
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label={t('signinPassword')}
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  value={password}
                  onChange={({target: {value}}) => {
                    setPassword(value);
                  }}
                />
                <Autocomplete
                  fullWidth
                  disabled={disabledPlatformId}
                  options={platformList.filter(p =>
                    disabledPlatformId ? p : !!p.isActive,
                  )}
                  getOptionLabel={option => option.name}
                  filterOptions={(options, state) =>
                    options
                      .filter(option =>
                        option.name
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase()),
                      )
                      .slice(0, 5)
                  }
                  value={
                    platformList.find(
                      p =>
                        p.id ===
                        (searchPlatformId || platformId || ZUSER_PLATFORM_ID),
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setPlatformId(newValue ? newValue.id : '');
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('signinPlatformName')}
                      required
                    />
                  )}
                />
                <FormControlLabel
                  control={<Checkbox value='remember' color='primary' />}
                  label={t('rememberMe')}
                />
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{mt: 3, mb: 2, py: 1.5, borderRadius: '20px'}}
                  endIcon={<LoginIcon />}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : t('signin')}
                </Button>
                <GoogleLoginButton
                  t={t}
                  platformId={platformId}
                  successFunc={successFunc}
                  handleClick={handleClick}
                />
                <Grid container direction='column' spacing={2} sx={{mt: 2}}>
                  <Grid item>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Link
                        href={`sign-up${window.location.search}`}
                        variant='body2'
                      >
                        {t('signinNoAccountSignup')}
                      </Link>
                      <Link
                        href={`forget-password${window.location.search}`}
                        variant='body2'
                      >
                        {t('signinNoAccountForgetPassword')}
                      </Link>
                    </Grid>
                  </Grid>

                  {policyUrl && (
                    <Grid item>
                      <Link href={policyUrl} variant='body2'>
                        {t('privacyPolicy')}
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>

            {/* 副卡片
            <Box
              sx={{
                width: '60%',
                height: 'auto',
                backgroundColor: '#f5f5f5',
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                boxShadow: 2,
                position: 'absolute',
                right: '-50%',
                top: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                animation: 'slideIn 0.5s ease-in-out',
                '@keyframes slideIn': {
                  from: {
                    opacity: 0,
                    transform: 'translateX(50%)',
                  },
                  to: {
                    opacity: 1,
                    transform: 'translateX(0)',
                  },
                },
              }}
            >
              {platformObj[platformId]?.logoUrl ? (
                <img
                  src={platformObj[platformId]?.logoUrl}
                  alt={`${platformObj[platformId]?.name} Logo`}
                  style={{
                    width: '80%',
                    objectFit: 'contain',
                    marginBottom: '8px',
                    transition: 'transform 0.3s ease-in-out',
                    cursor: 'pointer',
                  }}
                  onMouseEnter={e => {
                    e.currentTarget.style.transform = 'scale(1.1)';
                  }}
                  onMouseLeave={e => {
                    e.currentTarget.style.transform = 'scale(1)';
                  }}
                  onClick={e => {
                    e.currentTarget.style.transform = 'scale(1.2)';
                    setTimeout(() => {
                      e.currentTarget.style.transform = 'scale(1)';
                    }, 200); // 短暫放大後回到正常
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: 'secondary.main',
                    mb: 1,
                    transition: 'transform 0.3s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.1)',
                    },
                  }}
                >
                  {platformObj[platformId]?.name?.charAt(0).toUpperCase() ||
                    '?'}
                </Avatar>
              )}
              <Typography
                variant='subtitle1'
                align='center'
                sx={{wordWrap: 'break-word', fontSize: '14px'}}
              >
                {platformObj[platformId]?.name || 'Platform Name'}
              </Typography>
            </Box> */}
          </Box>
        </Box>
        <Copyright sx={{mt: 4, mb: 4}} />
      </Container>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
