import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../../components/Dashboard';
import {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../../components/MaterialTable';
import {
  createPlatformVoice,
  deletePlatformVoice,
  getPlatformVoiceList,
  updatePlatformVoice,
  uploadPlatformVoice,
} from '../../../api/platformVoice';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {CreatedAtColumn} from '../../../components/Date/CreatedAtColumn';
import AudioUploader from '../../../components/Audio/AudioUploader';
import {HttpStatusCode} from 'axios';
import {usePlatformUsage} from '../../../context/platformUsage';
import {MRT_Cell} from 'material-react-table';

dayjs.extend(utc);

function validate(data: Record<string, any>) {
  return {
    name: !validateRequired(data.name) ? 'Name is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

function AIPlatformVoiceContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });

  const {open} = state;
  const [refetch, setRefetch] = useState<Function>();

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState(prev => ({...prev, open: false}));
  };

  const refreshFunc = (refetch: Function) => {
    setRefetch(() => refetch);
  };

  const handleUpload = async (id: string, file: File) => {
    const MAX_SIZE = 5 * 1024 * 1024;
    if (file.size > MAX_SIZE) {
      handleClick('error', t('dashboardTableAIPlatformVoiceFileMaxExceed'));
      return;
    }

    try {
      const result = await uploadPlatformVoice(id, {file});
      if (result.status !== HttpStatusCode.Ok) {
        handleClick('error', JSON.stringify(result.data));
      }
      refetch && refetch();
    } catch (error) {
      handleClick('error', JSON.stringify(error));
    }
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        show: false,
        enableEditing: false,
        size: 60,
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                  {idValue}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'name',
        header: t('nameHeader'),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors(prev => ({
              ...prev,
              name: undefined,
            })),
        },
      },
      {
        accessorKey: 'path',
        header: t('uploadVoiceHeader'),
        enableEditing: false,
        Cell: ({row}: any) => (
          <label>
            {!row.original.path ? (
              <>
                <AudioUploader id={row.id} t={t} handleUpload={handleUpload} />
              </>
            ) : (
              <Typography variant='body2' color='text.secondary'>
                {row?.original.path?.split('/').pop()}
              </Typography>
            )}
          </label>
        ),
        muiEditTextFieldProps: {
          error: !!validationErrors?.upload,
          helperText: validationErrors?.upload,
          sx: {display: 'none'},
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              upload: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}: any) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuAIPlatformVoice')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTableAIPlatformVoiceDescription')}
        </Typography>
      </Box>

      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography
                  variant='body2'
                  color={
                    platformUsage &&
                    platformUsage?.platformVoiceCount >
                      platformUsage?.ruleObj.maxLimitPlatformVoiceCount
                      ? 'error.main'
                      : platformUsage?.platformVoiceCount ===
                        platformUsage?.ruleObj.maxLimitPlatformVoiceCount
                      ? 'warning.main'
                      : 'primary'
                  }
                >
                  {platformUsage?.platformVoiceCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj.maxLimitPlatformVoiceCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper', // 讓它更融合背景
              border: '1px solid #ccc', // 添加邊框讓它更清楚
            }}
          />
        </Box>
      </Tooltip>
      <TWithProviders
        props={{
          createApi: createPlatformVoice,
          updateApi: updatePlatformVoice,
          getListApi: getPlatformVoiceList,
          deleteApi: deletePlatformVoice,
        }}
        refreshFunc={refetch => refreshFunc(refetch)}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        displayMode='row'
        handleClick={handleClick}
      />
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardAIPlatformVoice() {
  return <Dashboard element={<AIPlatformVoiceContent />} />;
}
