import {AlertColor, SnackbarOrigin} from '@mui/material';

export interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

export const slugify = function (text: string) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const getCookie = function (cname: string) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const fixedLanguage = function (lang: string) {
  if (lang === 'en') {
    return 'en-US';
  }
  return lang;
};

export const cleanUserLocalStorage = function () {
  localStorage.removeItem('platform');
  localStorage.removeItem('user');
  localStorage.removeItem('modules');
  localStorage.removeItem('platformUsage');
  localStorage.removeItem('platformCredit');
  localStorage.removeItem('platformCredit:ai');
  localStorage.removeItem('platformCustomMenus');
  localStorage.removeItem('menuState');
};
