import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateRequired,
} from '../../components/MaterialTable';
import {
  createShortenUrl,
  deleteManyShortenUrl,
  deleteShortenUrl,
  getShortenUrlList,
  updateShortenUrl,
} from '../../api/shortenUrl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Alert,
  AlertColor,
  Chip,
  IconButton,
  Link,
  Snackbar,
  SnackbarOrigin,
  Tooltip,
} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
import {CreatedAtColumn} from '../../components/Date/CreatedAtColumn';
import {usePlatformUsage} from '../../context/platformUsage';
import {MRT_Cell} from 'material-react-table';
import {DateField} from '../../components/EditTable/EditTableDateColumn';
import {OpenInNew, Visibility, VisibilityOff} from '@mui/icons-material';

dayjs.extend(utc);

function validate(data: any) {
  return {
    redirectUrl: !validateRequired(data.name) ? 'redirectUrl is Required' : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
  autoHideDuration: number;
}
function ShortenUrlContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();
  const {platformUsage} = usePlatformUsage();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
    autoHideDuration: ALERT_HIDE_DURATION,
  });
  const {open} = state;

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleClick = (
    type: AlertColor,
    message: string,
    autoHideDuration?: number,
  ) => {
    setState({
      ...state,
      type,
      message,
      open: true,
      autoHideDuration: autoHideDuration || ALERT_HIDE_DURATION,
    });
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        show: false,
        enableEditing: false,
        size: 60,
        Cell: ({cell}: {cell: MRT_Cell<any>}) => {
          const [copied, setCopied] = useState(false);
          const idValue = cell.getValue<string>();

          const handleCopy = () => {
            navigator.clipboard.writeText(idValue);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
          };

          return (
            <Tooltip
              title={
                copied
                  ? t('dashboardIdCopyTextResult')
                  : t('dashboardIdCopyText')
              }
              arrow
            >
              <IconButton onClick={handleCopy} size='small'>
                <Typography variant='body2' sx={{fontWeight: 800, mr: 1}}>
                  {idValue}
                </Typography>
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'redirectUrl',
        header: t('redirectUrlHeader'),
        show: true,
        enableEditing: true,
        Cell: ({cell}: any) => (
          <Link
            href={cell.getValue()}
            target='_blank'
            rel='noopener noreferrer'
            sx={{
              color: '#1976d2',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {cell.getValue()}
            <OpenInNew fontSize='small' sx={{ml: 0.5}} />
          </Link>
        ),
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.redirectUrl,
          helperText: validationErrors?.redirectUrl,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              redirectUrl: undefined,
            }),
        },
      },
      {
        accessorKey: 'hash',
        header: t('shortenUrlHeader'),
        enableEditing: false,
        Cell: ({cell}: any) => {
          const fullUrl = `${
            process.env.REACT_APP_WEB_DOMAIN
          }/x/${cell.getValue()}`;
          return (
            <Link
              href={fullUrl}
              target='_blank'
              rel='noopener noreferrer'
              sx={{
                color: '#1976d2',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Typography variant='body2' noWrap>
                {cell.getValue()}
              </Typography>
              <OpenInNew fontSize='small' sx={{ml: 0.5}} />
            </Link>
          );
        },
        muiEditTextFieldProps: {
          error: !!validationErrors?.hash,
          helperText: validationErrors?.hash,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              hash: undefined,
            }),
        },
      },
      {
        accessorKey: 'expiredAt',
        header: t('expiredAtHeader'),
        muiEditTextFieldProps: ({row, column, cell}: any) => {
          return DateField({row, column, cell, validationErrors});
        },
      },
      {
        accessorKey: 'password',
        header: t('passwordHeader'),
        enableSorting: false,
        show: false,
        Cell: ({row}: any) => {
          const [showPassword, setShowPassword] = useState(false);

          return (
            <Box sx={{display: 'flex', gap: '1ch', alignItems: 'center'}}>
              {showPassword ? row.original.password : '******'}
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                size='small'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Box>
          );
        },
        muiEditTextFieldProps: ({row}: any) => {
          return {
            type: 'password',
            required: false,
            error: !!validationErrors?.password,
            helperText: validationErrors?.password,
            InputProps: {
              endAdornment: (
                <IconButton
                  onClick={() => {
                    const input = document.getElementById(
                      `password-${row.id}`,
                    ) as HTMLInputElement | null;
                    if (input) {
                      input.type =
                        input.type === 'password' ? 'text' : 'password';
                    }
                  }}
                  size='small'
                >
                  <Visibility />
                </IconButton>
              ),
            },
            id: `password-${row.id}`,
          };
        },
      },
      {
        accessorKey: 'maxViews',
        header: t('maxViewsHeader'),
        enableSorting: false,
        show: false,

        muiEditTextFieldProps: ({row}: any) => {
          return {
            required: false,
            type: 'number',
            error: !!validationErrors?.maxViews,
            helperText: validationErrors?.maxViews,
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                maxViews: undefined,
              }),
          };
        },
      },
      {
        accessorKey: 'entityId',
        show: false,
        enableEditing: false,
        header: t('entityIdHeader'),
        muiEditTextFieldProps: {
          error: !!validationErrors?.entityId,
          helperText: validationErrors?.entityId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              entityId: undefined,
            }),
        },
      },
      {
        accessorKey: 'entityType',
        header: t('entityTypeHeader'),
        show: false,
        enableEditing: false,
        muiEditTextFieldProps: {
          error: !!validationErrors?.entityType,
          helperText: validationErrors?.entityType,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              entityType: undefined,
            }),
        },
      },
      //   {
      //     accessorKey: 'body',
      //     header: t('bodyHeader'),
      //     show: false,
      //     muiEditTextFieldProps: {
      //       error: !!validationErrors?.body,
      //       helperText: validationErrors?.body,
      //       onFocus: () =>
      //         setValidationErrors({
      //           ...validationErrors,
      //           body: undefined,
      //         }),
      //     },
      //   },
      {
        accessorKey: 'views',
        header: t('viewsHeader'),
        enableSorting: false,
        show: true,
        muiEditTextFieldProps: ({row}: any) => {
          return {
            required: false,
            disabled: true,
            type: 'number',
            error: !!validationErrors?.views,
            helperText: validationErrors?.views,
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                views: undefined,
              }),
          };
        },
      },
      {
        accessorKey: 'createdAt',
        header: t('createdAtHeader'),
        enableEditing: false,
        Cell: ({cell}) => <CreatedAtColumn cell={cell} />,
      },
    ],
    [t, validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
          display: 'flex',
          flexDirection: 'column',
          alignItems: {sm: 'flex-start', md: 'center'},
          gap: 1.5,
          py: 2,
        }}
      >
        <Typography
          component='h2'
          variant='h4'
          color='text.primary'
          fontWeight='bold'
        >
          {t('dashboardMenuShortenUrl')}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{maxWidth: '80%', opacity: 0.9}}
        >
          {t('dashboardTableShortenUrlDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.shortenUrlCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj.maxLimitShortenUrlCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper', // 讓它更融合背景
              border: '1px solid #ccc', // 添加邊框讓它更清楚
            }}
          />
        </Box>
      </Tooltip>
      <TWithProviders
        props={{
          createApi: createShortenUrl,
          updateApi: updateShortenUrl,
          getListApi: getShortenUrlList,
          // deleteApi: deleteShortenUrl,
          deleteBulkApi: deleteManyShortenUrl,
        }}
        columns={columns}
        validate={validate}
        handleClick={handleClick}
        setValidateErrors={setValidationErrors}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardShortenUrl() {
  return <Dashboard element={<ShortenUrlContent />}></Dashboard>;
}
