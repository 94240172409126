import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export const DeviceStatusColumn = ({cell, t}: any) => {
  const status = cell.getValue();

  const getStatusDisplay = (status: any) => {
    switch (status) {
      case '出庫':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#4CAF50'}}
          >
            <CheckCircleIcon style={{marginRight: 5}} />
            {t('deviceStatusExport')}
          </span>
        );
      case '回庫':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#FFA500'}}
          >
            <HourglassEmptyIcon style={{marginRight: 5}} />
            {t('deviceStatusImport')}
          </span>
        );
      case '保養完成':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#2196F3'}}
          >
            <CheckCircleIcon style={{marginRight: 5}} />
            {t('deviceStatusMaintenanceComplete')}
          </span>
        );
      case '更換第一道柴油':
        return (
          <span
            style={{display: 'flex', alignItems: 'center', color: '#F44336'}}
          >
            <CancelIcon style={{marginRight: 5}} />
            {t('deviceStatusFirstDieselReplaced')}
          </span>
        );
      default:
        return status;
    }
  };

  return getStatusDisplay(status);
};
