import {useState} from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
  Tooltip,
  Typography,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';
import {ContentCopy} from '@mui/icons-material';
import {TFunction} from 'i18next';
import PopoverHelper from '../Popover';
import {LanguageObject} from '../LanguageSelector';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {Trans} from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const LANG_OPTIONS = ['en-US', 'zh-TW', 'zh-CN', 'ja-JP', 'ms-MY'];
const FIELD_OPTIONS = [
  'email',
  'nickname',
  'type',
  'phone',
  'externalType',
  'location',
];

const DEFAULT_FIELDS = ['email', 'nickname'];

interface IframeSettingsProps {
  platformId: string;
  t: TFunction<'translation', undefined>;
}

const IframeSettings: React.FC<IframeSettingsProps> = ({platformId, t}) => {
  const [lang, setLang] = useState('en-US');
  const [isLangSelector, setIsLangSelector] = useState(true);
  const [fields, setFields] = useState<string[]>(DEFAULT_FIELDS);
  const [isPlatformName, setIsPlatformName] = useState(true);
  const [isTitle, setIsTitle] = useState(true);
  const [isPlatformIcon, setIsPlatformIcon] = useState(true);
  const [copied, setCopied] = useState(false);
  const [openLinkDialog, setOpenLinkDialog] = useState(false);

  const handleFieldChange = (field: string) => {
    setFields(prev =>
      prev.includes(field) ? prev.filter(f => f !== field) : [...prev, field],
    );
  };

  const iframeSrc = `${
    process.env.REACT_APP_WEB_DOMAIN
  }/platform/${platformId}/contact?lang=${lang}&isLangSelector=${isLangSelector}&fields=${fields.join(
    ',',
  )}&isPlatformIcon=${isPlatformIcon}&isPlatformName=${isPlatformName}&isTitle=${isTitle}`;

  const iframeHtml = `<iframe title="platformContact" src="${iframeSrc}" width="100%" height="100%" style="border:none;"></iframe>`;

  const handleCopy = () => {
    navigator.clipboard.writeText(iframeHtml);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleOpenLinkDialog = () => setOpenLinkDialog(true);
  const handleCloseLinkDialog = () => setOpenLinkDialog(false);

  return (
    <Box>
      <Typography
        variant='h5'
        gutterBottom
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
          color: '#333',
          textAlign: 'center',
          mb: 3,
        }}
      >
        {t('platformContactIframeSettingTitle')}
        <PopoverHelper
          element={
            <Card
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#e3f2fd',
                padding: '8px 12px',
                borderRadius: '8px',
                cursor: 'pointer',
                boxShadow: 2,
                maxWidth: 350,
              }}
            >
              <MailOutlineIcon sx={{fontSize: 32, color: '#1976d2', mr: 2}} />
              <CardContent sx={{p: 1, minWidth: 220}}>
                <Typography
                  variant='subtitle1'
                  sx={{fontWeight: 'bold', mb: 0.5}}
                >
                  {t('platformContactPopoverTitle')}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {t('platformContactPopoverContent')}
                </Typography>
                <Typography
                  variant='caption'
                  color='text.disabled'
                  sx={{mt: 1, display: 'block'}}
                >
                  <Trans
                    i18nKey='platformContactPopoverNote'
                    values={{permission: 'platform-contacts:View'}}
                    components={{strong: <strong />}}
                  />
                </Typography>
              </CardContent>
            </Card>
          }
        />
      </Typography>

      <FormControl fullWidth sx={{mb: 2}}>
        <InputLabel>{t('platformContactIframeSettingLanguage')}</InputLabel>
        <Select value={lang} onChange={e => setLang(e.target.value)}>
          {LANG_OPTIONS.map(option => (
            <MenuItem key={option} value={option}>
              {LanguageObject[option]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            checked={isLangSelector}
            onChange={e => setIsLangSelector(e.target.checked)}
          />
        }
        label={t('platformContactIframeSettingShowLanguageSelector')}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={isPlatformIcon}
            onChange={e => setIsPlatformIcon(e.target.checked)}
          />
        }
        label={t('platformContactIframeSettingShowPlatformIcon')}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={isPlatformName}
            onChange={e => setIsPlatformName(e.target.checked)}
          />
        }
        label={t('platformContactIframeSettingShowPlatformName')}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={isTitle}
            onChange={e => setIsTitle(e.target.checked)}
          />
        }
        label={t('platformContactIframeSettingShowTitle')}
      />

      <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2}}>
        {FIELD_OPTIONS.map(field => (
          <FormControlLabel
            key={field}
            control={
              <Checkbox
                checked={fields.includes(field)}
                onChange={() => handleFieldChange(field)}
              />
            }
            label={t(`${field}Header`)}
          />
        ))}
      </Box>

      <Box sx={{mt: 2, display: 'flex', alignItems: 'center'}}>
        <TextField
          fullWidth
          value={iframeHtml}
          InputProps={{readOnly: true}}
          label={t('platformContactIframeSettingIframe')}
          sx={{
            mr: 1,
            backgroundColor: '#f5f5f5',
            color: '#999',
            '& .MuiInputBase-input': {
              color: '#666',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ccc',
            },
          }}
        />
        <Tooltip
          title={
            copied ? t('dashboardIdCopyTextResult') : t('dashboardIdCopyText')
          }
          arrow
        >
          <IconButton onClick={handleCopy}>
            <ContentCopy color={copied ? 'success' : 'inherit'} />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('platformContactIframeSettingOpenLink')}>
          <IconButton
            onClick={handleOpenLinkDialog}
            color={copied ? 'success' : 'inherit'}
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box
        sx={{mt: 2, border: '1px solid #ccc', width: '100%', height: '100%'}}
      >
        <iframe
          title='platformContact'
          src={iframeSrc}
          width='660'
          height='600'
          style={{border: 'none'}}
        />
      </Box>

      <Dialog open={openLinkDialog} onClose={handleCloseLinkDialog}>
        <DialogTitle>
          {t('platformContactIframeSettingOpenLinkDialogTitle')}
        </DialogTitle>
        <DialogContent sx={{p: 3}}>
          <Typography variant='body1'>
            {t('platformContactIframeSettingOpenLinkDialogContent')}
          </Typography>
          <Box sx={{display: 'flex', alignItems: 'center', my: 2}}>
            <TextField
              fullWidth
              value={iframeSrc}
              InputProps={{
                readOnly: true,
                sx: {
                  backgroundColor: '#f5f5f5',
                  cursor: 'default',
                },
              }}
            />
            <Tooltip title={t('dashboardIdCopyText')}>
              <IconButton
                onClick={() => navigator.clipboard.writeText(iframeSrc)}
                sx={{ml: 1}}
              >
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </Box>
          <Button
            variant='contained'
            onClick={() => window.open(iframeSrc, '_blank')}
            fullWidth
          >
            {t('platformContactIframeSettingVisitLink')}
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default IframeSettings;
