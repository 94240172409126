import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Copyright from '../components/Copyright';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {useTranslation} from 'react-i18next';
import {postSignUp} from '../api/auth';
import {
  Alert,
  AlertColor,
  Autocomplete,
  CircularProgress,
  FormControl,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import {HttpStatusCode} from 'axios';
import {ALERT_HIDE_DURATION, ZUSER_PLATFORM_ID} from '../constant';
import {getPlatformPublicList} from '../api/platform';
import './SignIn.css';
import {PlatformDto} from '../api/dto/platform';
import AuthIcon from '../components/Auth/Icon';
import LanguageSelector from '../components/LanguageSelector';
import {getUserMe} from '../api/user';
import {cleanUserLocalStorage} from '../utils';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    background: {
      default: '#f4f6f8',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
  autoHideDuration?: number;
}

export default function SignUp() {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [searchPlatformId, setSearchPlatformId] = React.useState<string>('');
  const [platformId, setPlatformId] = React.useState(
    searchPlatformId || ZUSER_PLATFORM_ID,
  );
  const [platformList, setPlatformList] = React.useState<PlatformDto[]>([]);
  const [platformObj, setPlatformObj] = React.useState<{
    [key: string]: PlatformDto;
  }>({});
  const [cancelUrl, setCancelUrl] = React.useState('/');
  const [returnUrl, setReturnUrl] = React.useState('/dashboard');
  const [policyUrl, setPolicyUrl] = React.useState('/policy');
  const [disabledPlatformId, setDisabledPlatformId] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
    autoHideDuration: ALERT_HIDE_DURATION,
  });
  const {open} = state;
  const {autoHideDuration} = state;

  const handleClick = (type: AlertColor, message: string, during?: number) => {
    setState({
      ...state,
      type,
      message,
      open: true,
      autoHideDuration: during || ALERT_HIDE_DURATION,
    });
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      return handleClick('error', t('signupPasswordValidate'));
    }
    localStorage.setItem('platformId', platformId);
    const signup = await postSignUp({
      username,
      email,
      password,
    });

    if (signup?.status === HttpStatusCode.Ok) {
      cleanUserLocalStorage();
      localStorage.setItem('token', signup.data.data.token);
      const tokenListStr = localStorage.getItem('platformTokenList');
      const tokenList = tokenListStr ? JSON.parse(tokenListStr) : {};
      const userId = signup.data.data.userId;
      getUserMe()?.then((d: any) => {
        tokenList[userId] = {
          token: signup.data.data.token,
          platformId,
          username: d.data.data.username,
          platformName: platformObj[platformId].name,
        };
      });

      localStorage.setItem('platformTokenList', JSON.stringify(tokenList));
      handleClick('success', t('signupSuccess'));
      let resultUrl: string | URL = returnUrl;

      try {
        const url = new URL(returnUrl);
        const urlParams = new URLSearchParams(url.search);
        urlParams.set('token', signup.data.data.token);
        urlParams.set('event', 'signup');
        url.search = urlParams.toString();
        resultUrl = url;
      } catch (e) {
        console.error('Invalid returnUrl:', e);
      }

      if (platformObj[platformId].isUserEmailVerify) {
        setTimeout(() => {
          window.location.replace(
            `/user/email/verification?returnUrl=${resultUrl}`,
          );
        }, 2000);
      } else {
        setTimeout(() => {
          window.location.replace(resultUrl.toString());
        }, 2000);
      }
    } else {
      handleClick('error', signup?.data.message);
    }
  };

  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  React.useEffect(() => {
    setPolicyUrl(platformObj[platformId]?.policyUrl ?? policyUrl);
  }, [platformId]);

  React.useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);
    const platformId = urlSearch.get('platformId');
    const returnUrlParam = urlSearch.get('returnUrl');
    const cancelUrlParam = urlSearch.get('cancelUrl');

    if (platformId) {
      setPolicyUrl('');
      platformList.forEach(platform => {
        if (platform.id === platformId) {
          const {returnUrl = [], cancelUrl = []} = platform;

          if (returnUrlParam && !returnUrl.includes(returnUrlParam)) {
            setIsLoading(true);
            handleClick(
              'error',
              t('signinReturnUrlError'),
              ALERT_HIDE_DURATION * 100,
            );
            return;
          }

          if (cancelUrlParam && !cancelUrl.includes(cancelUrlParam)) {
            setIsLoading(true);
            handleClick(
              'error',
              t('signinCancelUrlError'),
              ALERT_HIDE_DURATION * 100,
            );
            return;
          }

          setPlatformId(platform.id);
          localStorage.setItem('platformId', platformId);

          setReturnUrl(returnUrlParam || returnUrl[0] || '/dashboard');
          setCancelUrl(cancelUrlParam || cancelUrl[0] || '/');
          setPolicyUrl(platform.policyUrl ?? '');
        }
      });

      setSearchPlatformId(platformId);
      setDisabledPlatformId(true);
    }
  }, [platformList, t]);

  React.useEffect(() => {
    setIsLoading(true);
    getPlatformPublicList({limit: 1000})
      ?.then(d => {
        const platformListData = d.data.items.map((item: PlatformDto) => item);
        setPlatformList(platformListData);
        const platformObj = platformListData.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.id]: curr,
          };
        }, {});
        setPlatformObj(platformObj);
        if (platformListData.length > 0) {
          setIsLoading(false);
        }
      })
      .catch(e => {
        handleClick('error', e);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className='background-animation'>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
        <div className='circle'></div>
      </div>

      <Button
        color='primary'
        variant='contained'
        size='small'
        component='a'
        rel='noopener'
        onClick={() => window.location.replace(cancelUrl)}
      >
        <KeyboardReturnIcon />
      </Button>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 3,
            p: 3,
            mt: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LanguageSelector i18n={i18n} theme='darkBlue' iconOnly />
          </Box>
          <AuthIcon platform={platformObj[platformId]} />
          <Typography component='h1' variant='h5'>
            {t('signup')}
          </Typography>
          <FormControl sx={{m: 1, minWidth: 120}} disabled></FormControl>
          <Box component='form' onSubmit={handleSubmit} sx={{mt: 1}}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label={t('signinEmail')}
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={({target: {value}}) => {
                setEmail(value);
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='username'
              label={t('signupUsername')}
              type='string'
              id='username'
              autoComplete='username'
              value={username}
              onChange={({target: {value}}) => {
                setUsername(value);
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label={t('signinPassword')}
              type='password'
              id='password'
              autoComplete='current-password'
              value={password}
              onChange={({target: {value}}) => {
                setPassword(value);
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='confirmPassword'
              label={t('signupConfirmPassword')}
              type='password'
              id='confirmPassword'
              autoComplete='confirm-password'
              value={confirmPassword}
              onChange={({target: {value}}) => {
                setConfirmPassword(value);
              }}
            />

            <Autocomplete
              fullWidth
              disabled={disabledPlatformId}
              options={platformList.filter(p =>
                disabledPlatformId ? p : !!p.isActive,
              )}
              getOptionLabel={option => option.name}
              filterOptions={
                (options, state) =>
                  options
                    .filter(option =>
                      option.name
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase()),
                    )
                    .slice(0, 5) // 限制最多显示5个选项
              }
              value={
                platformList.find(
                  p =>
                    p.id ===
                    (searchPlatformId || platformId || ZUSER_PLATFORM_ID),
                ) || null
              }
              onChange={(event, newValue) => {
                setPlatformId(newValue ? newValue.id : '');
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('signinPlatformName')}
                  required
                />
              )}
            />

            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{mt: 3, mb: 2, py: 1.5, borderRadius: '20px'}}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : t('signup')}
            </Button>

            <Grid container justifyContent='space-between' sx={{mt: 2}}>
              <Link href={`sign-in${window.location.search}`} variant='body2'>
                {t('signupGoSignin')}
              </Link>

              {policyUrl ? (
                <Link href={policyUrl} variant='body2'>
                  {t('signupPolicy')}
                </Link>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{mt: 4, mb: 4}} />
      </Container>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
