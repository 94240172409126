import {AxiosResponse} from 'axios';
import {
  GetPlatformContactListDto,
  PatchPlatformContactDto,
  PostPlatformContactDto,
  PlatformContactDto,
} from './dto/platformContact';
import callApi from './index';

export const createPlatformContact = (
  data: PostPlatformContactDto,
): Promise<AxiosResponse<{data: PlatformContactDto}, any>> | undefined => {
  return callApi('post', `/platform/contact`, data);
};

export const getPlatformContactList = (
  data: GetPlatformContactListDto,
): Promise<AxiosResponse<{items: PlatformContactDto[]}, any>> | undefined => {
  const options = {orderBy: 'createdAt', sort: 'DESC', ...data};
  return callApi('get', `/platform/contact/list`, options);
};

export const updatePlatformContact = (
  id: string,
  data: PatchPlatformContactDto,
) => {
  return callApi('patch', `/platform/contact/${id}`, data);
};

export const deletePlatformContact = (id: string) => {
  return callApi('delete', `/platform/contact/${id}`);
};

export const deleteManyPlatformContact = (ids: string[]) => {
  return callApi('delete', `/platform/contact/bulk`, {ids});
};
