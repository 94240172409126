import React, {useState, useEffect, useRef} from 'react';
import {
  IconButton,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Snackbar,
  SnackbarOrigin,
  AlertColor,
  Alert,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
  Chip,
} from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import {PlayArrow, Pause, Download, Delete} from '@mui/icons-material';
import WaveSurfer from 'wavesurfer.js';
import Dashboard from '../../../components/Dashboard';
import {TTSDto} from '../../../api/dto/tts';
import {getTTSList, createTTS, deleteTTS} from '../../../api/tts';
import {PlatformVoiceDto} from '../../../api/dto/platformVoice';
import {getPlatformVoiceList} from '../../../api/platformVoice';
import {useTranslation} from 'react-i18next';
import {ALERT_HIDE_DURATION} from '../../../constant';
import {HttpStatusCode} from 'axios';
import {usePlatformUsage} from '../../../context/platformUsage';
import NoPermissionAlert from '../../../components/Alert/NoPermission';

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

const AITTS: React.FC = () => {
  const [ttsList, setTtsList] = useState<TTSDto[]>([]);
  const [platformVoiceList, setPlatformVoiceList] = useState<
    PlatformVoiceDto[]
  >([]);
  const [platformVoiceObj, setPlatformVoiceObj] = useState<{
    [id: string]: PlatformVoiceDto;
  }>();
  const [textInput, setTextInput] = useState<string>('');
  const [selectedVoice, setSelectedVoice] = useState<string>('');
  const [playbackRate, setPlaybackRate] = useState(1);
  const waveformRefs = useRef<Record<string, HTMLElement | null>>({});
  const waveSurferRefs = useRef<Record<string, WaveSurfer | null>>({});
  const [playing, setPlaying] = useState<string | null>(null);
  const {t} = useTranslation();
  const {platformUsage} = usePlatformUsage();

  // Modal 状态
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState<string | null>(null);
  const [noPermission, setNoPermission] = useState<boolean>(false);

  const [state, setState] = useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });

  const {open} = state;
  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState(prev => ({...prev, open: false}));
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTTSList({limit: 100, isFileName: true})?.then(d => {
      if (d.status === HttpStatusCode.Unauthorized) {
        setNoPermission(true);
        return;
      }
      setTtsList(d.data.items);
    });
    getPlatformVoiceList({limit: 100})
      ?.then(d => {
        setPlatformVoiceList(d.data.items);
        if (d.status === HttpStatusCode.Unauthorized) {
          setNoPermission(true);
          return;
        }

        const platformVoiceObj = d.data.items.reduce((d, curr) => {
          return {
            ...d,
            [curr.id]: curr,
          };
        });
        setPlatformVoiceObj(platformVoiceObj);
        const savedVoiceId = localStorage.getItem('selectedVoiceId');
        if (savedVoiceId) {
          setSelectedVoice(savedVoiceId); // 頁面重整時設置為存儲的語音ID
        } else if (d.data.items.length > 0) {
          setSelectedVoice(d.data.items[0].id);
        }

        if (d.status !== HttpStatusCode.Ok) {
          handleClick('error', JSON.stringify(d.data));
        }
      })
      .catch(e => {
        handleClick('error', JSON.stringify(e));
      });
  }, []);

  const handleTogglePlay = (audioUrl: string, id: string) => {
    if (playing && playing !== id) {
      // 停止當前播放
      waveSurferRefs.current[playing]?.stop();
      setPlaying(null);
    }

    if (!waveSurferRefs.current[id]) {
      // 創建新的 WaveSurfer 實例
      waveSurferRefs.current[id] = WaveSurfer.create({
        container: waveformRefs.current[id] as HTMLElement,
        waveColor: '#eee',
        progressColor: '#3f51b5',
        cursorColor: '#3f51b5',
        barWidth: 2,
      });
      waveSurferRefs.current[id]!.load(audioUrl);
    }

    const wavesurfer = waveSurferRefs.current[id];
    if (wavesurfer) {
      wavesurfer.setPlaybackRate(playbackRate); // 設置播放倍速

      if (playing === id) {
        wavesurfer.pause();
        setPlaying(null);
      } else {
        wavesurfer.play();
        setPlaying(id); // 設置播放狀態為當前音頻
      }

      wavesurfer.on('finish', () => {
        setPlaying(null);
      });
    }
  };

  const handleTTS = async () => {
    if (textInput.trim() && selectedVoice) {
      setIsLoading(true);
      const result = await createTTS({
        text: textInput,
        platformVoiceId: selectedVoice,
        IsSaveFile: true,
      });

      setIsLoading(false);
      if (result && result.status !== HttpStatusCode.Ok) {
        handleClick('error', JSON.stringify(result.data));
        return;
      }
      setTextInput('');
      localStorage.removeItem('platformCredit:ai');
      window.location.reload();
    }
  };

  const handleDeleteClick = (id: string) => {
    setDeleteTargetId(id);
    setOpenDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!deleteTargetId) return;

    const result = await deleteTTS(deleteTargetId);
    setOpenDeleteModal(false);

    if (result && result.status !== HttpStatusCode.Ok) {
      handleClick('error', JSON.stringify(result.data));
      return;
    }

    setTtsList(prevList => prevList.filter(tts => tts.id !== deleteTargetId));
    setDeleteTargetId(null);
  };

  const handleVoiceChange = (voiceId: string) => {
    setSelectedVoice(voiceId);
    localStorage.setItem('selectedVoiceId', voiceId); // 儲存選擇的語音ID
  };

  const downloadFile = async (tts: TTSDto, url: string, speed: number) => {
    try {
      const response = await fetch(url);
      const audioBuffer = await response.arrayBuffer();

      const audioContext = new AudioContext();
      const decodedAudio = await audioContext.decodeAudioData(audioBuffer);

      // 調整播放速率，保持音訊長度
      const adjustedAudioBuffer = await createAdjustedAudioBuffer(
        decodedAudio,
        speed,
      );

      // 轉換音訊為 WAV 格式
      const wavData = audioBufferToWav(adjustedAudioBuffer);

      // 創建 Blob 並下載
      const blob = new Blob([wavData], {type: 'audio/wav'});
      const urlObject = URL.createObjectURL(blob);

      // 創建下載連結並觸發下載
      const a = document.createElement('a');
      a.href = urlObject;
      a.download = `${tts.id}_${speed}.wav`; // 設定下載檔名
      a.click();

      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const createAdjustedAudioBuffer = (
    audioBuffer: AudioBuffer,
    speed: number,
  ) => {
    return new Promise<AudioBuffer>((resolve, reject) => {
      // 計算調整後的音訊長度
      const adjustedLength = Math.floor(audioBuffer.length / speed);

      const offlineContext = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        adjustedLength, // 這裡調整音訊的長度
        audioBuffer.sampleRate,
      );

      const source = offlineContext.createBufferSource();
      source.buffer = audioBuffer;
      source.playbackRate.setValueAtTime(speed, offlineContext.currentTime);
      source.connect(offlineContext.destination);
      source.start(0);

      offlineContext
        .startRendering()
        .then(renderedBuffer => {
          resolve(renderedBuffer);
        })
        .catch(reject);
    });
  };

  const audioBufferToWav = (audioBuffer: AudioBuffer) => {
    const numOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;
    const sampleRate = audioBuffer.sampleRate;
    const buffer = new ArrayBuffer(44 + length * numOfChannels * 2); // 44 bytes for WAV header
    const view = new DataView(buffer);

    // WAV Header
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length * numOfChannels * 2, true); // file size - 8
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true); // Subchunk1Size (16 for PCM)
    view.setUint16(20, 1, true); // AudioFormat (1 = PCM)
    view.setUint16(22, numOfChannels, true); // Number of channels
    view.setUint32(24, sampleRate, true); // SampleRate
    view.setUint32(28, sampleRate * numOfChannels * 2, true); // ByteRate
    view.setUint16(32, numOfChannels * 2, true); // BlockAlign
    view.setUint16(34, 16, true); // BitsPerSample
    writeString(view, 36, 'data');
    view.setUint32(40, length * numOfChannels * 2, true); // Subchunk2Size

    let offset = 44;
    for (let channel = 0; channel < numOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      for (let i = 0; i < length; i++) {
        view.setInt16(offset, channelData[i] * 0x7fff, true); // 16-bit PCM
        offset += 2;
      }
    }

    return buffer;
  };

  const writeString = (view: DataView, offset: number, str: string) => {
    for (let i = 0; i < str.length; i++) {
      view.setUint8(offset + i, str.charCodeAt(i));
    }
  };

  if (noPermission) {
    return (
      <Container
        sx={{
          pt: {xs: 4, sm: 8},
          pb: {xs: 8, sm: 2},
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: {xs: 3, sm: 6},
        }}
      >
        {' '}
        <NoPermissionAlert t={t} />
      </Container>
    );
  }

  return (
    <Container
      sx={{
        pt: {xs: 4, sm: 8},
        pb: {xs: 8, sm: 2},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Typography
        component='h2'
        p={3}
        variant='h4'
        color='text.primary'
        fontWeight='bold'
      >
        {t('dashboardTableAITTSTitle')}
      </Typography>

      <Tooltip title={t('dashboardTableCreditTooltip')} arrow>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: '-1rem',
          }}
        >
          <Chip
            label={
              <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                <Typography variant='body2' color='primary'>
                  {platformUsage?.planCount}
                </Typography>
                /
                <Typography variant='body2' color='text.secondary'>
                  {platformUsage?.ruleObj.maxLimitPlanCount}
                </Typography>
              </Box>
            }
            size='small'
            color='default'
            sx={{
              fontSize: '0.75rem',
              height: 24,
              paddingX: 1.2,
              backgroundColor: 'background.paper', // 讓它更融合背景
              border: '1px solid #ccc', // 添加邊框讓它更清楚
            }}
          />
        </Box>
      </Tooltip>

      <FormControl fullWidth>
        <InputLabel>{t('dashboardTableAITTSChooseVoice')}</InputLabel>
        <Select
          value={selectedVoice || ''}
          onChange={e => handleVoiceChange(e?.target?.value)}
        >
          {platformVoiceList?.map(voice => (
            <MenuItem key={voice.id} value={voice.id}>
              {voice.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label={t('dashboardTableAITTSEnterText')}
        variant='outlined'
        fullWidth
        value={textInput}
        onChange={e => setTextInput(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton
              color='primary'
              onClick={handleTTS}
              disabled={textInput.length < 6 || isLoading} // 禁用按钮，当文字长度小于6或正在加载时
            >
              <PlayArrow />
            </IconButton>
          ),
        }}
      />

      <Grid container justifyContent='flex-end' sx={{marginTop: 1}}>
        <Grid item>
          <FormControl sx={{minWidth: 100}}>
            <InputLabel sx={{fontSize: '0.8rem'}}>
              {t('dashboardTableAITTSTitlePlaySpeed')}
            </InputLabel>
            <Select
              value={playbackRate}
              onChange={e => setPlaybackRate(Number(e.target.value))}
              label={t('dashboardTableAITTSTitlePlaySpeed')}
              sx={{fontSize: '0.8rem', width: 100}}
            >
              <MenuItem value={0.5}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Typography sx={{fontSize: '0.8rem'}}>0.5x</Typography>
                  <IconButton
                    sx={{padding: '6px', fontSize: '0.9rem', marginLeft: 1}}
                  >
                    <SpeedIcon />
                  </IconButton>
                </Box>
              </MenuItem>
              <MenuItem value={0.75}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Typography sx={{fontSize: '0.8rem'}}>0.75x</Typography>
                  <IconButton
                    sx={{padding: '6px', fontSize: '0.9rem', marginLeft: 1}}
                  >
                    <SpeedIcon />
                  </IconButton>
                </Box>
              </MenuItem>
              <MenuItem value={1}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Typography sx={{fontSize: '0.8rem'}}>1x</Typography>
                  <IconButton
                    sx={{padding: '6px', fontSize: '0.9rem', marginLeft: 1}}
                  >
                    <SpeedIcon />
                  </IconButton>
                </Box>
              </MenuItem>
              <MenuItem value={1.5}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Typography sx={{fontSize: '0.8rem'}}>1.5x</Typography>
                  <IconButton
                    sx={{padding: '6px', fontSize: '0.9rem', marginLeft: 1}}
                  >
                    <SpeedIcon />
                  </IconButton>
                </Box>
              </MenuItem>
              <MenuItem value={2.25}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  <Typography sx={{fontSize: '0.8rem'}}>2.25x</Typography>
                  <IconButton
                    sx={{padding: '6px', fontSize: '0.9rem', marginLeft: 1}}
                  >
                    <SpeedIcon />
                  </IconButton>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {ttsList?.map(tts => {
          const isNew =
            Date.now() - new Date(tts.createdAt).getTime() < 30 * 1000; // 半小时内
          return (
            <Grid item xs={12} sm={6} md={4} key={tts.id}>
              <Card
                sx={{p: 2, borderRadius: 2, boxShadow: 2, position: 'relative'}}
              >
                <CardContent>
                  {isNew && (
                    <Typography
                      variant='caption'
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '2px 6px',
                        borderRadius: '4px',
                        fontWeight: 'bold',
                        fontSize: '0.75rem',
                      }}
                    >
                      NEW
                    </Typography>
                  )}

                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{width: '100%'}}
                  >
                    {/* 左側文本 */}
                    <Box
                      sx={{
                        flex: 1,
                        maxWidth: 'calc(100% - 120px)',
                        overflow: 'hidden',
                      }}
                    >
                      <Tooltip title={tts.text.length > 10 ? tts.text : ''}>
                        <Typography
                          variant='subtitle1'
                          fontWeight='bold'
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {tts.text}
                        </Typography>
                      </Tooltip>
                      <Typography variant='body2' color='textSecondary'>
                        {t('dashboardTableAITTSDuration')}:{' '}
                        {tts.audioDuration || '未知'}{' '}
                        {t('dashboardTableAITTSDurationType')}
                      </Typography>
                      {/* 語音平台顯示 */}
                      <Typography variant='caption' color='textSecondary'>
                        {/* {t('dashboardTableAITTSPlatformVoice')}:{' '} */}
                        {platformVoiceObj?.[tts.platformVoiceId]?.name}
                      </Typography>
                    </Box>

                    {/* 右側按鈕 */}
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{marginLeft: '8px'}}
                    >
                      <Tooltip title={t('dashboardTableAITTSDownloadTooltip')}>
                        <Button
                          component='button'
                          onClick={() =>
                            downloadFile(tts, tts.filename, playbackRate)
                          }
                          endIcon={<Download />}
                        ></Button>
                      </Tooltip>
                      {/* 播放/暫停按鈕 */}
                      <IconButton
                        onClick={() => handleTogglePlay(tts.filename, tts.id)}
                        sx={{
                          color:
                            playing === tts.id ? 'primary.main' : '#006400',
                        }}
                      >
                        {playing === tts.id ? <Pause /> : <PlayArrow />}
                      </IconButton>
                      {/* 刪除按鈕 */}
                      <IconButton
                        sx={{
                          color: '#8B0000',
                        }}
                        onClick={() => handleDeleteClick(tts.id)}
                        disabled={playing === tts.id}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>

                  {/* 波形区域 */}
                  <Box
                    ref={el =>
                      (waveformRefs.current[tts.id] = el as HTMLElement | null)
                    }
                    sx={{
                      width: '100%',
                      height: '6rem',
                      mt: 2,
                      border: '1px solid #ddd',
                      borderRadius: 1,
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {/* 删除确认对话框 */}
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        maxWidth='sm'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            padding: 3,
            minWidth: '6rem',
          },
        }}
      >
        <DialogTitle>
          {t('dashboardTableAITTSTitleConfirmDeleteTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('dashboardTableAITTSTitleConfirmDeleteMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)}>
            {t('cancelBtn')}
          </Button>
          <Button
            color='error'
            onClick={handleConfirmDelete}
            disabled={isLoading}
          >
            {t('deleteBtn')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default function DashboardAITTS() {
  return <Dashboard element={<AITTS />} />;
}
